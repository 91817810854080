/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '@/plugins/axios'

export default {
    setUploadID( context, payload ) {
        context.commit( 'setUploadID', payload )
    },
    setUploadType( context, payload ) {
        context.commit( 'setUploadType', payload )
    },
    setMultiUpload( context, payload ) {
        context.commit( 'setMultiUpload', payload )
    },
    showUploader( context ) {
        context.dispatch( 'setSelectedFile', null )

        context.dispatch( 'setShowUpload', true )
    },
    setShowUpload( context, payload ) {
        context.commit( 'setShowUpload', payload )
    },
    setSelectedFile( context, payload ) {
        context.commit( 'setSelectedFile', payload )
    },
    doUpload( context, formData ) {
        const config = {
            onUploadProgress: progressEvent => console.log( progressEvent.loaded )
        }
        let url = '/api/upload/uploadImage/'
        url += context.state.uploadID + '/' + context.state.uploadType

        return api.post( url, formData, config )
    }
}