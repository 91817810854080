<template>
  <spinner-overlay v-if="isLoading"></spinner-overlay>
  <div class="row">
    <label class="col-2">
      <translation :t="'TEMPLATETHEME'"></translation>
      :
    </label>
    <div class="checkbox-radio-container"
         v-for="stylesheet in app.template.metadata.stylesheets">
      <label style="margin-right:5px">
        {{ stylesheet.name }}
      </label>
      <i class="fa fa-check"
         v-if="stylesheet.key == app.metadata.templatetheme"></i>
      <i class="far fa-square pointer"
         @click="setTemplatetheme(stylesheet)"
         v-else></i>
    </div>
  </div>
  <hr/>
  <div class="row">
    <div class="col-12">
      <div>
        <label>
          <translation :t="'PUBLISHINLANGUAGES'"></translation>
          : </label>
      </div>
      <language-selector
          :applicationID="app.id"
          :metadata="app.metadata">
      </language-selector>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <div>
        <label>
          <translation :t="'FORREGIONS'"></translation>
          : </label>
      </div>
      <region-selector
          :applicationID="app.id"
          :metadata="app.metadata">
      </region-selector>
    </div>
  </div>
  <hr/>
  <select-form></select-form>
  <hr/>
  <div class="row">
    <div class="col-5">
      <button class="btn btn-success" @click="newVersion">
        <translation :t="'NEWVERSION'"></translation>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import LanguageSelector from '@/components/phoneapp/templates/partials/LanguageSelector.vue'
import RegionSelector from '@/components/phoneapp/templates/partials/RegionSelector.vue'
import SelectForm from "@/components/phoneapp/templates/partials/SelectForm"
import SpinnerOverlay from '@/components/widgets/SpinnerOverlay'

export default {
  name : "Blix",
  props: [],
  data() {
    return {
      busy:false,
      isLoading: false
    }
  },
  components: {SelectForm, LanguageSelector, RegionSelector, SpinnerOverlay},
  computed  : {
    ...mapState( ['languageCode'] ),
    ...mapState( 'formModule', ['forms'] ),
    ...mapState( 'applicationModule', ['app', 'unselectedForms'] )
  },
  created() {

  },
  methods: {
    ...mapActions( 'applicationModule',
        ['saveMetadata', 'getUnselectedForms', 'setNewVersion'] ),
    setTemplatetheme( stylesheet ) {
      this.app.metadata.templatetheme = stylesheet.key
      let payload = {
        applicationID: this.app.id,
        metadata     : JSON.stringify( this.app.metadata )
      }
      this.saveMetadata( payload )
    },
    newVersion() {
      if( this.busy ) return
      this.isLoading = true

      this.busy = true
      this.setNewVersion( this.app.id ).then( result => {
        this.busy = false
        this.isLoading = false
        if ( result.data.result ) return alert( 'new version is available' );

        alert( 'something went wrong' );
      } )
    }
  }
}
</script>

<style scoped>
</style>





