<template>
  <div class="input-group">
    <input class="form-control form-control-sm"
           :placeholder="placeHolder"
           @blur="handleBlur"
           @keyup="disableTimeout"
           v-model="searchTag"/>
    <div class="input-group-append">
      <span class="input-group-text bg-transparent border-left-0">
      <i :class="icon"></i>
      </span>
    </div>
  </div>
  <ul class="foundtags" v-if="searchTag.length > 0">
    <li v-for="foundTag in foundTags"
        @click="selectTag(foundTag)">
      {{ foundTag.translation ? foundTag.translation.translation : 'NB' }}
    </li>
  </ul>
  <ul style="padding:0;margin:5px 0 0 0;">
    <li class="item-tag" v-for="selectedTag in selectedTags">
      {{ selectedTag.tagname }}
      <button class="remove-tag"
              @click.stop="removeTheTag(selectedTag)">x
      </button>
    </li>
  </ul>
  <button class="btn"
          style="width:90%;"
          :class="buttonClass"
          @click="buttonAction"
          :disabled="buttonDisabled">
    <translation :t="buttonText"></translation>
  </button>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "FoundTags",
  props: ['icon', 'type', 'placeHolder', 'buttonClass', 'buttonText', 'buttonDisabled', 'reset', 'addTags'],
  emits: ['selectTag', 'buttonAction'],
  data() {
    return {
      searchTag   : '',
      timeout     : null,
      selectedTags: []
    }
  },
  components: {},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'tagModule', ['tags'] ),
    foundTags() {
      let self = this
      return self.tags.filter( tag => {
        if ( tag.translation ) {
          return tag.translation.translation.toUpperCase().search( self.searchTag.toUpperCase() ) != -1
        }
      } );
    }
  },
  created() {
  },
  methods: {
    ...mapActions( 'tagModule', ['createTag'] ),
    selectTag( foundTag ) {
      this.selectedTags.push( {
        id     : foundTag.id,
        tagname: foundTag.translation.translation
      } )
      this.$emit( 'selectTag', this.selectedTags, this.type )
    },
    handleBlur() {
      let self = this
      self.timeout = setTimeout( function () {
        self.searchTag = ''
      }, 1000 );
    },
    disableTimeout( event ) {
      if ( event.keyCode == 13 && this.addTags ) this.createTheTag()

      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
    },
    createTheTag() {
      let self = this

      if ( self.searchTag.length == 0 ) return

      let payload = {}
      payload.languageCode = self.languageCode
      payload.tagname = self.searchTag
      payload.tagTypeID = 0

      self.createTag( payload ).then( result => {
        self.selectedTags.push( {
          id     : result.data,
          tagname: self.searchTag
        } )
        self.searchTag = ''
      } )
    },
    buttonAction() {
      this.$emit( 'buttonAction', this.selectedTags, this.type )

      if ( this.reset ) this.selectedTags = []
    },
    removeTheTag( tag ) {
      let index = this.selectedTags.findIndex( item => item.id === tag.id );

      this.selectedTags.splice( index, 1 );

      this.$emit( 'selectTag', this.selectedTags, this.type )
    }
  }
}
</script>

<style scoped>

</style>