/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '../../../plugins/axios'
import router from '../../../router/index'

export default {
    refreshUsers(context) {
        api.get('/users/getusers')
            .then(function (result) {
                context.commit('setUsers', result.data)
            })
            .catch()
    },
    selectUser(context, user) {
        context.commit('setSelectedUser', user)
    },
    getOrganizationUsers(context, organizationID) {
        api.get('/users/getorganizationusers/' + organizationID)
            .then(function (result) {
                context.commit('setOrganizationUsers', result.data)
            })
    },
    saveUser: function (context, user) {
        return api.post('/api/users/saveuser', user)
    },
    resetUser(context) {
        let user = {
            id             : 0,
            firstname      : '',
            lastname       : '',
            email          : '',
            languagecode   : '',
            organization_id: null,
            blocked        : 'F',
            archived       : 'F'
        }
        context.commit('setSelectedUser', user)
    },
    setPassword(context, form) {
        api.post('/api/users/setpassword', form)
    },
}