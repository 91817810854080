/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import tagMutations from './tagMutations.js';
import tagActions from './tagActions.js';
import tagGetters from './tagGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            tags:[]
        }
    },
    mutations: tagMutations,
    actions  : tagActions,
    getters  : tagGetters,

};


