/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setFormpages( state, payload ) {
        state.formpages = payload
    },
    setLibrary(state, payload){
        state.library = payload
    },
    setFormID(state, payload){
        state.selectedFormpage.form_id = payload
    },
    setElementtypes(state, payload){
        state.elementtypes = payload
    },
    setFormelements(state, payload){
        state.formelements = payload
    },
    setSelectedElement(state, payload){
        state.selectedElement = payload
        state.selectedElementID = payload.id
    },
    selectFormpage( state, payload ) {
        state.formpageID = payload
        state.selectedFormpage = state.formpages.find( formpage => {
            return formpage.id == payload
        } )
    },
    resetFormpage( state ) {
        state.selectedFormpage = {
            id                    : null,
            formpage_id           : null,
            formpagedescription_id: null,
            formpagename          : '',
            formpagedescription   : ''
        }
    },
    setShowInfoTextPanel(state){
        state.showInfoTextPanel = !state.showInfoTextPanel
    },
    updateFormpagename( state, payload ) {
        state.selectedFormpage.formpagename = payload
    }
}