/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setOrganizations(state, payload){
        state.organizations = payload
    },
    setUserrights(state, payload){
      state.userrights = payload
    },
    setEditOrganization(state, status) {
        state.editOrganization = status
    },
    setRoles(state, payload){
        state.roles = payload
    },
    selectedOrganization(state, organization){
        state.selectedOrganization = organization
    },
    setOrganizationID(state, organizationID){
        state.organizationID = organizationID
    }
}