<template>
<div class="app-header"
     :class="{'app-header-background':background}">
  <h3>Cerebral Palsy</h3>
  <div class="app-header-icon-holder">
    <i class="fa fa-home"></i>
    <i class="mdi mdi-menu"></i>
    <i class="fa fa-lock"></i>
  </div>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "Appheader",
  props:['background'],
  data() {
    return {}
  },
  components: {},
  computed  : {},
  methods   : {}
}
</script>

<style scoped>
.app-header{
  position:absolute;
  width:100%;
  padding:10px;
}
.app-header-background{
  background-color:#f16a02
}
.app-header h3{
  color:#fff;
  font-size:20px;
  float:left;
}
.app-header-icon-holder{
  float:right;
  color:#fff;
}
.app-header-icon-holder i{
  margin-right:8px;
}
</style>