/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    showEditor( state ) {
        return state.showEditor;
    },
    editorText(state){
        return state.editorText
    },
    showRevisions(state){
        return state.showRevisions;
    },
    revisions(state){
        return state.revisions
    },
    textType(state){
        return state.textType
    },
    textTypeID(state){
        return state.textTypeID
    },
    editorRefresh(state){
        return state.editorRefresh
    }
}
