<template>
  <div v-if="infoText" class="info-text-holder">
    <infopopup :elementID="elementID"
               :showInfo="showInfo"
               :infoText="infoText.translation"
               @hideInfo="showInfo = false"></infopopup>
    <span class="info-text fa fa-info-circle info-color"
          @click="showInfotext"></span>
  </div>
  <h4>{{ metaData.label.value }}</h4>
  <div class="goalgenerator-box">
    <div v-for="goal in metaData.goalgenerator.value">
      <label style="color:#fff;margin-top:10px;">{{ goal.translation }}</label>
      <input type="text" class="form-control form-control-sm bg-white border-grey" />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Infopopup from "@/components/forms/formelements/phone/infopopup";

export default {
  name : "GoalGeneratorPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {
      showInfo : false
    }
  },
  components: {Infopopup},
  computed  : {},
  methods   : {}
}
</script>

<style scoped>
.goalgenerator-box{
  border: 1px solid #b2b2b2;
  border-radius:10px;
  padding:0px 10px 20px;
  background-color:#555;
}
</style>