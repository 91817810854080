/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import organizationMutations from './organizationMutations.js';
import organizationActions from './organizationActions.js';
import organizationGetters from './organizationGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            selectedOrganization: {
                id        : null,
                name      : '',
                address   : '',
                place     : '',
                country_id: null
            },
            organizations       : [],
            organizationID      : null,
            editOrganization    : true,
            userrights          : [],
            roles               : []
        }
    },
    mutations: organizationMutations,
    actions  : organizationActions,
    getters  : organizationGetters,

};


