/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import uploadMutations from './uploadMutations.js';
import uploadActions from './uploadActions.js';
import uploadGetters from './uploadGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            uploadType  : '',
            uploadID    : null,
            selectedFile: null,
            multiUpload : false,
            showUpload  : false
        }
    },
    mutations: uploadMutations,
    actions  : uploadActions,
    getters  : uploadGetters
};


