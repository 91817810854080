<template>
  <div
      class="modal fade"
      id="new-organization">
    <div
        class="modal-dialog"
        role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
              class="modal-title"
              id="exampleModalLabel">
            <translation :t="'NEWORGANIZATION'"></translation>
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <organizationdetails></organizationdetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Organizationdetails from '@/components/organizations/OrganizationDetails.vue'

export default {
name: "Neworganization",
  components:{
    Organizationdetails
  }
}
</script>

<style scoped>

</style>