<template>
  <span v-if="showUpload">
    <div id="upload-backdrop" class="my-backdrop"></div>
    <div id="upload-popup" class="my-popup">
      <div class="my-popup-header">
        <h3>
          <translation :t="'UPLOAD'"></translation>
          <button class="close" @click="setShowUpload(false)">x</button>
        </h3>
      </div>
      <upload></upload>
    </div>
  </span>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Upload from "@/components/widgets/Upload";

export default {
  name: "Uploadpopup",
  data() {
    return {}
  },
  components: {Upload},
  computed  : {
    ...mapGetters( 'uploadModule', ['showUpload'] )
  },
  methods   : {
    ...mapActions( 'uploadModule', ['setShowUpload'] ),
  }
}
</script>

<style scoped>

</style>