<template>
  <div class="fr-view content-phone" v-html="metaData.content.value"></div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "ParagraphPhone",
  props: ['metaData', 'infoText'],
  data() {
    return {}
  },
  components: {},
  computed  : {
  },
  methods   : {
  }
}
</script>

<style scoped>
.content-phone{
  padding:3px;
}

</style>