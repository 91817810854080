<template>
  <div
      class="modal fade"
      id="new-formpage">
    <div
        class="modal-dialog"
        role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <translation :t="'NEWFORMPAGE'"></translation>
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <validated-input
              v-model="formpagename"
              :validations="'required'"
              :placeholder="'PAGEFORMNAME'"
              :errorMessage="'VALIDFORMNAME'"
              :labelCaption="'PAGEFORMNAME'">
          </validated-input>
          <hr/>
          <i class="fa fa-check"
             v-if="formtype == 'DEFAULT'"></i>
          <i class="far fa-square pointer"
             v-else
             @click="formtype = 'DEFAULT'"></i>
          <translation :t="'NORMALFORMPAGE'"
                       style="margin-left:5px;margin-right:15px;"></translation>
          <i class="fa fa-check"
             v-if="formtype == 'GOAL'"></i>
          <i class="far fa-square pointer"
             v-else
             @click="formtype = 'GOAL'"></i>
          <translation :t="'GOALFORM'"
                       style="margin-left:5px;;margin-right:15px">
          </translation>
          <i class="fa fa-check"
             v-if="formtype == 'CLIENTFORM'"></i>
          <i class="far fa-square pointer"
             v-else
             @click="formtype = 'CLIENTFORM'"></i>
          <translation :t="'CLIENTFORM'"
                       style="margin-left:5px;"></translation>
          <hr/>
          <button class="btn btn-success" @click="saveTheFormpage"
                  :disabled="formpagename.length == 0">
            <translation :t="'SAVE'"></translation>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name      : "newFormpage",
  components: {},
  data() {
    return {
      formpagename: '',
      formtype    : 'DEFAULT'
    }
  },
  computed: {
    ...mapGetters( 'formModule', ['formID', 'selectedForm'] ),
    ...mapGetters( ['languages', 'languageCode'] )
  },
  methods : {
    ...mapActions( 'formpageModule', ['saveFormpage', 'getFormpages'] ),
    ...mapActions( 'formModule', ['getForm'] ),
    saveTheFormpage() {
      let self = this
      let payload = {}
      payload.languageCode = self.languageCode
      payload.formID = self.selectedForm.id
      payload.formpagename = self.formpagename
      payload.formpagedescription = 'PLACEHOLDER'
      payload.formtype = self.formtype
      self.saveFormpage( payload )
          .then( () => {
            self.formpagename = ''
            self.getFormpages( 0 )
            $( '#new-formpage' ).modal( 'hide' )
          } )
    }
  }
}
</script>

<style scoped>

</style>