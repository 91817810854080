<template>
  <div class="input-group-append">
    <input class="form-control form-control-sm"
           @keyup="saveTranslation"
           v-model="translated.translation"/>
    <span v-if=" ! translatorBusy"
          style="cursor:pointer"
          @click="translateInput"
          class="input-group-text
                    bg-transparent border-left-0">
      <i class="icon-refresh"></i>
    </span>
    <span v-else
          style="padding:0 8px"
          class="input-group-text
                    bg-transparent border-left-0">
      <img src="img/spinner.gif"
           width="20"/>
    </span>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "Translator",
  data() {
    return {
      translatorBusy: false,
      timeout       : null,
      translated    : {
        id           : null,
        identifier_id: null,
        translation  : '',
        languagecode : ''
      }
    }
  },
  props   : ['ownLanguageCode', 'baseTranslation'],
  computed: {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'translator', ['toTranslate', 'identifierID', 'translations'] )
  },
  watch   : {
    translations() {
      let translated =
              this.translations.find( translation => {
                return translation.languagecode == this.ownLanguageCode
              } )

      if ( translated ) return this.translated = translated;

      this.translated = {
        id           : null,
        identifier_id: null,
        translation  : '',
        languagecode : this.ownLanguageCode
      }
    }
  },
  methods : {
    ...mapActions( 'translator', ['translateIt', 'getTranslations'] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    translateInput() {
      let self = this
      self.translatorBusy = true
      let translationData = JSON.stringify( {
        fromLanguage: self.languageCode,
        toTranslate : self.toTranslate,
        identifierID: self.identifierID,
        toLanguage  : self.translated.languagecode
      } )
      let formData = new FormData();
      formData.append( 'translationData', translationData )
      this.translateIt( formData )
          .then( function () {
            self.getTranslations( self.identifierID )
            self.translatorBusy = false
          } )
    },
    saveTranslation() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        let payload = {}
        payload.identifierID = self.identifierID
        payload.languageCode = self.ownLanguageCode
        payload.translation = self.translated.translation

        self.saveTranslationByIdentifier( payload )
      }, 700 );
    }
  }
}
</script>

<style scoped>

</style>