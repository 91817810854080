<template>
  <div class="side-panel side-panel-apppages"
       :class="showAppPages ? 'open-panel' : ''">
    <h3 class="side-button side-apppages-button"
        @click="toggleShow">
      <translation :t="'APPPAGES'"></translation>
    </h3>
    <div class="card bg-primary-light" style="width:900px;">
      <div class="card-body">
        <button class="btn btn-danger" @click="test">test</button>
          <div v-html="image"></div>
        <div class="row">
          <div class="col-5">
            <ul class="list-group" v-if="app && app.template" style="height:600px; overflow:auto">
              <li class="list-group-item"
                  v-for="templatepage in app.template.templatepages">
                <i class="fa fa-plus pull-right pointer"
                   style="margin-right:15px;"
                   @click="addAppPage(templatepage.id)"></i>
                {{ templatepage.translation }}
                <ul class="list-group">
                  <li class="list-group-item"
                      :class="{'selected-list-item-light':selectedPage && selectedPage.id == page.id}"
                      v-for="page in templatepage.applicationpages">
                    <div style="width:85%; float:left;">
                      <input-translation
                          :identifierID="page.translation_id"
                          :toTranslate="page.translation ? page.translation.translation : ''"
                          @saveTranslation="savePagename"></input-translation>
                    </div>
                    <i class="fa fa-trash pointer redish"
                       style="float:right;margin-top:8px;margin-left:10px;"
                       @click="removeAppPage(page)"></i>
                    <i class="fa fa-edit pointer"
                       style="float:right;margin-top:8px"
                       @click="setPageToEdit(templatepage, page)"></i>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="col-7">
            <div class="phone-app">
              <component v-if="comp"
                         :applicationpageID="selectedPage.id"
                         :is="comp">
              </component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import InputTranslation from "../../controls/InputTranslation"
import BlixStart from '@/components/phoneapp/templates/blix/templatepages/Startpage'
import BlixAbout from '@/components/phoneapp/templates/blix/templatepages/Aboutpage'
import BlixLeftSidepanel from '@/components/phoneapp/templates/blix/templatepages/LeftSidepanel'
import BlixArticle from '@/components/phoneapp/templates/blix/templatepages/Article'
import BlixNavigation from '@/components/phoneapp/templates/blix/templatepages/Navigation'
import {api} from '@/plugins/axios'

export default {
  name      : "AppPages",
  props     : ['templatepages', 'applicationID'],
  components: {InputTranslation, BlixStart, BlixAbout, BlixLeftSidepanel, BlixArticle, BlixNavigation},
  emits     : [],
  data() {
    return {
      showAppPages: false,
      comp        : null,
      selectedPage: null,
      pageMetadata: null,
      image       : 'image'
    }
  },
  computed: {
    ...mapGetters( ['languages', 'languageCode'] ),
    ...mapGetters( 'templateModule', ['template'] ),
    ...mapState( 'applicationModule', ['app', 'selectedApplicationID'] )
  },
  watch   : {
    selectedApplicationID() {
      this.refreshApp()
    }
  },
  created() {
    this.refreshApp()
  },
  methods: {
    ...mapActions( 'applicationModule', ['getApp', 'createApplicationpage', 'removeApplicationPage'] ),
    ...mapActions( 'templateModule', [] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    test() {
      let self = this
      api.get( '/api/applications/getImage' )
          .then( ( result ) => {
            self.image = result.data
          } )
    },
    refreshApp() {
      this.getApp( this.selectedApplicationID )
    },
    toggleShow() {
      this.showAppPages = !this.showAppPages
    },
    setPageToEdit( templatepage, page ) {
      this.comp = this.app.template.component + templatepage.component

      this.selectedPage = page
    },
    addAppPage( templatepageID ) {
      let self = this
      let payload = {
        applicationID : this.selectedApplicationID,
        templatepageID: templatepageID
      }
      self.createApplicationpage( payload )
          .then( () => {
            self.refreshApp()
          } )
    },
    savePagename( translation, translationID ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    },
    removeAppPage( page ) {
      let self = this
      self.removeApplicationPage( page.id )
          .then( () => {
            self.refreshApp()
          } )
    }
  }
}
</script>

<style scoped>
.phone-app {
  font-family: helvetica, Arial, 'sans-serif';
  position: relative;
  width: 90%;
  height: 700px;
  border-top: 40px solid #000;
  border-left: 20px solid #000;
  border-right: 20px solid #000;
  border-bottom: 70px solid #000;
  border-radius: 10px;
}
</style>