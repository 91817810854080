<template>
  <li
      class="list-group-item list-group-item-info list-group-sm pointer"
      :class="{'selected':selectedUser.id == user.id}"
      v-for="user in userList"
      @click="selectTheUser(user)">
    <i class="fas fa-user-alt"
       style="position:relative;left:-10px;"></i>
    {{ user.fullname }}
  </li>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name   : "Userlistitem",
  props  : ['userList'],
  computed:{
    ...mapGetters('user', ['selectedUser'])
  },
  methods: {
    ...mapActions('user', ['selectUser']),
    ...mapActions('organization', ['setEditOrganization']),
    selectTheUser(user) {
      this.selectUser(user)
      this.setEditOrganization(false);
    }
  }
}
</script>

<style scoped>
li {
  background-color: #e0eefd;
}

li.selected {
  background-color: #b9daff;
}
</style>