<template>
  <div class="row">
    <div class="row">
      <div class="col-12">
        <label>
          <translation :t="'CATEGORYFOLLOWUP'"></translation>
        </label>
      </div>
    </div>
  </div>
  <div class="row row-margin-top">
    <div class="col-12">
      <label>
        <translation :t="'LABELFORFOLLOWUP'"></translation>
      </label>
      <div class="input-group">
        <input class="form-control form-control-sm bg-white"
               v-model="followupLabel"
               @keyup.enter="saveTheFollowupname"
               :disabled="! followup.translation_id"/>
        <div class="input-group-append pointer bg-white">
          <span class="input-group-text bg-transparent border-left-0"
                @click="showTranslator">
                    <i class="far fa-flag"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-margin-top">
    <div class="col-12">
      <ul class="list-group">
        <li class="list-group-item list-group-item-hover"
            :class="{'selected-list-item-light': followup && followup.followuptype == 'INPUTFIELD'}"
            @click="saveTheFollowup('INPUTFIELD')">
          <i class="fa fa-trash redish"
             style="margin-right:10px"
             @click.stop="removeFollowup(followup)"
             v-if="followup && followup.followuptype == 'INPUTFIELD'"></i>
          <translation :t="'INPUTFIELD'"></translation>
        </li>
        <li class="list-group-item list-group-item-hover"
            @click="saveTheFollowup('TEXTAREA')"
            :class="{'selected-list-item-light': followup && followup.followuptype == 'TEXTAREA'}">
          <i class="fa fa-trash redish"
             style="margin-right:10px"
             @click.stop="removeFollowup(followup)"
             v-if="followup && followup.followuptype == 'TEXTAREA'"></i>
          <translation :t="'TEXTAREA'"></translation>
        </li>
        <li class="list-group-item list-group-item-hover"
            @click="saveTheFollowup('RADIOGROUP')"
            :class="{'selected-list-item-light': followup && followup.followuptype == 'RADIOGROUP'}">
          <i class="fa fa-trash redish"
             style="margin-right:10px"
             @click.stop="removeFollowup(followup)"
             v-if="followup && followup.followuptype == 'RADIOGROUP'"></i>
          <translation :t="'RADIOGROUP'"></translation>
        </li>
        <li class="list-group-item list-group-item-hover"
            @click="saveTheFollowup('CHECKBOX')"
            :class="{'selected-list-item-light': followup && followup.followuptype == 'CHECKBOX'}">
          <i class="fa fa-trash redish"
             style="margin-right:10px"
             @click.stop="removeFollowup(followup)"
             v-if="followup && followup.followuptype == 'CHECKBOX'"></i>
          <translation :t="'CHECKBOX'"></translation>
        </li>
        <li class="list-group-item list-group-item-hover"
            @click="saveTheFollowup('SELECTBOX')"
            :class="{'selected-list-item-light': followup && followup.followuptype == 'SELECTBOX'}">
          <i class="fa fa-trash redish"
             style="margin-right:10px"
             @click.stop="removeFollowup(followup)"
             v-if="followup && followup.followuptype == 'SELECTBOX'"></i>
          <translation :t="'SELECTBOX'"></translation>
        </li>
      </ul>
    </div>
  </div>
  <div class="row row-margin-top" v-if="showCategory">
    <div class="col-12">
      <select class="form-control form-control-sm bg-white"
              v-model="categoryID"
              @change="saveTheFollowup(false)">
        <option value="">
          select...
        </option>
        <option v-for="category in categories" :value="category.id">
          {{ category.translation ? category.translation.translation : 'NB' }}
        </option>
      </select>
    </div>
  </div>
  {{ followup }}
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "CategoryFollowup",
  data() {
    return {
      categoryID   : null,
      followupLabel: '',
      timeout      : null,
    }
  },
  components: {},
  watch     : {
    followup() {
//       this.setFollowupName();
    }
  },
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'categoryModule', ['categories', 'followup'] ),
    showCategory() {
      let self = this
      let categoryTypes = ['RADIOGROUP', 'CHECKBOX', 'SELECTBOX']

      if ( !this.followup ) return false

      return categoryTypes.find( categoryType => {
        return categoryType == self.followup.followuptype
      } )
    }
  },
  created() {
    this.setFollowupName();
  },
  methods: {
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    ...mapActions( 'categoryModule', ['saveFollowup', 'saveFollowupname', 'removeFollowup'] ),
    showTranslator() {
      let self = this
      if ( !self.followup.translation_id ) return;
      this.showHideTranslator( 'SHOW' )

      this.getTranslations( self.followup.translation_id )
      this.setToTranslate( this.followupLabel )
      this.setIdentifierID( self.followup.translation_id )
    },
    setFollowupName() {
      this.followupLabel =
          this.followup.translation ? this.followup.translation.translation : ''
      this.categoryID = this.followup.category_id
    },
    saveTheFollowup( followupType ) {
      let payload = {}
      payload.id = this.followup ? this.followup.id : null
      payload.categoryitem_id = this.followup.categoryitem_id
      payload.category_id = this.categoryID
      if ( followupType )
        payload.followuptype = followupType

      this.saveFollowup( payload )
    },
    saveTheFollowupname() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        let payload = {}
        payload.translation_id = self.followup.translation_id
        payload.followupname = self.followupLabel
        payload.languageCode = self.languageCode

        this.saveFollowupname( payload )
      }, 700 );
    }
  }
}
</script>

<style scoped>

</style>