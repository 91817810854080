<template>
  <div id="element-holder">
    <input type="text"
           class="form-control"
           v-if="editFormpagename"
           @keyup.enter="saveTheFormpagename"
           v-model="formpagename">
    <h3 v-else>
      <i class="fa fa-edit pointer"
         style="margin-rigth:15px;font-size:.6em;position: relative;top:-15px"
         @click="editFormpagename=true"></i>
      <label style="position: relative;left:15px;">
        {{ selectedFormpage.formpagename }}
      </label>
      <i class="fas fa-align-justify"
         style="float:right;"
         @click="toggleCollappseAll"></i>
    </h3>
    <br>
    <!-- DRAG & DROP -->
    <draggable v-model="localFormelements"
               v-if="localFormelements.length > 0"
               ghost-class="ghost"
               tag="transition-group"
               @end="reorderTheFormelements"
               :component-data="{name: 'flip-list', type: 'transition'}"
               item-key="id">
      <template #item="{element}">
        <div class="form-page-block">
          <formelement :formelement="element"></formelement>
        </div>
      </template>
    </draggable>
    <!-- END DRAG & DROP -->
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import draggable from 'vuedraggable'
import Formelement from "@/components/forms/formpages/builder/Formelement"


export default {
  name : "FormpageBuilder",
  props: ['elementtypeName'],
  data() {
    return {
      editFormpagename : '',
      formpagename     : false,
      localFormelements: []
    }
  },
  components: {
    Formelement,
    draggable
  },
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'formpageModule', ['formelements', 'selectedFormpage', 'selectedElementID'] )
  },
  watch     : {
    selectedFormpage() {
      console.log( 'inside watch 1' );
      this.formpagename = this.selectedFormpage.formpagename
    },
    formelements() {
      this.localFormelements = this.formelements
    }
  },
  created() {
    console.log( 'CREATED' );
  },
  methods: {
    ...mapActions( 'formpageModule', ['saveFormpagename', 'reorderFormelements'] ),
    toggleCollappseAll(){
      let self = this
      let status = ! self.formelements[0].showBody
      self.formelements.forEach(formelement=>{
        formelement.showBody = status
      })
    },
    reorderTheFormelements() {
      let reorderdFormelements = [];
      let index = 1;
      this.localFormelements.forEach( formelement => {
        reorderdFormelements.push( {
          id   : formelement.id,
          index: index
        } )
        index++
      } )
      this.reorderFormelements( reorderdFormelements )
    },
    saveTheFormpagename() {
      let payload = {}
      payload.id = this.selectedFormpage.id
      payload.languageCode = this.languageCode
      payload.formpagename = this.formpagename
      payload.languageCode = this.languageCode

      this.saveFormpagename( payload )
      this.editFormpagename = false
    }
  }
}
</script>

<style scoped>
.formtype-holder {
  /*height:100%;*/
  /*overflow:auto;*/
}

#element-holder {
  height: 700px;
  overflow: scroll;
}
</style>