<template>
  <div class="thumbnail"
       :class="{'selected-thumbnail': inSelection}"
       @click="selectThumbnail">
    <img :src="thumbnail.thumbnail"
         @mouseover="showMobileImage"
         @mouseleave="hideMobileImage"
         class="img-thumbnail"/>
    <div class="thumbnail-footer">
      <i class="fa fa-tags text-white pointer"
         @click.stop="showTags">
      </i>
      <tags v-if="thumbnail.showTags"
            @hideTags="hideTags"
            @refreshTags="refreshTags"
            :linkedTags="thumbnail.tags"
            :tagTypeID="thumbnail.id"
            :tagType="'IMAGETAG'"></tags>
      <i class="fa fa-trash redish right-pull hover-effect"
         @click.stop="removeThumbnail"></i>
    </div>
    <div class="hover-image-holder">
      <div class="hover-image" @mouseleave="" v-if="mobileSource">
        <img :src="thumbnail.imageUrl">
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import router from "@/router"
import Swal from "sweetalert2"
import Tags from "@/components/management/tags/Tags";

export default {
  name : "Thumbnail",
  emits: ['tagsClicked', 'refreshImages'],
  props: ['thumbnail', 'selectedThumbnails'],
  data() {
    return {
      firstClick  : true,
      mobileSource: '',
      timeout     : null
    }
  },
  components: {Tags},
  computed  : {
    ...mapGetters( 'treeModule', ['selectedItems'] ),
    inSelection() {
      return this.selectedItems.find( thumb => {
        return thumb == this.thumbnail.id
      } )
    }
  },
  methods   : {
    ...mapActions( 'treeModule', ['selectItemsWithShift', 'selectItemsWithMeta', 'firstSelectedItem', 'setRefreshSource', 'removeItem'] ),
    ...mapActions( 'tagModule', ['getItemTags'] ),
    selectThumbnail( event ) {
      let payload = {itemID: this.thumbnail.id}
      if ( event.shiftKey ) {
        this.selectItemsWithShift( payload )
      } else if ( event.ctrlKey || event.metaKey ) {
        this.selectItemsWithMeta( payload )
      } else {
        this.firstSelectedItem( payload );
      }
    },
    removeThumbnail() {
      let self = this

      Swal.fire( {
        title            : 'Are you sure you want to delete this image',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return

        self.removeItem( self.thumbnail.id )
            .then( () => {
              self.$emit( 'refreshImages' )
            } )
      } )
    },
    showTags() {
      if ( !this.thumbnail.showTags ) this.$emit( 'tagsClicked' )
      this.thumbnail.showTags = !this.thumbnail.showTags
    },
    showMobileImage() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.mobileSource = 'ONHOVER'
      }, 700 );
    },
    hideMobileImage() {
      clearTimeout( this.timeout );
      this.timeout = null;
      this.mobileSource = false
    },
    hideTags() {
      let self = this
      setTimeout( function () {
        self.thumbnail.showTags = false
      }, 700 );
    },
    refreshTags( payload ) {
      this.$emit('refreshImages')
//       let self = this
//       self.getItemTags( payload )
//           .then( result => {
//             self.thumbnail.tags = result.data.tags
//           } )
    }
  }
}
</script>

<style scoped>
.thumbnail {
  padding: 8px;
  background-color: #222;
  border-radius: 15px;
  margin: 10px;
  box-shadow: #bbb 5px 5px 5px;
  float: left;
  border: 4px solid #222;
}

.thumbnail-footer {
  padding: 10px 10px 0;
}

.selected-thumbnail {
  border: 4px solid #ffc100;
}

.hover-image-holder {
  position: relative;
}

.hover-image {
  position: absolute;
  padding: 15px;
  background-color: #fff;
  border: 5px solid #000;
  box-shadow: #aaa 5px 5px 5px;
  border-radius: 15px;
}
</style>