/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setUsers (state, payload){
        state.users = payload;
    },
    setSelectedUser(state, payload){
        state.selectedUser = payload
    },
    setOrganizationUsers(state, payload){
        state.organizationUsers = payload
        console.log( 'organizationUsers', state.organizationUsers );
    }
}