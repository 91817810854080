/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    organizations(state){
        return state.organizations
    },
    selectedOrganization (state){
        return state.selectedOrganization
    },
    organizationID(state){
        return state.organizationID
    },
    editOrganization(state){
        return state.editOrganization
    }
}
