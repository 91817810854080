<template>
  <button class="language-button btn"
          @click="$emit('selectLanguage')"
          :class="'btn-' + (countryCode ? 'success' : 'info')">
    <img :src="flagUrl" alt="country flag" />
    <span style="margin-left:20px;">
      {{ countryName }}
    </span>
  </button>
</template>

<script>
export default {
  props: ['countryCode','flagUrl', 'countryName']
}
</script>

<style scoped>
img{
  width:30px;
}
button.language-button{
  display:block;
  margin-bottom:15px;
  width:250px;
  text-align: left;
}
</style>