<template>
</template>

<script>
import {mapActions} from 'vuex'
import Translation from "@/components/controls/Translation";

export default {
  components: {Translation},
  data() {
    return {
      url: null
    }
  },
  created() {
    let token = this.$route.query.token
    const form = new FormData()
    form.append( 'token', token )
    this.passwordForgotten( form )
  },
  methods: {
    ...mapActions( 'auth', ['passwordForgotten'] )
  }
}
</script>

<style scoped>

</style>