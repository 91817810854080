<template>
  <picture-selector
      @selectPicture="selectPicture"
      :pictureformat="pictureformat"
      v-if="showPictureSelector"></picture-selector>
  <button class="close" @click="toggleRegionPicture">x</button>
  <hr/>
  <ul class="region-pictures">
    <li>
      <img :src="defaultPicture"
           @click="togglePictureSelector('DEFAULT')"/>
      <label>
        <translation :t="'DEFAULT'"></translation>
      </label>
    </li>
    <li v-for="region in regions">
      <img :src="region.picture ? region.picture.picture : defaultPicture"
           @click="togglePictureSelector(region)"/>
      <label>
        {{ region.translation ? region.translation.translation.translation : '' }}
      </label>
    </li>
  </ul>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import PictureSelector from "@/components/widgets/pictureSelector.vue"

export default {
  name : "RegionPicture",
  props: ['defaultPicture', 'regions', 'applicationpageID', 'pictureformat', 'pictureType'],
  emits: ['toggleRegionPicture', 'setDefaultPicture'],
  data() {
    return {
      showPictureSelector: false,
      selectedRegion     : null,
      busy               : false
    }
  },
  components: {PictureSelector},
  computed  : {},
  created() {
  },
  methods: {
    ...mapActions( 'modalModule', ['getModalPictures', 'saveApplicationpageRegionpicture'] ),
    togglePictureSelector( selectedRegion ) {
      this.showPictureSelector = !this.showPictureSelector
      this.selectedRegion = selectedRegion
      if ( !selectedRegion ) return

      this.getModalPictures( this.pictureType )
    },
    selectPicture( selectedPicture ) {
      let self = this
      if ( self.busy ) return
      if ( !selectedPicture ) return self.toggleRegionPicture( false )
      let payload = {}
      this.busy = true
      console.log( self.selectedRegion );
      if ( self.selectedRegion == 'DEFAULT' ) {
        this.$emit('setDefaultPicture', selectedPicture)
        payload.regionID = 'DEFAULT'
      } else {
        if( self.selectedRegion.picture == undefined ) {
          self.selectedRegion.picture = {}
          self.selectedRegion.picture.regionID = self.selectedRegion.id
        }
        self.selectedRegion.picture.picture = selectedPicture
        payload.regionID = self.selectedRegion.picture.regionID
      }

      payload.applicationpageID = self.applicationpageID
      payload.picture = selectedPicture

      this.saveApplicationpageRegionpicture( payload )
          .then( () => {
            self.busy = false
            self.togglePictureSelector( false )
          } )
    },
    toggleRegionPicture(){
      this.$emit('toggleRegionPicture')
    }
  }
}
</script>

<style scoped>

</style>