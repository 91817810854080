<template>
  <span v-if="hasChildpages">
    <article-childpages
        @setArticlepage="setArticlepage"
        :applicationpageID="applicationpageID"
        :childpages="metadata.childpages"
        :article="article"></article-childpages>
  </span>
  <span v-else>
    <tree-modal @clickAction="setArticle"></tree-modal>
    <link-selector
        @chosenLink="chosenLink"
        :selectedLink="metadata.navigation.link"
        v-if="showLinkSelector">
    </link-selector>
    <span v-if="metadata">
      <app-header :background="true"></app-header>
      <div class="blix-article"
           @click="showArticles"
           :class="{'blix-sans-navigation': !metadata.navigation.hasNavigation }">
        <p v-html="article"></p>
      </div>
<!--      <div class="blix-article-navigation"-->
<!--           v-if="metadata.navigation.hasNavigation">-->
<!--        <input-translation-->
<!--            v-if="editNavigationName"-->
<!--            :saveOnEnter="true"-->
<!--            :identifierID="metadata.navigation.translation_id"-->
<!--            :toTranslate="metadata.navigation.translation"-->
<!--            @saveTranslation="saveNavigationName">-->
<!--        </input-translation>-->
<!--        <span v-else>-->
<!--          <span class="fa fa-link blix-article-navigation-link"-->
<!--                @click.stop="showLinkSelector = true"></span>-->
<!--          <label @click.stop="editNavigationName = true">-->
<!--            {{ metadata.navigation.translation }}-->
<!--          </label>-->
<!--        </span>-->
<!--        <button class="close blix-article-navigation-close"-->
<!--                @click="toggleNavigation()">x-->
<!--        </button>-->
<!--      </div>-->
<!--      <div v-else-->
<!--           @click="toggleNavigation"-->
<!--           class="blix-add-article-navigation">-->
<!--        <translation :t="'ADDNAVIGATIONBUTTON'"></translation>-->
<!--      </div>-->
    </span>
  </span>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import AppHeader from '@/components/phoneapp/templates/blix/partials/Appheader.vue'
import InputTranslation from "@/components/controls/InputTranslation"
import TreeModal from '@/components/widgets/TreeModal.vue'
import LinkSelector from '@/components/widgets/LinkSelector'
import ArticleChildpages from './ArticleChildpages'

export default {
  name : "Article",
  props: ['applicationpageID'],
  emits: [],
  data() {
    return {
      article           : '',
      metadata          : false,
      editNavigationName: false,
      showLinkSelector  : false,
      hasChildpages     : false
    }
  },
  components: {AppHeader, InputTranslation, TreeModal, LinkSelector, ArticleChildpages},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] )
  },
  watch     : {
    applicationpageID() {
      this.setMetadata();
    }
  },
  created() {
    this.setMetadata()
  },
  methods: {
    ...mapActions( 'modalModule', ['toggleArticlesSelector'] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'applicationModule',
        ['getApplicationpageMetadata', 'saveMetadataPropertyValue', 'getArticleMetadata'] ),
    ...mapActions( 'editorModule', ['getArticleByLink'] ),
    setMetadata() {
      let self = this
      self.getApplicationpageMetadata( self.applicationpageID )
          .then( ( result ) => {
            this.hasChildpages = result.data.childpages && result.data.childpages.length > 0

            this.metadata = result.data
            if( this.hasChildpages){
              this.metadata.childpages.sort((a, b) => a.order - b.order);
              this.getTheArticleByLink(this.metadata.article.link)
            }else{

            }
          } )
    },
    setArticlepage( childpage ) {
      if ( childpage.childpages.length > 0 ) {
        this.hasChildpages = true
      } else {
        this.hasChildpages = false
      }

      this.getTheArticleByLink(childpage.link)

      this.metadata = childpage
    },
    getTheArticleByLink(link){
      this.getArticleByLink( link )
          .then( result => {
            this.article = result.data
          } )
    },
    setArticleFromNavigation( articleID ) {
      this.getArticleMetadata( articleID )
          .then( result => {
            this.hasChildpages = result.data.childpages && result.data.childpages.length > 0
            self.metadata = result.data
          } )
    },
    showArticles() {
      this.toggleArticlesSelector()
    },
    toggleNavigation() {
      this.metadata.navigation.hasNavigation = !this.metadata.navigation.hasNavigation
      let payload = {}

      payload.applicationpageID = this.applicationpageID
      payload.property = 'navigation.hasNavigation'
      payload.value = this.metadata.navigation.hasNavigation
      this.saveMetadataPropertyValue( payload )
    },
    setArticle( article ) {
      let self = this
      let payload = {}

      if ( !article ) return

      payload.applicationpageID = this.applicationpageID
      payload.property = 'article.link'
      payload.value = article.id
      self.saveMetadataPropertyValue( payload )
          .then( () => {
            self.setMetadata()
          } )
    },
    chosenLink( link ) {
      this.showLinkSelector = false

      if ( !link ) return

      let self = this
      let payload = {}

      payload.applicationpageID = this.applicationpageID
      payload.property = 'navigation.link'
      payload.value = link.id
      self.saveMetadataPropertyValue( payload )
          .then( () => {
            self.setMetadata()
          } )
    },
    saveNavigationName( translation, translationID ) {
      this.editNavigationName = false
      this.metadata.navigation.translation = translation

      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    }
  }
}
</script>

<style scoped>
</style>