<template>
<h1>NOT AVAILABLE YET</h1>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "Contentone",
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( ['languages'] ),
  },
  methods   : {}
}
</script>

<style scoped>

</style>