/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '@/plugins/axios'

export default {

    saveEditortext(context, payload){
        const formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post('/api/texteditor/saveText', formData)
    },
    getRevisions(context, payload){
        const formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        api.post('/api/texteditor/getRevisions', formData)
            .then(result=>{
                context.commit('setRevisions', result.data)
            })
    },
    getEditorText(context){
        const formData = new FormData
        formData.append( 'textType', context.state.textType)
        formData.append( 'textTypeID', context.state.textTypeID)
        return api.post('/api/texteditor/getText', formData)
    },
    getArticleByLink(context, linkID){
        let languageCode = context.rootState.languageCode
        return api.get('/api/texteditor/getArticleByLinkID/' + linkID + '/' + languageCode)
    },
    getTranslatedText(context, payload){
        return api.post('/api/texteditor/getTranslatedText', payload)
    },
    toggleRevisions(context){
        context.commit('toggleRevision')
    },
    translateEditortext(context, payload){
        const formData = new FormData;
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post('/api/editortext/translateEditortext', formData)
    },
    setShowEditor(context, payload){
        if( payload === undefined) payload = ! context.state.showEditor

        context.commit('setShowEditor', payload)
    },
    setTextType(context, payload){
        context.commit('setTextType', payload)
    },
    setTextTypeID(context, payload){
        context.commit('setTextTypeID', payload)
    },
    updateEditortext(context, payload){
        context.commit('updateEditortext', payload)
    }
}