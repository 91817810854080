<template>
  <div class="modal fade"
       id="alert-me">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ alertData.title }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ alertData.message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            <translation :t="'NO'"></translation>
          </button>
          <button type="button" class="btn btn-primary">
            <translation :t="'YES'"></translation>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation";

export default {
  name : "Alertme",
  data() {
    return {}
  },
  components: {Translation},
  computed  : {
    ...mapGetters( ['languageCode', 'alertData'] ),
  },
  methods   : {

  }
}
</script>

<style scoped>

</style>