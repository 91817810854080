/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router"

export default {

    setMilestoneIndicators( context, milestoneIndicators ) {
        context.commit( 'setMilestoneIndicators', milestoneIndicators )
    },
    setActiveMilestoneDomainID( context, milestoneDomainID ) {
        context.commit( 'setActiveMilestoneDomainID', milestoneDomainID )
    },
    setActiveMilestoneCategoryID( context, milestoneCategoryID ) {
        context.commit( 'setActiveMilestoneCategoryID', milestoneCategoryID )
    },
    showOverview(context, milestoneCountryID) {
        return api.get('/api/milestones/showOverview/' + milestoneCountryID)
    },
    getMilestoneForms( context, formID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/milestones/getMilestoneForms/' + languageCode + '/' + formID )
    },
    getMilestoneForm( context, milestoneCountryID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/milestones/getMilestoneForm/' + languageCode + '/' + milestoneCountryID )
            .then( result => {
                context.commit( 'setMilestoneForm', result.data )
            } )
    },
    getMilestoneCategory( context, payload ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/milestones/getMilestoneCategory/' + payload.milestoneDomainID + '/' + payload.milestoneCategoryID + '/' + languageCode )
    },
    getMilestoneIndicators( context, payload ) {
        let languageCode = context.rootState.languageCode
        let url = '/api/milestones/getMilestoneIndicators/'
        url += payload.milestoneDomainID + '/'
        url += payload.milestoneCategoryID + '/'
        url += languageCode

        return api.get( url )
    },
    newDomain( context, formID ) {
        return api.get( '/api/milestones/newDomain/' + formID )
    },
    newDomainCountry( context, domainID ) {
        return api.get( '/api/milestones/newDomaincountry/' + domainID )
    },
    newMilestonecategory( context, milestoneCountryID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/milestones/newMilestonecategory/' + milestoneCountryID + '/' + languageCode )
    },
    newMilestoneIndicator( context, payload ) {
        let languageCode = context.rootState.languageCode
        let url = '/api/milestones/newMilestoneIndicator/'
        url += payload.milestoneDomainID + '/'
        url += payload.milestoneCategoryID + '/'
        url += languageCode

        return api.get( url )
    },
    saveDomain( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveDomain', formData )
    },
    saveMilestoneorder( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestoneorder', formData )
    },
    saveMilestoneCategoryOrder( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestoneCategoryOrder', formData )
    },
    saveMilestoneIndicatorOrder( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestoneIndicatorOrder', formData )
    },
    saveDomainAttributes( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveDomainAttribute', formData )
    },
    saveMilestonecategory( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestonecategory', formData )
    },
    saveMilestoneindicator( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestoneindicator', formData )
    },
    saveMilestoneDomainCategory( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveMilestoneDomainCategory', formData )
    },
    saveCategoryPicture( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveCategoryPicture', formData )
    },
    saveIndicatorPicture( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/saveIndicatorPicture', formData )
    },
    removeMilestone( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/milestones/deleteMilestone', formData )
    }

}