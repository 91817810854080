<template>
  <div class="checkbox-radio-container">
    <i
        class="mdi mdi mdi-checkbox-marked mdi-med pointer"
        :class="boxColor + '-color'"
        @click="toggleCheckbox('F')"
        v-if="modelValue == 'T'"></i>
    <i
        v-else
        :class="boxColor + '-color'"
        @click="toggleCheckbox('T')"
        class="mdi mdi mdi-checkbox-blank-outline mdi-med pointer"></i>
    <label class="checkbox-label">{{ labelCaption }}</label>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput",
  props:[
      'modelValue', 'labelCaption', 'boxColor'
  ],
  emits: ['update:modelValue'],
  data() {
    return {
    }
  },
  methods:{
    toggleCheckbox(status){
      this.$emit('update:modelValue', status);
    }
  }
}
</script>

<style scoped>

</style>