<template>
  <div class="blix-left-side-panel" v-if="metadata">
      <div class="blix-left-side-panel-backbutton"
        v-if="metadata.backbutton=='WITH-BACKBUTTON'">
        <i class="mdi mdi-arrow-left-circle-outline"
            style="font-size:1.7em;position:relative;top:5px; left:15px;"></i>
        <label style="text-transform: uppercase;font-weight:normal;position: relative;left:30px;top:2px;">
          <translation :t="'BACKTOMAINMENU'"></translation>
        </label>
      </div>
      <div class="blix-leftside-panel-buttonholder">
        <action-button-default
            v-for="button in metadata.buttons"
            @removeButton="removeTheButton"
            :withDelete="true"
            :buttonClass="'LEFTSIDE_' + metadata.buttontype"
            :applicationpageID="applicationpageID"
            :button="button">
        </action-button-default>
      </div>
    <div class="clearfix" style="width:100%"></div>
    <div style="margin-top:20px;
                float:left;
                position:relative;
                border-top:2px solid #fff;
                padding:10px;
                width:100%;">

      <label
          @click="toggleButtontype"
          style="color:#fff;font-weight:normal;">
        <translation :t="'SQUAREBUTTONS'"></translation>
        <i class="fa fa-check"
           style="color:#fff; margin-top:3px;margin-left:5px;"
           v-if="metadata.buttontype == 'SQUARE'"></i>
        <i class="far fa-square pointer"
           style="color:#fff; margin-top:3px;margin-left:5px;"
           v-else></i>
      </label>

      <label
          @click="toggleBackbutton"
          style="color:#fff;font-weight:normal;margin-left:20px;">
        <translation :t="'BACKBUTTON'"></translation>
        <i class="fa fa-check"
           style="color:#fff; margin-top:3px;margin-left:5px;"
           v-if="metadata.backbutton=='WITH-BACKBUTTON'"></i>
        <i class="far fa-square pointer"
           style="color:#fff; margin-top:3px;margin-left:5px;"
           v-else></i>
      </label>

      <i class="fa fa-plus pointer"
         style="position:absolute;right:0px;top:5px; color:#fff;"
         @click.stop="addAButton"></i>

    </div>
  </div>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import InputTranslation from "@/components/controls/InputTranslation"
import ActionButtonDefault from '@/components/phoneapp/templates/blix/controls/ActionButtonDefault.vue'

export default {
  name : "LeftSidePanel",
  props: ['applicationpageID'],
  data() {
    return {
      metadata: false
    }
  },
  components: {InputTranslation, ActionButtonDefault},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] )
  },
  created() {
    this.setMetadata()
  },
  watch  : {},
  methods: {
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'applicationModule', ['getApplicationpageMetadata', 'addButton', 'removeButton', 'saveMetadataPropertyValue'] ),
    setMetadata() {
      let self = this
      self.getApplicationpageMetadata( self.applicationpageID )
          .then( ( result ) => {
            self.metadata = result.data
          } )
    },
    addAButton() {
      let self = this
      let payload = {}
      payload.applicationpageID = this.applicationpageID

      self.addButton( payload )
          .then( () => {
            self.setMetadata()
          } )
    },
    saveTheTranslation( translation, translationID ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    },
    toggleButtontype(){
      if( this.metadata.buttontype == 'SQUARE') {
        this.metadata.buttontype = 'RETANGULAR';
      }else if( this.metadata.buttontype == 'RETANGULAR') {
        this.metadata.buttontype = 'SQUARE';
      }

      let payload = {}
      payload.applicationpageID = this.applicationpageID
      payload.property = 'buttontype'
      payload.value = this.metadata.buttontype

      this.saveMetadataPropertyValue(payload)
    },
    toggleBackbutton(){
      console.log( this.metadata.buttontype );
      if( this.metadata.backbutton == 'WITH-BACKBUTTON') {
        this.metadata.backbutton = 'NO-BACKBUTTON';
      }else if( this.metadata.backbutton == 'NO-BACKBUTTON') {
        this.metadata.backbutton = 'WITH-BACKBUTTON';
      }

      let payload = {}
      payload.applicationpageID = this.applicationpageID
      payload.property = 'backbutton'
      payload.value = this.metadata.backbutton

      this.saveMetadataPropertyValue(payload)
    },
    removeTheButton( button ) {
      let self = this
      let payload = {}
      payload.applicationpageID = self.applicationpageID
      payload.buttonUuid = button.uuid

      self.removeButton( payload )
          .then( () => {
            self.setMetadata()
          } )
    }
  }
}
</script>

<style scoped>
.blix-left-side-panel {
  position: absolute;
  width: 320px;
  background-color: #f16a02;
  min-height: 100%;
}
.blix-leftside-panel-buttonholder{
  margin-top:10px;
  padding-left:20px;
}
.blix-left-side-panel-backbutton{
  color:#fff;
  height:40px;
  background-color:#F4A820;
  width:100%;
}
</style>


