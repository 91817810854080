<template>
  <div class="my-popup my-popup-lg">
    <div class="my-popup-header">
      <h4>
        <translation :t="'IMAGES'"></translation>
        <button class="close" @click="hideImagebank">x</button>
      </h4>
    </div>
    <div class="my-popup-body">
      <div class="row">
        <ul class="tags-row">
          <li v-for="tag in tags"
              :class="{'selected-tag': selectedTags.includes(tag.id)}"
              @click="toggleSelectTag(tag)">
            {{ tag.translation }}
          </li>
        </ul>
      </div>
      <hr/>
      <div class="row">
        <div class="col-4">
          <tree :tree="tree"
                :treeType="entities.IMAGE_MANAGER"
                @refreshTree="refreshTree"
                @atSelection="setTreeID"
                :hideIcons="true"
                v-if="tree.length > 0"></tree>
        </div>
        <div class="col-8">
            <ul class="images-cointainer">
              <li v-for="image in images" @click="selectImage(image)">
                <img :src="image.thumbnail"/>
              </li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Tree from "@/components/cms/Tree"

export default {
  name : "Imagebank",
  props: [],
  emits: ['hideImagebank', 'selectImage'],
  data() {
    return {
      tree        : [],
      treeID      : 1,
      selectedTags: [],
      tags        : [],
      images      : []
    }
  },
  components: {Tree},
  computed  : {
    ...mapState( ['entities'] ),
    ...mapGetters( 'treeModule', ['selectedTreenodeID'] )
  },
  created() {
    let self = this
    self.refreshTree()
    self.getTagsByType( 'IMAGETAG' )
        .then( result => {
          self.tags = result.data
        } )
    this.getImages()
  },
  methods: {
    ...mapActions( 'tagModule', ['getTagsByType'] ),
    ...mapActions( 'treeModule', ['getTree', 'getTreeItemsByTags'] ),
    refreshTree() {
      let self = this
      self.getTree( self.entities.IMAGE_MANAGER )
          .then( result => {
            self.tree = result.data
          } )
    },
    setTreeID( treeID ) {
      this.treeID = treeID
      this.getImages()
    },
    getImages() {
      let self = this
      let payload = {}
      payload.treeID = this.treeID
      payload.tags = this.selectedTags

      this.getTreeItemsByTags( payload )
          .then( result => {
            self.images = result.data
          } )
    },
    toggleSelectTag( tag ) {
      let tagIndex = this.selectedTags.indexOf( tag.id )

      if ( tagIndex === -1 ) {
        this.selectedTags.push( tag.id )
      } else {
        this.selectedTags.splice( tagIndex, 1 )
      }
      this.getImages()
    },
    hideImagebank() {
      this.$emit( 'hideImagebank' )
    },
    selectImage( image ) {
      this.$emit( 'selectImage', image )
    }
  }
}
</script>

<style scoped>
.tags-row, .images-cointainer {
  padding: 0;
  margin: 0;
  list-style: none;
}

.tags-row li {
  float: left;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #ebebeb;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.tags-row li:hover {
  background-color: #e8cef8;
}

.tags-row li.selected-tag {
  background-color: #6700a9;
  color: #fff;
}
.images-cointainer{
  max-height:500px;
  overflow:auto;
}
.images-cointainer li {
  float: left;
  height: 130px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 10px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.images-cointainer li > img {
  max-height: 100px;
}

.images-cointainer li:hover {
  background-color: #ebebeb;
}
</style>