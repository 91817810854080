

import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Tooltip from "./components/widgets/Tooltip.vue"
import Translation from "./components/controls/Translation.vue"
import ValidatedInput from "./components/controls/ValidatedInput.vue"
import Alertme from "./components/widgets/Alertme.vue"
import store from './store/index.js'
import './assets/styles.css'
import './assets/myfroala.css'
import './assets/blix.css'


const app = createApp(App)
app.component('translation', Translation)
app.component('tooltip', Tooltip)
app.component('validated-input', ValidatedInput)
app.component('Alertme', Alertme)
app.use(store)
app.use(router)
app.provide('someVarName', 'this is a var')
app.mount('#app')
// window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app.constructor


