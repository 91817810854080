<template>
<h1>User organizations</h1>
</template>

<script>
export default {
name: "Organizationusers"
}
</script>

<style scoped>

</style>