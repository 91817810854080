<template>
  <div class="input-group" v-if="textarea">
    <textarea class="form-control form-control-sm"
              :rows="rows"
              @keyup.enter="saveTheTranslation"
              v-model="toTranslate"
              v-if="saveOnEnter">
    </textarea>
    <textarea class="form-control form-control-sm"
              :rows="rows"
              @keyup="saveTheTranslation"
              v-model="toTranslate"
              v-else>

    </textarea>
    <div class="input-group-append parent-popover pointer"
         @click.stop="setTranslator">
      <tooltip :tip="'SHOWTRANSLATION'" :placement="'LEFT'"></tooltip>
      <span class="input-group-text
                  bg-transparent border-left-0">
        <i class="far fa-flag">
        </i>
      </span>
    </div>
  </div>
  <div class="input-group"
       v-else>
    <input type="text"
           style="background-color:#fff"
           class="form-control  form-control-sm"
           v-if="saveOnEnter"
           @keyup.enter="saveTheTranslation"
           v-model="toTranslate"/>
    <input type="text"
           v-else
           style="background-color:#fff"
           class="form-control  form-control-sm"
           @keyup="saveTheTranslation"
           v-model="toTranslate"/>
    <div class="input-group-append parent-popover pointer"
         @click.stop="setTranslator">
      <tooltip :tip="'SHOWTRANSLATION'" :placement="'LEFT'"></tooltip>
      <span class="input-group-text
                  bg-transparent border-left-0">
        <i class="far fa-flag">
        </i>
      </span>
    </div>
    <div class="input-group-append parent-popover pointer"
         v-if="deleteID"
         @click.stop="removeAction">
      <span class="input-group-text
                  bg-transparent border-left-0">
        <i class="fa fa-trash redish">
        </i>
      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "InputTranslation",
  props: ['identifierID', 'toTranslate', 'saveOnEnter', 'nonEmitSave', 'deleteID', 'textarea', 'rows'],
  emits: ['saveTranslation', 'removeAction'],
  data() {
    return {
      timeout: null
    }
  },
  components: {},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] )
  },
  methods   : {
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    setTranslator() {
      this.showHideTranslator( 'SHOW' )
      this.getTranslations( this.identifierID )
      this.setToTranslate( this.toTranslate )
      this.setIdentifierID( this.identifierID )
    },
    saveTheTranslation() {
      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
      this.timeout = setTimeout( _ => {
        if ( this.nonEmitSave ) {
          let payload = {}
          payload.languageCode = this.languageCode
          payload.identifierID = this.identifierID
          payload.translation = this.toTranslate

          this.saveTranslationByIdentifier( payload )
        } else {
          this.$emit( 'saveTranslation', this.toTranslate, this.identifierID )
        }
      }, this.saveOnEnter ? 0 : 400 )
    },
    removeAction() {
      this.$emit( 'removeAction', this.deleteID )
    }
  }
}
</script>

<style scoped>

</style>