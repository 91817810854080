/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import userMutations from './userMutations.js';
import userActions from './userActions.js';
import userGetters from './userGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            users            : [],
            organizationUsers: [],
            selectedUser     : {
                id             : 0,
                firstname      : '',
                lastname       : '',
                email          : '',
                languagecode   : '',
                blocked        : 'F',
                archived       : 'F',
                organization_id: null
            }
        }
    },
    mutations : userMutations,
    actions   : userActions,
    getters   : userGetters
};


