<template>
  <label class="">
    <translation :t="'LABELFORINPUT'"></translation>
  </label>
  <div class="input-group">
    <input v-model="metaData.label.value"
           @keyup="saveTypedMetaData"
           class="form-control form-control-sm"/>
    <div class="input-group-append"
         style="position:relative"
         @click="toggleShowTranslations(metaData.label)">
      <span class="input-group-text
                    bg-transparent border-left-0">
        <i class="far fa-flag"></i>
      </span>
    </div>
  </div>
  <hr/>
  <div class="row">
    <div class="col-12">
      <label>
        <translation :t="'ROWS'"></translation>
      </label>
      <button class="btn btn-info"
              @click="saveTheMetadata('PLUS')">+
      </button>
      <label style="width:15px;text-align: center;font-weight:bold;">
        {{ metaData.rows.value }}
      </label>
      <button class="btn btn-info"
              @click="saveTheMetadata('MINUS')"
              :disabled="metaData.rows.value == 0">-
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation";

export default {
  name : "Textarea",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      timeout: null
    }
  },
  components: {
    Translation
  },
  computed  : {
    ...mapGetters( ['languageCode'] )
  },
  methods   : {
    ...mapActions( 'formpageModule', ['saveMetaData'] ),
    saveTypedMetaData() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.$emit( 'saveMetaData', self.metaData )
      }, 700 );
    },
    saveTheMetadata(type){
      if( type == 'MINUS') this.metaData.rows.value--
      if( type == 'PLUS') this.metaData.rows.value++
      this.$emit( 'saveMetaData', this.metaData )
    },
    toggleShowTranslations( translator ) {
      this.$emit( 'showTranslator', translator )
    }
  }
}
</script>

<style scoped>

</style>