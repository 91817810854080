/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 28/10/2020
 * Time: 10:23
 */


import axios from 'axios'
import store from '../store/index.js'


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

// "Content-Type": 'application/json'
// "Content-type": 'application/x-www-form-urlencoded',
const baseHeaders = {
    "Content-type": 'application/x-www-form-urlencoded',
    "X-Requested-With" : 'XMLHttpRequest'
}
const baseURL = process.env.VUE_APP_BASE_URL

const guest = axios.create({
    baseURL: baseURL,
    headers: baseHeaders
})

const api = axios.create({
    baseURL: baseURL,
    headers: baseHeaders
})

api.interceptors.response.use(
    function (response) {
        return response
    },
    function (error){
        if( error.response.status === 401 ){
            store.dispatch('auth/logOut')
        }else{
            return Promise.reject(error)
        }
    }
)

export {guest, api}
