<template>
  <div class="row">
    <div class="col-12 col-sm-12 mt-4">
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'TRANSLATIONS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div class="row">
              <div class="col-6">
                <input class="form-control"
                       v-model="searchTranslation"/>
              </div>
              <div class="col-6">
                <select class="form-control"
                        @change="getTranslations"
                        v-model="selectedLanguage">
                  <option
                      v-for="language in translatedLanguages"
                      :value="language.languagecode">
                    {{ language.translation }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr/>
              </div>
            </div>
            <div class="scrollcontainer-for-translations">
              <div class="row"
                   style="border-bottom:1px solid #b2b2b2"
                   v-for="translation in filteredTranslations">
                <div class="col-6">
                  <p style="text-align: right;margin-top:5px;">
                    {{ translation['EN'] }}
                  </p>
                </div>
                <div class="col-6">
                  <input type="text"
                         class="form-control"
                         @keyup="saveTheTranslation(translation)"
                         v-model="translation.translation"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex'

export default {
  name    : "Translations",
  data() {
    return {
      searchTranslation: '',
      selectedLanguage : '',
      timeout          : null
    }
  },
  computed: {
    ...mapGetters('translate', ['allTranslations']),
    ...mapGetters(['translatedLanguages', 'languageCode']),
    filteredTranslations() {
      const self = this;
      console.log('inside filter');
      return self.allTranslations.filter((translation) => {
        return translation.EN.toLowerCase().indexOf(
            self.searchTranslation.toLowerCase()) >= 0
      })
    }
  },
  created() {
    this.selectedLanguage = this.languageCode
    this.getAllTranslations(this.languageCode)
  },
  methods : {
    ...mapActions('translate', ['getAllTranslations', 'saveTranslation']),
    getTranslations() {
      this.getAllTranslations(this.selectedLanguage)
    },
    saveTheTranslation(translation) {
      const self = this
      console.log( 'insidesave', translation.translation );
      if (self.timeout) {
        clearTimeout(self.timeout);
        self.timeout = null;
      }
      self.timeout = setTimeout(function () {
        const form = new FormData()
        form.append('id', translation.id)
        form.append('translation', translation.translation)

        self.saveTranslation(form);
      }, 400);

    }
  }
}
</script>

<style scoped>
.scrollcontainer-for-translations {
  height: 600px;
  overflow: auto;
}
</style>