/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '@/plugins/axios'

export default {
    getTranslations( context, identifierID ) {
        api.get( '/api/translator/getTranslations/' + identifierID )
            .then( function ( result ) {
                context.commit( 'setTranslations', result.data )
            } )
    },
    translateIt(context, translationData){
        return api.post('/api/translator/translateIt', translationData)
    },
    translateAll(context, translationData){
        return api.post('/api/translator/translateAll', translationData)
    },
    showHideTranslator( context, status = false ) {
        context.commit( 'showHideTranslator', status )
    },
    setToTranslate( context, toTranslate ) {
        context.commit( 'setToTranslate', toTranslate )
    },
    setIdentifierID( context, identifierID ) {
        context.commit( 'setIdentifierID', identifierID )
    }
}