<template>
  <h1 style="z-index:999999999999">The app is being prepared, hold on....</h1>
  <div class="spinner-overlay">
    <div class="loader"></div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "SpinnerOverlay",
  props: ['text', 'spinner'],
  emits: [],
  data() {
    return {}
  },
  components: {},
  computed  : {},
  created() {
  },
  methods: {}
}
</script>

<style scoped>
.spinner-overlay{
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background-color:#fff;
  z-index:999999;
  opacity:.8;
}
</style>