<template>
  <div class="card mb-4">
    <div class="card-header"
         :class="domainAttribute.active ? 'bg-warning': 'bg-primary text-white'"
         @click="setActiveDomainAttribute">
      <strong>{{ countryName }}</strong> |
      {{ domainAttribute.age_start }}
      <translation :t="domainAttribute.age_start_type"></translation>
      -
      {{ domainAttribute.age_end }}
      <translation :t="domainAttribute.age_end_type"></translation>
      <i class="fa fa-trash right-icon mr-3"
         @click.stop="removeMilestoneDomainattribute"
         :class="{'shinethrough':domainAttribute.milestonecategories.length > 0 }"></i>
    </div>
    <div class="card-body"
         v-if="domainAttribute.active">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <translation :t="'COUNTRY'"></translation>
          </span>
        </div>
        <select class="form-control"
                @change="saveTheDomainAttributes"
                v-model="domainAttribute.country_id">
          <option value="null">
            <translation :t="'SELECTIT'"></translation>
          </option>
          <option v-for="country in countries"
                  :value="country.id">
            {{ country.translation }}
          </option>
        </select>
      </div>
      <div class="input-group mt-2">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <translation :t="'STARTAGE'"></translation>
          </span>
        </div>
        <input type="number"
               class="form-control"
               min="0"
               step="1"
               style="text-align:right"
               v-model="domainAttribute.age_start"
               @change="checkAge">
        <select class="form-control"
                @change="checkAge"
                v-model="domainAttribute.age_start_type">
          <option value="MONTH">
            <translation :t="'MONTH'"></translation>
          </option>
          <option value="YEAR">
            <translation :t="'YEAR'"></translation>
          </option>
        </select>
        <div class="input-group-prepend">
          <span class="input-group-text">
            <translation :t="'ENDAGE'"></translation>
          </span>
        </div>
        <input type="number"
               class="form-control"
               style="text-align:right"
               min="0"
               step="1"
               v-model="domainAttribute.age_end"
               @change="checkAge">
        <select class="form-control"
                @change="checkAge"
                v-model="domainAttribute.age_end_type">
          <option value="MONTH">
            <translation :t="'MONTH'"></translation>
          </option>
          <option value="YEAR">
            <translation :t="'YEAR'"></translation>
          </option>
        </select>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <translation :t="'POORLY'"></translation>
              </span>
            </div>
            <input class="form-control"
                   style="text-align:right"
                   type="number"
                   step="1"
                   v-model="domainAttribute.poor_score"
                   @change="checkScore"
                   min="0">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <translation :t="'MODERATE'"></translation>
              </span>
            </div>
            <input class="form-control"
                   style="text-align:right"
                   type="number"
                   step="1"
                   v-model="domainAttribute.moderate_score"
                   @change="checkScore"
                   min="0">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <translation :t="'SUFFICIENTLY'"></translation>
              </span>
            </div>
            <input class="form-control"
                   style="text-align:right"
                   type="number"
                   step="1"
                   v-model="domainAttribute.sufficient_score"
                   @change="checkScore"
                   min="0">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "MilestoneAttribute",
  props: ['domainAttribute'],
  emits: ['setActiveDomainAttribute'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapState( 'translate', ['countries'] ),

    countryName() {
      let country = this.countries.find( c => c.id == this.domainAttribute.country_id )

      return country ? country.translation : ''
    }
  },
  created() {
  },
  methods: {
    ...mapActions( 'milestoneModule', ['saveDomainAttributes', 'removeMilestone'] ),
    checkAge() {
      this.domainAttribute.age_start = Number( this.domainAttribute.age_start ) * 1
      this.domainAttribute.age_end = Number( this.domainAttribute.age_end ) * 1

      let startAge = this.domainAttribute.age_start
      let endAge = this.domainAttribute.age_end

      let startAgeMonth =
              this.domainAttribute.age_start_type == 'YEAR' ? startAge * 12 : startAge
      let endAgeMonth =
              this.domainAttribute.age_end_type == 'YEAR' ? endAge * 12 : endAge

      if ( startAgeMonth > endAgeMonth ) endAgeMonth = startAgeMonth

      this.domainAttribute.age_start =
          this.domainAttribute.age_start_type == 'YEAR' ? Math.ceil( startAgeMonth / 12 ) : startAgeMonth
      this.domainAttribute.age_end =
          this.domainAttribute.age_end_type == 'YEAR' ? Math.ceil( endAgeMonth / 12 ) : endAgeMonth

      this.saveDelayedDomainAttributes()
    },
    checkScore() {
      if ( Number( this.domainAttribute.poor_score ) > Number( this.domainAttribute.moderate_score ) )
        this.domainAttribute.moderate_score = this.domainAttribute.poor_score

      if ( Number( this.domainAttribute.moderate_score ) > Number( this.domainAttribute.sufficient_score ) )
        this.domainAttribute.sufficient_score = this.domainAttribute.moderate_score

      this.saveDelayedDomainAttributes()
    },
    saveDelayedDomainAttributes() {
      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
      this.timeout = setTimeout( _ => {
        this.saveTheDomainAttributes()
      }, 700 );
    },
    saveTheDomainAttributes() {
      this.saveDomainAttributes( this.domainAttribute )
    },
    setActiveDomainAttribute() {
      if ( this.domainAttribute.active ) return this.domainAttribute.active = false

      this.$emit( 'setActiveDomainAttribute', this.domainAttribute.id )
    },
    removeMilestoneDomainattribute() {
      if ( this.domainAttribute.milestonecategories.length > 0 ) return
      let payload = {}
      payload.milestoneID = this.domainAttribute.id
      payload.type = 'MILESTONEDOMAINATTRIBUTE'

      this.removeMilestone( payload )
          .then( _ => {
            this.$el.parentNode.removeChild( this.$el );
          } )
    }
  }
}
</script>

<style scoped>

.right-icon {
  float: right;
  font-weight: 700;
  line-height: 1;
  opacity: .8;
  cursor: pointer;
}

.right-icon:hover {
  opacity: 1;
}
</style>