<template>

  <div class="row">
    <div class="col-12 col-sm-7 mt-4">
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'ORGANIZATIONS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <organizationlist></organizationlist>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-5 mt-4" v-if="editOrganization">
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'ORGANIZATIONDETAILS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <organizationdetails
              v-if="selectedOrganization">
          </organizationdetails>
        </div>
      </div>
    </div>



    <div class="col-12 col-sm-5 mt-4" v-else>
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'ORGANIZATIONDETAILS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <userdetails></userdetails>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import Organizationlist from "@/components/organizations/Organizationlist";
import Organizationdetails from "@/components/organizations/OrganizationDetails";
import Organizationuserlist from "@/components/users/OrganizationUserlist"
import Userdetails from "@/components/users/UserDetails"
import Translation from "@/components/controls/Translation"

export default {
  components: {
    Translation,
    Organizationlist,
    Organizationdetails,
    Organizationuserlist,
    Userdetails
  },
  data() {
    return {
      model:null,
      config: {
        events: {
          initialized: function () {
            console.log('initialized')
          }
        }
      }
    }
  },
  computed  : {
    ...mapGetters( 'organization', ['selectedOrganization', 'editOrganization'] )
  },
  created() {
  },
  mounted() {
    if ( sessionStorage.getItem( 'show_change_password' ) ){
      $( '#change-password' ).modal();
      sessionStorage.removeItem( 'show_change_password' )
    }

  },
  methods   : {}
}
</script>

<style scoped>

</style>