<template>
  <div class="my-backdrop"></div>
  <div class="my-popup my-popup-md" style="width:900px;">
    <div class="my-popup-header">
      FORMFLOW
      <button class="close" @click="toggleShowFormflow">x</button>
    </div>
    <div class="my-popup-body">
<!--      <div>{{ formelement.elementtype.elementtypekey }}</div>-->
      <div v-if="formelement.elementtype.elementtypekey">

        <div class="row">
          <div class="col-12">
            <div v-if="formelement.metadata.inputType && formelement.metadata.inputType.value">
              <dateconditions
                  :formelement="formelement"
                  v-if="formelement.elementtype.elementtypekey == 'INPUTFIELD'">
              </dateconditions>
            </div>

            <checkboxconditions
                :formelement="formelement"
                v-if="['CHECKBOX', 'SELECTBOX'].find( e=>{ return e == formelement.elementtype.elementtypekey}) ">
            </checkboxconditions>

            <hr/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Dateconditions from "./Dateconditions"
import Checkboxconditions from "./Checkboxconditions"

export default {
  name : "Formflow",
  props: ['formelement'],
  emits: ['toggleShowFormflow'],
  data() {
    return {}
  },
  components: {Dateconditions, Checkboxconditions},
  computed  : {},
  created() {
  },
  methods: {
    ...mapActions( 'formpageModule', ['getFlowmelement', 'addCondition', 'saveConditions'] ),
    toggleShowFormflow() {
      this.$emit( 'toggleShowFormflow' )
    }
  }
}
</script>

<style scoped>

</style>