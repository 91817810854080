/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import modalMutations from './modalMutations.js';
import modalActions from './modalActions.js';
import modalGetters from './modalGetters.js';

export default {

    namespaced: true,
    state() {
        return {
            activeButton            : '',
            showPictureselector     : '',
            pictureType             : '',
            modalPictures           : '',
            selectedPicture         : '',
            activePicturepage       : '',
            showButtonActionselector: '',
            selectedButtonAction    : '',
            activeActionButton      : '',
            showTreeModal           : '',
            selectedModalpage       : '',
            showRegionPicture       : '',
            pictureFormat           : 'PORTRAIT',
            pageTypeID              : null
        }
    },
    mutations: modalMutations,
    actions  : modalActions,
    getters  : modalGetters,


};


