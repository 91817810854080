<template>
  <div class="row vh-100 justify-content-between align-items-center login-background">
    <div class="col-12">
      <form
          action="#"
          class="row row-eq-height login-form lockscreen mt-5 mb-5">
        <div class="lock-image col-12 col-sm-5">
        </div>
        <div class="login-form col-12 col-sm-7">
          <div class="form-group mb-3">
            <label>
              <translation :t="'LANGUAGE'"></translation>
            </label>
            <select
                class="form-control"
                v-model="language"
                @change="setlanguage()">
              <option
                  v-for="language in languages"
                  :value="language.languagecode">
                {{ language.translation }}
              </option>
            </select>
          </div>
          <div class="form-group mb-3">
            <h3 v-if="requestPassword">
              <translation :t="'REQUESTNEWPASSWORD'"></translation>
            </h3>
            <h3 v-else>
              <translation :t="'LOGIN'"></translation>
              RehApp CMS
            </h3>
            <div
                class="alert alert-danger"
                v-if="loginError"
                role="alert">
              {{ loginAlertMessage }}
            </div>
            <div
                class="alert alert-success"
                v-if="loginSuccess"
                role="alert">
              {{ loginAlertMessage }}
            </div>
          </div>
          <div class="form-group mb-3">
            <validated-input
                v-model="email"
                :validations="'email'"
                :placeholder="'EMAILADDRESS'"
                :errorMessage="'VALIDEMAIL'"
                :labelCaption="'EMAILADDRESS'"></validated-input>
          </div>

          <div
              class="form-group mb-3"
              v-if="! requestPassword">
            <validated-input
                v-model="password"
                :type="'password'"
                :validations="'required'"
                :lengte="8"
                :placeholder="'PASSWORD'"
                :errorMessage="'PASSWORDINPUT'"
                :labelCaption="'PASSWORD'"></validated-input>
          </div>

          <div class="form-group mb-3">

            <div class="form-group">
              <span v-if="requestPassword">
                <button
                    class="btn btn-primary"
                    :disabled="! validEmail"
                    @click.prevent="newPasswordRequest"
                    type="submit">
                  <translation :t="'REQUEST'"></translation>
                </button>
                <button
                    class="btn btn-warning"
                    @click="requestPassword = false"
                >
                  <translation :t="'CANCEL'"></translation>
                </button>
              </span>
              <span v-else>
                <button
                    class="btn btn-primary"
                    :disabled="! validEmail || !validPassword"
                    @click.prevent="userlogin"
                    type="submit">
                  <translation :t="'LOGIN'"></translation>
                </button>
                <button
                    style="margin-left:20px;"
                    class="btn btn-warning"
                    @click="setPasswordRequest"
                    type="button">
                  <translation :t="'FORGOTPW'"></translation>
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import flag from "../components/controls/Flag.vue"
import {guest, api} from "@/plugins/axios";
import Translation from "@/components/controls/Translation";
import axios from 'axios'

export default {
  components: {
    Translation,
    flag
  },
  data() {
    return {
      email            : '',
      password         : '',
      error            : {},
      invalidEmail     : false,
      requestPassword  : false,
      loginError       : false,
      loginSuccess     : false,
      loginAlertMessage: '',
      language         : null
    }
  },
  created() {
    this.language = this.languageCode
  },
  computed: {
    ...mapGetters( ['languageCode', 'languages'] ),
    ...mapGetters( 'translate', ['translations'] ),
    ...mapState( 'auth', ['loginFailed'] ),
    validEmail() {
      return ra.validate.email( this.email )
    },
    validPassword() {
      return this.password.length >= 8
    }
  },
  methods : {
    ...mapActions( ['changeLanguageCode'] ),
    ...mapActions( 'auth', ['createLoginState'] ),
    downloadItem() {
      guest.get( '/api/downloadAndroidApp' )
          .then( ( response ) => {
            var fileURL = window.URL.createObjectURL( new Blob( [response.data] ) );
            var fileLink = document.createElement( 'a' );
            fileLink.href = fileURL;
            fileLink.setAttribute( 'download', 'RehappMobileApp.apk' );
            document.body.appendChild( fileLink );
            fileLink.click();
          } );
    },
    userlogin() {
      const self = this
      const form = new FormData()
      form.append( 'email', this.email )
      form.append( 'password', this.password )

      guest.get( '/sanctum/csrf-cookie' )
          .then( respons => {
            guest.post( '/api/login', form )
                .then( function ( response ) {
                  if ( response.data.success == false ) {
                    self.loginError = true
                    return self.loginAlertMessage =
                        ra.findTranslation( self.translations, 'WRONGCOMBINATION' )
                  }

                  self.createLoginState( response.data )
                  location.reload()
                } )
                .catch(
                    ( err ) => {
                      self.loginError = true

                      self.loginAlertMessage =
                          ra.findTranslation( self.translations, 'WRONGCOMBINATION' )
                    } )
          } )
    },
    setPasswordRequest() {
      this.loginError = false
      this.requestPassword = true
    },
    newPasswordRequest() {
      const self = this
      const form = new FormData()
      form.append( 'email', this.email )
      form.append( 'type', 'CMS' )
      guest.post( '/api/requestpassword', form )
          .then( () => {
          } ).catch( ( error ) => {
      } ).then( () => {
        self.loginSuccess = true
        self.loginAlertMessage =
            ra.findTranslation( self.translations, 'EMAILPASSWORDSEND' )
        this.requestPassword = false
      } )
    },
    setlanguage() {
      this.changeLanguageCode( this.language )
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.lockscreen {
  max-width: 700px !important;
}
</style>


