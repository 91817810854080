/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    categories(state){
        return state.categories
    },
    selectedCategoryID(state){
        return state.selectedCategoryID
    },
    followup(state){
        return state.followup
    }
}
