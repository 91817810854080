<template>
  <div v-html="metaData.content.value" class="fr-view"></div>
  <button class="btn btn-success btn-small"
          @click="initFroala">
    <translation :t="'EDITPARAGRAPH'"></translation>
  </button>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"

export default {
  name : "Paragraph",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      timeout: null
    }
  },
  components: {
    Translation
  },
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters('editorModule', ['editorText', 'textType', 'textTypeID'])
  },
  watch:{
    editorText(){
      if( this.textType != 'FORMPAGE_PARAGRAPH' ) return
      if( this.textTypeID != this.formelementID) return

      this.metaData.content.value = this.editorText;
    }
  },
  methods   : {
    ...mapActions( 'editorModule', ['setTextTypeID', 'setTextType', 'showEditor', 'setShowEditor'] ),
    ...mapActions( 'formpageModule', ['saveMetaData'] ),
    initFroala(){
      this.setTextType( 'FORMPAGE_PARAGRAPH' )
      this.setTextTypeID( this.formelementID )
      this.setShowEditor( true )
    }
  }
}
</script>

<style scoped>

</style>