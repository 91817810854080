/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setMilestoneForm(state, milestoneForm){
        state.milestoneForm = milestoneForm
    },
    setMilestoneIndicators(state, milestoneIndicators){
        state.milestoneIndicators = milestoneIndicators
    },
    setActiveMilestoneDomainID(state, milestoneDomainID){
        state.activeMilestoneDomainID = milestoneDomainID
    },
    setActiveMilestoneCategoryID(state, milestoneCategoryID){
        state.activeMilestoneCategoryID = milestoneCategoryID
    }
}