<template>
  <div class="my-backdrop"></div>
  <div class="my-popup"
       style="width:600px;
       margin-left:-300px;
       position:fixed;"
       @click.stop>
    <div class="my-popup-header">
      <h3>
        <button class="close"
                @click.stop="chosenLink(false)">x
        </button>
      </h3>
    </div>
    <div class="my-popup-body">
      <ul class="pagetype-list">
        <li v-for="pageType in app.template.templatepages">
          <i class="fa fa-check" v-if="pageType.id == pageTypeID">
          </i>
          <i v-else class="far fa-square pointer"
             @click="setPageType(pageType)"></i>
          {{ pageType.translation }}
        </li>
        <li>
          <i class="fa fa-check" v-if="! pageTypeID">
          </i>
          <i v-else class="far fa-square pointer"
             @click="setPageType(null)"></i>
          actions
        </li>
      </ul>
      <div class="clearfix"></div>
      <hr/>
      <ul v-if=" ! pageTypeID" class="rehapp-list">
        <li @click="chosenLink('LOGIN')">
          login / logout
        </li>
        <li @click="chosenLink('DRAWER-ARTICLES')">
          Articles list
        </li>
      </ul>
      <span v-else>
        <ul v-if="pageTypePages" class="rehapp-list"
            v-for="page in pageTypePages.applicationpages">
          <li @click.stop="chosenLink(page)"
              :class="{'selected-rehapp-list-item': selectedLink == page.id }">
            {{ page.translation ? page.translation.translation : '#NB' }}
          </li>
        </ul>
      </span>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "ApplicationpageSelector",
  props: ['selectedLink'],
  emits: ['chosenLink'],
  data() {
    return {
      pageType : null,
      pageTypePages: []
    }
  },
  components: {},
  computed  : {
    ...mapState( 'applicationModule', ['app', 'selectedApplicationID'] ),
    ...mapState( 'modalModule', ['pageTypeID'] )
  },
  created() {
    if ( !this.app.template.templatepages[0] ) return

    if ( !this.pageTypeID )
      return this.setPageType( this.app.template.templatepages[0] )

    let pageType = this.app.template.templatepages.find( templatepage => {
      return templatepage.id == this.pageTypeID;
    } )

    this.setPageType( pageType )
  },
  watch  : {
    selectedApplicationID() {
      if ( !this.app.template.templatepages[0] ) return;

      this.setPageType( this.app.template.templatepages[0] )
    }
  },
  methods: {
    ...mapActions( 'modalModule', ['setPageTypeID'] ),
    setPageType( pageType ) {
      let self = this

      this.setPageTypeID( pageType ? pageType.id : false )

      self.pageTypePages =
          self.app.template.templatepages.find( templatepage => {
            return self.pageTypeID == templatepage.id
          } )
    },
    setActionpages(){
      this.pageType = 'ACTION'
      this.pageTypeID = null
    },
    setActionpage(){

    },
    chosenLink(page) {
      this.$emit( 'chosenLink', page, this.pageTypeID )
    }
  }
}
</script>

<style scoped>
.pagetype-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagetype-list li {
  float: left;
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  margin-right: 10px;
}

.pagetype-list li > i {
  margin-right: 1px;
}
</style>