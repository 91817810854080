<!--suppress ALL -->
<template>
  <ul style="padding:0; margin-top:10px">
    <organizationlistitem
        v-for="organization in organizations"
        :organization="organization">
    </organizationlistitem>
  </ul>
  <button class="btn btn-warning" @click="showNewOrganization">
    <translation :t="'NEW'"></translation>
  </button>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"
import Userlistitem from "@/components/users/Userlistitem"
import Organizationlistitem from "@/components/organizations/Organizationlistitem"

export default {
  components: {Translation, Organizationlistitem},
  props     : [],
  data() {
    return {}
  },
  computed  : {
    ...mapGetters( 'organization', ['organizations'] )
  },
  created() {
    this.getOrganizations()
  },
  methods   : {
    ...mapActions( 'organization', ['getOrganizations', 'resetOrganization', 'setOrganziationID'] ),
    showNewOrganization() {
      this.resetOrganization();
      this.setOrganziationID(0)
      $('#new-organization').modal('show')
    }
  }
}
</script>

<style scoped>

</style>