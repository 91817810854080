<template>
  <div class="tags-container" @click.stop>
    <div class="arrow-up"></div>
    <div class="tags-holder">
      <input class="form-control form-control-sm"
             style="margin-bottom:5px;"
             @keyup.enter.stop="newTag"
             @blur="hideTags"
             v-model="tagname"/>
      <div class="foundtags-holder"
           v-if="foundTags.length > 0 && tagname.length > 0">
        <ul class="foundtags">
          <li v-for="foundTag in foundTags" @click="addTheTag(foundTag)">
            {{ foundTag.translation.translation }}
          </li>
        </ul>
      </div>
      <ul style="padding:0;margin:0;">
        <li class="item-tag" v-for="itemTag in itemTags">
          {{ itemTag.translation ? itemTag.translation.translation : 'NB' }}
          <button class="remove-tag"
                  @click.stop="removeTheTag(itemTag)">x
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "Tags",
  props: ['tagType', 'tagTypeID', 'linkedTags'],
  data() {
    return {
      tagname : '',
      itemTags: []
    }
  },
  emits:['hideTags', 'refreshTags'],
  components: {},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'tagModule', ['tags'] ),
    foundTags() {
      let self = this
      return self.tags.filter( tag => {
        if ( tag.translation ) {
          let found = self.itemTags.find( t => {
            return t.id == tag.id
          } )
          return !found && tag.translation.translation.toUpperCase().search( self.tagname.toUpperCase() ) != -1
        }
      } );
    }
  },
  created() {
    this.itemTags = this.linkedTags
  },
  methods: {
    ...mapActions( 'tagModule', ['createTag', 'getItemTags', 'addTag', 'removeTag'] ),
    addTheTag( foundTag ) {
      let self = this
      let payload = {}
      payload.languageCode = this.languageCode
      payload.tagType = this.tagType
      payload.tagTypeID = this.tagTypeID //imageID
      payload.tagID = foundTag.id
      this.addTag( payload )
          .then( () => {
            self.$emit('refreshTags', payload)
          } )
    },
    hideTags(){
      this.$emit('hideTags')
    },
    newTag() {
      let self = this
      let payload = {}
      payload.languageCode = self.languageCode
      payload.tagType = self.tagType
      payload.tagTypeID = self.tagTypeID
      payload.tagname = self.tagname
      self.createTag( payload )
          .then( () => {
            self.$emit('refreshTags', payload)
          } )
    },
    removeTheTag( itemTag ) {
      let self = this
      let payload = {}
      payload.languageCode = self.languageCode
      payload.tagType = self.tagType
      payload.tagTypeID = self.tagTypeID
      payload.tagID = itemTag.id
      self.removeTag( payload )
          .then( () => {
            self.$emit('refreshTags', payload)
          } )
    }
  }
}
</script>

<style scoped>
.tags-container {
  position: absolute;
}

.tags-holder {
  position: absolute;
  width: 300px;
  height: auto;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  left: -150px;
  z-index: 999999999;
  box-shadow: #aaa 5px 5px 5px;
  /*overflow:auto;*/
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: relative;
  left: -4px;
  bottom: -1px;
  z-index: 9999999991;
}

.foundtags-holder {
  position: relative;
  top: -5px;
}

</style>