<template>
  <!--  <translators></translators>-->
  <revisions @replaceContent="replaceContent"></revisions>
  <!--  <phone-example :editorText="editorText"></phone-example>-->
  <Imagebank @hideImagebank="showImagebank = false"
             @selectImage="selectImage"
             v-if="showImagebank"></Imagebank>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div v-if="1 == 1">
            <!--            <div v-if="selectedArticleID">-->

            <div class="row">
              <div class="col-12">
                <div id="article-froala-editor"></div>
              </div>
            </div>
            <div class="row row-margin-top"
                 v-if="selectedTreenodeID && readWrite == 2">
              <div class="col-12">
                <button class="btn btn-success"
                        @click="saveArticleText(true)">
                  <translation :t="'SAVE'"></translation>
                </button>
                <button class="btn btn-info"
                        @click="showRevisions">
                  <translation :t="'REVISIONS'"></translation>
                </button>
                <button class="btn btn-primary"
                        @click="showImagebank = true">
                  <translation :t="'IMAGES'"></translation>
                </button>
                <div class="blurry"
                     :class="{'background-redish':blurry}"></div>
              </div>
            </div>
            <hr/>
            <div class="row row-margin-top">
              <div class="col-12">
                <ul style="list-style: none;margin:0;padding:0;">
                  <li class="language-selector-box"
                      @click="setSelectedLanguage(authorizedLanguage)"
                      :class="{'language-selector-box-selected': selectedLanguage == authorizedLanguage.languagecode}"
                      v-for="authorizedLanguage in authorizedLanguages">
                    {{ authorizedLanguage.translation }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>
            <h3>
              <translation :t="'SELECTANARTICLE'"></translation>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <i class="fa fa-folder-plus fa-2x dark-greenish hover-effect"
             @click="addTheTreenode(0)"></i>
          <tree :tree="tree"
                :treeType="entities.ARTICLESTREE"
                :withID="true"
                @refreshTree="refreshTree"
                @atSelection="atSelection"
                v-if="tree.length > 0"></tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import {api} from "@/plugins/axios";
import PhoneExample from "@/components/forms/formelements/phone/PhoneExample"
import Tree from "@/components/cms/Tree"
// import Translators from "@/components/widgets/Translators.vue"
import Translation from "@/components/controls/Translation";
import Revisions from "@/components/cms/Revisions"
import Imagebank from '@/components/widgets/Imagebank'

let articleEditor = null
export default {
  name: "Articles",
  data() {
    return {
      editorText        : '',
      tree              : [],
      timeout           : null,
      blurry            : false,
      showImagebank     : false,
      selectedTreenodeID: null,
      selectedLanguage  : null,
      articleText       : ''
    }
  },
  components: {Revisions, Translation, Tree, PhoneExample, Imagebank},
  computed  : {
    ...mapGetters( ['languageCode', 'entities'] ),
    ...mapState( ['authorizedLanguages'] ),
    readWrite() {
      let authorizedLanguage =
              this.authorizedLanguages.find( al => al.languagecode == this.selectedLanguage )

      if ( !authorizedLanguage ) return 0

      return authorizedLanguage.status
    }
  },
  watch     : {
    articleTextX() {
      let self = this
      self.editorText = self.articleTextX
      setTimeout( function () {
        articleEditor.html.set( self.editorText )
      }, 1000 );
    },
    authorizedLanguages() {
      this.setDefaultlanguage()
    }
  },
  created() {
    this.refreshTree()
    this.setDefaultlanguage()
  },
  methods: {
    ...mapActions( 'treeModule', ['getTree', 'addTreenode', 'getNodeContent'] ),
    ...mapActions( 'editorModule', ['saveEditortext', 'getRevisions', 'setRevisions', 'toggleRevisions'] ),
    refreshTree() {
      let self = this
      self.getTree( 'ARTICLESTREE' )
          .then( result => {
            self.tree = result.data
          } )
    },
    addTheTreenode() {
      let self = this
      let payload = new FormData()
      payload.parentID = 0
      payload.treeType = self.entities.ARTICLESTREE
      payload.languageCode = self.languageCode

      let formData = new FormData();
      formData.append( 'formData', JSON.stringify( payload ) )

      this.addTreenode( formData )
          .then( () => {
            self.refreshTree()
          } )
    },
    setSelectedLanguage( language ) {
      if( this.blurry ) return

      this.selectedLanguage = language.languagecode
      if ( this.selectedTreenodeID ) this.atSelection( this.selectedTreenodeID )
    },
    atSelection( treenodeID ) {
      let self = this

      if( ! treenodeID || !self.selectedLanguage ){
        this.initialiseEditor( true, ()=>{
          return articleEditor.html.set( '' )
        } )
        return
      }

      let payload = {}

      self.selectedTreenodeID = treenodeID

      payload.languageCode = self.selectedLanguage
//       payload.treenodeID = self.selectedTreenodeID
      payload.treenodeID = treenodeID

      self.initialiseEditor( this.readWrite != 2, () => {
        self.getNodeContent( payload )
            .then( result => {
//               if ( this.readWrite == 1 ) {
//                 this.articleText = result.data
//               } else if ( readWrite == 2 ) {
                articleEditor.html.set( result.data )
//               }
            } )
      } )
    },
    setDefaultlanguage() {
      if ( this.selectedLanguage ) return

      if ( this.languageCode ) {
        this.authorizedLanguages.forEach( l => {
          if ( l.languagecode == this.languageCode ) this.selectedLanguage = l.languagecode
        } )
      }

      if ( this.authorizedLanguages.length > 0 && !this.selectedLanguage )
        this.selectedLanguage = this.authorizedLanguages[0].languagecode
    },
    showRevisions() {
      let self = this
      let payload = {}
      payload.languageCode = this.selectedLanguage
      payload.textTypeID = this.selectedTreenodeID
      payload.textType = this.entities.ARTICLE

      self.toggleRevisions()
      self.getRevisions( payload )
    },
    replaceContent( content ) {
      let self = this
      self.saveArticleText( true, () => {
        articleEditor.html.set( content )
        self.saveArticleText( true )
      } )
    },
    selectImage( image ) {
      let article = articleEditor.html.get()

      article += '<image src="' + image.imageUrl + '" />'

      articleEditor.selection.save();
      articleEditor.selection.restore();
      articleEditor.html.insert( '<image src="' + image.imageUrl + '" />' )

      this.showImagebank = false
    },
    updateEditortext() {
      this.editorText = articleEditor.html.get()
    },
    saveArticleText( forceRevision, callback ) {
      if( ! this.selectedLanguage ) return
      let payload = {}
      payload.languageCode = this.selectedLanguage
      payload.html = articleEditor.html.get()
      payload.textTypeID = this.selectedTreenodeID
      payload.textType = this.entities.ARTICLE
      payload.forceRevision = forceRevision

      this.saveEditortext( payload )
          .then( () => {
            if ( typeof (callback) == 'function' ) callback()
          } )
    },
    saveEditorContent() {
      let self = this
      self.blurry = true
      if ( self.timeout ) return

      self.timeout = setTimeout( function () {
        self.timeout = null
        self.saveArticleText( false )
        self.blurry = false
      }, 2000 );
    },
    initialiseEditor( editorOff, callback ) {
      let self = this
      if ( articleEditor ) articleEditor.destroy();
      articleEditor = new FroalaEditor( '#article-froala-editor', {
        imageEditButtons       : ['imageReplace', 'imageAlign', 'imageRemove', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageUpload            : true,
        key                    : '7MD3aA3E3C5C4A3B2xROKLJKYHROLDXDRE1b1YYGRi1Bd1C4F4B3A2G3A14A15A12C4D5==',
        zIndex                 : 250,
        heightMin              : 200,
        heightMax              : 600,
        toolbarButtons         : ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'fontSize', 'paragraphFormat', 'formatUL', 'formatOL', 'indent', 'outdent', 'insertLink', 'insertTable', 'clearFormatting', 'html'],
        imageDefaultDisplay    : 'block',
        imageMaxSize           : 1024 * 500,
        imageAllowedTypes      : ['jpeg', 'jpg', 'png'],
        wordPasteModal         : true,
        wordPasteKeepFormatting: false,
        wordDeniedAttrs        : ['style', 'id'],
        attribution            : false,
        charCounterCount       : false,
        editorClass            : 'green-header',
        imageManagerLoadURL    : process.env.VUE_APP_BASE_URL + "/api/images/getImagemanagerImages",
        imageManagerPageSize   : 5,
        imageManagerLoadMethod : "GET",
        paragraphStyles        : {
          'Normal'         : 'normal',
          'greenish-header': 'Green header'
        },
        imageStyles            : {
          'image left': 'image-left'
        },     // Set a preloader.
        events                 : {
          initialized() {
            if( editorOff ) articleEditor.edit.off()
            callback()
          },
          'image.beforeUpload': function ( images ) {
            // Before image is uploaded
            const data = new FormData();
            data.append( 'file1', images[0] );

            api.post( '/api/texteditor/upload_image/' + self.selectedTreenodeID + '/EDITORIMAGE', data, {
              headers: {
                'accept'      : 'application/json',
                'Content-Type': `multipart/form-data`
              }
            } )
                .then( result => {
                  articleEditor.image.insert( result.data.link, null, null, articleEditor.image.get() );
                } ).catch( err => {
              console.log( err );
            } )
            return false;
          },
          'image.error'       : function ( error, response ) {
            console.log( error );
            console.log( response );
          },
          'image.insert'      : function () {
            alert( '' )
          },
          'paste.after' : function(){
            self.saveEditorContent()
          },
          'keyup'             : function () {
            self.saveEditorContent()
          },
          'contentChanged'    : function () {
            self.saveEditorContent()
          }
        }
      } )
    }
  }
}
</script>

<style scoped>

</style>