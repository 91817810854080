/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    loggedIn(state){
        return state.loggedIn
    }
}
