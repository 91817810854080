<template>
  <ul class="list-group">
    <li
        class="list-group-item list-group-item-dark"
        v-for="organizationUser in organizationUsers">
      {{ organizationUser.fullname }}
    </li>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "OrganizationUserlist",
  computed: {
    ...mapGetters( 'user', ['organizationUsers'] )
  }

}
</script>

<style scoped>

</style>