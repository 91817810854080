<template>
  <div
      class="modal fade"
      id="new-user">
    <div
        class="modal-dialog"
        role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
              class="modal-title"
              id="exampleModalLabel">
            <translation :t="'NEWUSER'"></translation>
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <userdetails></userdetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Userdetails from '@/components/users/UserDetails.vue'

export default {
name: "Neworganization",
  components:{
    Userdetails
  }
}
</script>

<style scoped>

</style>