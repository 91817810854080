<template>
  <translators></translators>
  <app-pages v-if="application"></app-pages>
  <div class="card bg-primary-light">
    <div class="card-body">
      <div class="row">
        <label class="col-6">
          <translation :t="'APPLICATION'"></translation>
        </label>
        <label class="col-6">
          <translation :t="'TEMPLATE'"></translation>
        </label>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="input-group" v-if=" ! editNewMode">
            <select class="form-control form-control-sm bg-white"
                    v-model="applicationID"
                    @change="setTheSelectedApplicationID">
              <option value="null">
                <translation :t="'SELECTIT'"></translation>
              </option>
              <option v-for="application in applications"
                      :value="application.id"
                      :key="application.id">
                {{ application.translation }}
              </option>
            </select>
            <div class="input-group-append bg-white"
                 style="position:relative">
                  <span class="input-group-text bg-transparent border-left-0">
                    <i class="far fa-flag pointer"
                       @click="showTranslator"></i>
                  </span>
              <span class="input-group-text bg-transparent border-left-0">
                <i class="fa fa-edit pointer" @click="setUpdateMode"></i>
              </span>
              <span class="input-group-text bg-transparent border-left-0">
                <i class="fa fa-plus color2 pointer"
                   @click="addApplication"></i>
              </span>
              <span class="input-group-text bg-transparent border-left-0"
                    @click="removeTheApplication"
                    v-if="application && application.applicationpages.length == 0">
                <i class="fa fa-trash redish pointer"></i>
              </span>
              <span class="input-group-text bg-transparent border-left-0"
                    v-else>
                <i class="fa fa-trash greyish"></i>
              </span>
            </div>
          </div>
          <input class="form-control-sm form-control"
                 v-model="applicationname"
                 @keyup.enter="saveTheApplication"
                 v-else>
        </div>
        <div class="col-6">
          <select class="form-control form-control-sm bg-grey"
                  :class="{'bg-white':selectTemplate }"
                  :disabled="! selectTemplate"
                  @change="setTheSelectedTemplateID"
                  v-model="templateID">
            <option value="null">
              <translation :t="'SELECTIT'"></translation>
            </option>
            <option v-for="t in templates"
                    :value="t.id"
                    :key="t.id">
              {{ t.translation }}
            </option>
          </select>
        </div>
      </div>
      <div class="row" v-if="template">
        <div class="col-12">
          <hr/>
          <component :is="template.component"
                     v-if="app.template"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import Translators from "@/components/widgets/Translators.vue"
import Contentone from '@/components/phoneapp/templates/contentone/Contentone.vue'
import Blix from '@/components/phoneapp/templates/blix/Blix.vue'
import AppPages from "@/components/phoneapp/templates/AppPages";

export default {
  name: "Application",
  data() {
    return {
      editNewMode    : false,
      applicationname: '',
      applicationID  : null,
      templateID     : null
    }
  },
  components: {
    AppPages,
    Translators, Blix, Contentone
  },
  computed  : {
    ...mapState( 'applicationModule', ['selectedApplicationID', 'applications', 'app'] ),
    ...mapState( 'templateModule', ['templates'] ),
    ...mapGetters( 'applicationModule', ['application'] ),
    ...mapGetters( 'templateModule', ['template'] ),
    selectTemplate() {
      let self = this
      if ( !this.applicationID ) return false
      if ( !this.application ) return false
      if ( self.application.applicationpages.length > 0 ) return false
      return true;
    }
  },
  created() {
    this.getApplications()
    this.getTemplates()
  },
  methods: {
    ...mapActions( 'applicationModule', ['getApplications', 'getApp', 'getUnselectedForms', 'setSelectedApplicationID', 'saveApplication', 'saveSelectedTemplateID', 'removeApplication'] ),
    ...mapActions( 'templateModule', ['getTemplates', 'setSelectedTemplateID'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    addApplication() {
      this.editNewMode = true
      this.applicationname = ''
      this.setSelectedApplicationID( 0 )
    },
    setTheSelectedApplicationID() {
      this.setSelectedApplicationID( this.applicationID )
      this.setSelectedTemplateID( this.application.template_id )
      this.getUnselectedForms( this.applicationID )
      this.templateID = this.application.template_id
    },
    setTheSelectedTemplateID() {
      let self = this
      let payload = {applicationID: this.applicationID, templateID: this.templateID}
      self.saveSelectedTemplateID( payload )
          .then( () => {
            self.getApp( self.applicationID )
          } )
      self.setSelectedTemplateID( this.templateID )
    },
    showTranslator() {
      let self = this
      if ( !self.applicationID ) return

      if ( !this.application || !this.application.translation ) return;
      this.showHideTranslator( 'SHOW' )

      this.getTranslations( this.application.translation_id )
      this.setToTranslate( this.application.translation )
      this.setIdentifierID( this.application.translation_id )
    },
    setUpdateMode() {
      let self = this
      if ( !self.applicationID ) return

      this.applicationname = this.application.translation ? this.application.translation : ''
      this.editNewMode = true
    },
    saveTheApplication() {
      let self = this
      let payload = {}
      payload.applicationID = this.selectedApplicationID
      payload.applicationname = this.applicationname

      if ( this.applicationname.length == 0 ) return this.editNewMode = false;

      this.saveApplication( payload )
          .then( function ( result ) {
            self.getApplications()
                .then( function () {
                  self.editNewMode = false
                  if ( !result.data ) return

                  self.setSelectedApplicationID( result.data.id )
                  self.applicationID = self.selectedApplicationID
                  self.applicationname = ''
                } )
          } )
    },
    removeTheApplication() {
      if ( !this.applicationID ) return

      this.removeApplication( this.applicationID )

      this.setSelectedApplicationID( null )
      this.setSelectedTemplateID( null )
      this.applicationID = null
      this.templateID = null
    }
  }
}
</script>

<style scoped>

</style>

