/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setTranslations(state, payload){
        state.translations = payload
    },
    showHideTranslator(state, status){
        if( ! status )
            state.showTranslator = ! state.showTranslator

        if( status == 'HIDE')
            state.showTranslator = false

        if( status == 'SHOW')
            state.showTranslator = true
    },
    setToTranslate(state, payload){
        state.toTranslate = payload
    },
    setIdentifierID(state, payload){
        state.identifierID = payload
    }
}