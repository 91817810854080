<template>
  <div class="card text-white page-form"
       :class="{'brighten-background' :formpage.id == formpageID,
                'bg-turquoise' : formpage.formtype == 'DEFAULT',
                'bg-blueish' : formpage.formtype == 'GOAL',
                'bg-purpleish' : formpage.formtype == 'CLIENTFORM'}">
    <div class="card-body">
      <div style="margin-top:30px; width:100%;text-align: center;">
        <b>{{ formpage.formpagename }}</b>
        <br>
        <span class="far fa-flag parent-popover"
              style="margin-top:10px;"
              @click="showTranslator">
          <tooltip :tip="'SHOWTRANSLATION'"></tooltip>
        </span>
        <br/>
        <button class="btn btn-primary btn-small"
                @click.stop="addTheFormpageToLibrary"
                style="margin-top:20px;">
          <translation :t="'ADDTOFORMLIBRARY'"></translation>
        </button>
        <br/>
        <select class="form-control form-control-sm"
                style="margin-top:15px;background-color:#fff;"
                v-model="formpage.followup"
                @change="saveTheFolowup">
          <option :value="'null'">
            <translation :t="'SELECTFORMPAGE'"></translation>...
          </option>
          <option v-for="otherFormpage in otherFormpages"
                  :value="otherFormpage.id">
            {{ otherFormpage.formpagename }}
          </option>
        </select>
      </div>
    </div>
    <div class="card-footer">
      <div class="flex-container">
        <i class="fa fa-chevron-left parent-popover"
           @click="moveThePageform('LEFT')">
<!--          <tooltip :tip="'MOVELEFT'"></tooltip>-->
        </i>
        <i class="fa fa-edit parent-popover"
           @click="editFormpage">
<!--          <tooltip :tip="'EDITFORMPAGE'"></tooltip>-->
        </i>
        <i class="fa fa-copy parent-popover"
           @click="copyTheFormpage">
<!--          <tooltip :tip="'COPYFORMPAGE'"></tooltip>-->
        </i>
        <i class="fa fa-trash parent-popover"
           @click="removeTheFormpage"
           style="">
<!--          <tooltip :tip="'REMOVE'"></tooltip>-->
        </i>
        <i class="fa fa-chevron-right parent-popover"
           @click="moveThePageform('RIGHT')">
<!--          <tooltip :tip="'MOVERIGHT'"></tooltip>-->
        </i>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex"

import Swal from 'sweetalert2'
import Translation from "@/components/controls/Translation";

export default {
  name      : "Formpage",
  components: {Translation},
  props     : ['formpage'],
  data() {
    return {
      busy: false
    }
  },
  computed: {
    ...mapGetters( ['languages', 'languageCode', 'alertData'] ),
    ...mapGetters( 'formpageModule', ['formpageID', 'selectedFormpage', 'formpages'] ),
    ...mapGetters( 'translator', ['toTranslate'] ),
    otherFormpages(){
      let self = this
      let otherFormpages = []
      this.formpages.forEach( formpage =>{
        if( formpage.id != self.formpage.id) otherFormpages.push(formpage)
      })
      return otherFormpages
    }
  },
  methods : {
    ...mapActions( ['alertMe'] ),
    ...mapActions( 'formpageModule', ['getFormpages', 'setClientFormpage', 'selectFormpage', 'getFormelements', 'removeFormpage', 'moveFormpage', 'copyFormpage', 'saveFollowup'] ),
    ...mapActions( 'formModule', ['toggleShowEditFormpage', 'showEditFormpage'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'getTranslations', 'setIdentifierID'] ),
    showTranslator() {
      let self = this

      this.showHideTranslator( 'SHOW' )

      this.getTranslations( self.formpage.formpage_id )
      this.setToTranslate( self.formpage.formpagename )
      this.setIdentifierID( self.formpage.formpage_id )
    },
    editFormpage() {
      this.selectFormpage( this.formpage.id )
      this.toggleShowEditFormpage()
      let payload = {}
      payload.formpageID = this.selectedFormpage.id
      payload.languageCode = this.languageCode
      this.getFormelements( 0 );
    },
    moveThePageform( direction ) {
      let self = this
      let payload = {}
      payload.formpageID = this.formpage.id
      payload.direction = direction

      this.moveFormpage( payload )
          .then( () => {
            self.refreshFormpage()
          } )
    },
    copyTheFormpage() {
      let self = this
      Swal.fire( {
        title            : 'Do you want to copy the form page?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( result.isConfirmed ) {
          let payload = {}
          payload.formpageID = self.formpage.id
          payload.library = 0

          self.copyFormpage( payload )
              .then( () => {
                self.refreshFormpage()
                ra.toasterMessage( 'The element was successfully removed from the formpage', 'success' )
              } )
        }
      } )
    },
    addTheFormpageToLibrary() {
      let self = this

      if ( self.busy ) return
      self.busy = true

      Swal.fire( {
        title            : 'Do you want to add the formpage to the library?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( result.isConfirmed ) {
          let payload = {}
          payload.formpageID = self.formpage.id
          payload.library = 1
          self.copyFormpage( payload )
              .then( () => {
                self.busy = false
                ra.toasterMessage( 'the form page was successfully copied', 'success' )
              } )
        } else {
          self.busy = false
        }

      } )
    },
    saveTheFolowup(){
      let self = this
      console.log( 'inside Followup',self.formpage );
      if( ! self.formpage.followup) return

      let payload = {}
      payload.id = self.formpage.id
      payload.followup = self.formpage.followup

      self.saveFollowup(payload)
    },
    removeTheFormpage() {
      let self = this

      Swal.fire( {
        title            : 'The formpage will be deleted, this can not be undone, continue?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( result.isConfirmed ) {
          self.removeFormpage( self.formpage.id )
              .then( () => {
                self.refreshFormpage()
                ra.toasterMessage( 'the form page was successfully removed', 'success' )
              } )
        }
      } )
    },
    refreshFormpage() {
      this.getFormpages( 0 )
    }
  }
}
</script>

<style scoped>
.fa {
  cursor: pointer;
}

.flex-container {
  display: flex;
}

.flex-container > i {
  flex-basis: 100%;
  text-align: center;
  /*border: 1px solid black;*/
}

.page-form {
  height: 250px;
  border: 1px solid #0c5c69;
  cursor: move;
}

.page-form:hover {
  box-shadow: #9da3a4 5px 5px 5px;
}

</style>