<template>
  <span v-if="showButtonActionselector">
  <div class="my-backdrop"></div>
  <div class="my-popup my-popup-md">
    <div class="my-popup-header">
      <h3>
        <translation :t="'BUTTONACTIONSELECTOR'"></translation>
        <button class="close" @click="toggleButtonActionselector()">x</button>
      </h3>
    </div>
    <div class="my-popup-body">
      <div class="row">
        <div class="col-6">
          <label>
            <translation :t="'PAGES'"></translation>
          </label>
          <ul class="list-group">
            <li class="list-group-item">
              <translation :t="'HOME'"></translation>
            </li>
            <li class="list-group-item">
              <translation :t="'LOGIN'"></translation>
            </li>
            <li class="list-group-item">
              <translation :t="'ABOUT'"></translation>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <label>
            <translation :t="'ARTICLES'"></translation>
          </label>
        </div>
      </div>
    </div>
  </div>
  </span>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
  name: "ButtonActionSelector",
  data() {
    return {
    }
  },
  components: {},
  computed  : {
    ...mapState( 'modalModule',['showButtonActionselector'] )
  },
  methods   : {
    ...mapActions( 'modalModule', ['toggleButtonActionselector', 'setSelectedButtonAction'] ),
    setTheSelectedAction( action ) {
      let self = this
      self.setSelectedButtonAction( action )

      setTimeout(function(){
        self.toggleButtonActionselector()
      },100);
    }
  }
}
</script>

<style scoped>
.my-picture-list{
  padding: 0;
  margin: 0;
  list-style: none;
  height:500px;
  overflow:auto;
}
.my-picture-list-item{
  float:left;
  margin:5px;

}
.my-picture-list-item img{
  height:300px;
  width:195px;
  border-radius:5px;
  box-shadow: #aaa 5px 10px 5px;
}
</style>