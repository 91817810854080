import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Nopage from '../views/Nopage.vue'
import Login from '../views/Login.vue'
import Translations from '../views/Translations.vue'
import Organizations from '../views/Organizations.vue'
import RequestForPassword from '../views/RequestForPassword.vue'
import Organizationusers from '../views/Organizationusers.vue'
import Userrights from '../views/Userrights.vue'
import Forms from '../views/forms/Forms.vue'
import Formcategories from '../views/forms/Categories.vue'
import Articles from '../views/cms/Articles.vue'
import Images from '../views/cms/Images.vue'
import Applications from '../views/phoneapp/Applications.vue'
import Milestones from '../views/forms/Milestones.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/nopage',
                name: 'nopage',
                component: Nopage
            },
            {
                path: '/uitranslations',
                name: 'uitranslations',
                component: Translations
            },
            {
                path: '/organizations',
                name: 'organizations',
                component: Organizations
            },
            {
                path: '/organizationusers',
                name: 'organizationusers',
                component: Organizationusers
            },
            {
                path: '/userrights',
                name: 'userrights',
                component: Userrights
            },
            {
                path: '/editforms',
                name: 'editforms',
                component: Forms
            },
            {
                path: '/articles',
                name: 'articles',
                component: Articles
            },
            {
                path: '/images',
                name: 'images',
                component: Images
            },
            {
                path: '/formcategories',
                name: 'formcategories',
                component: Formcategories
            },
            {
                path: '/applications',
                name: 'applications',
                component: Applications
            },
            {
                path: '/milestones',
                name: 'milestones',
                component: Milestones
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/requestforpassword',
        name: 'requestforpassword',
        component: RequestForPassword
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
