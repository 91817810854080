<template>
  <label :class="{'invalid' : invalid }">
    <translation :t="labelCaption"></translation>
  </label>
  <div class="input-group">
    <input
        :type="type"
        :class="{'invalid' : invalid }"
        v-model.trim="modelValue"
        @blur="validateInput"
        @keyup="updateTyping"
        class="form-control"
        :placeholder="pc">
    <div class="input-group-append"
         style="position:relative"
         @click="toggleShowTranslations"
         v-if="showTranslate">
      <span
          class="input-group-text
                    bg-transparent border-left-0">
        <i class="far fa-flag"></i>
      </span>
    </div>
  </div>
  <div
      class="invalid-feedback"
      v-if="invalid">
    <translation :t="errorMessage"></translation>
  </div>
</template>

<input type="text" class="form-control" id="username" value="">
<label class="form-control-placeholder" for="username">Name</label>
<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"

export default {
  name      : "Inputfield",
  components: {Translation},
  props     : [
    'modelValue',
    'validations',
    'labelCaption',
    'placeholder',
    'lengte',
    'type',
    'errorMessage',
    'showTranslate'],
  emits     : ['update:modelValue', 'updateTyping'],
  data() {
    return {
      invalid         : false,
      inputValue      : '',
      pc              : '',
      translatorsPopup: false,
      timeout         : null
    }
  },
  computed: {
    ...mapGetters( 'translate', ['translations'] )
  },
  created() {
    if ( !this.type ) this.type = 'text'
    if ( !this.lengte ) this.lengte = 1
    this.initialize()
  },
  methods: {
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID'] ),
    initialize() {
      if ( !this.placeholder ) return
      setTimeout( function () {
        self.pc = ra.findTranslation( self.translations, self.placeholder )
      }, 1000 );

    },
    updateTyping() {
      var self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout )
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.$emit( 'updateTyping', self.modelValue )
      }, 700 );

    },
    validateInput() {
      const self = this

      this.$emit( 'update:modelValue', this.modelValue )

      if ( !self.validations ) return self.invalid = false
      let valids = this.validations.split( '|' )
      this.invalid = false
      valids.forEach( function ( valid ) {
        if ( self[valid]() == true ) {
          self.invalid = true
        }
      } )
    },
    required() {
      if ( this.modelValue.length < this.lengte ) {
        this.invalid = true
      } else {
        this.invalid = false
      }
    },
    email() {
      return !ra.validate.email( this.modelValue )
    },
    toggleShowTranslations() {
      this.showHideTranslator( 'SHOW' )
      this.setToTranslate( this.modelValue )
      this.setIdentifierID( this.showTranslate )
    }
  }
}
</script>

<style scoped>

</style>