/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router";

export default {

    getTemplates(context){
        let languageCode = context.rootState.languageCode
        return api.get('/api/template/getTemplates/' + languageCode)
            .then((result)=>{
                context.commit('setTemplates', result.data)
            })
    },
    setSelectedTemplateID(context, payload){
        return context.commit('setSelectedTemplateID', payload)
    }
}