<template>
  <div v-if="infoText" class="info-text-holder">
  <infopopup :elementID="elementID"
             :showInfo="showInfo"
             :infoText="infoText.translation"
             @hideInfo="showInfo = false"></infopopup>
  <span class="info-text fa fa-info-circle info-color"
        @click="showInfotext"></span>
  </div>
  <label>{{ metaData.label.value }}</label>
  <input :type="metaData.inputType.value" class="form-control form-control-sm bg-white border-grey">
  <!--    <info-text-popup></info-text-popup>-->
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Infopopup from '@/components/forms/formelements/phone/infopopup.vue'

export default {
  name : "InputfieldPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {
      showInfo : false
    }
  },
  components: {Infopopup},
  computed  : {},
  methods   : {
    showInfotext() {
      this.showInfo = true
    }
  }
}
</script>

<style scoped>

.input-field {
  border: 1px solid #999;
  border-radius: 10px;
  height: 40px;
  background-color: #fff;
}
</style>