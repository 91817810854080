/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setTemplates( state, payload ) {
        state.templates = payload
    },
    setSelectedTemplateID( state, payload ) {
        state.selectedTemplateID = payload
    }
}