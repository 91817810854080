/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router";

export default {

    getApplications( context ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/applications/getApplications/' + languageCode )
            .then( result => {
                context.commit( 'setApplications', result.data )
            } )
    },
    getApp( context, applicationID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/applications/getApplication/' + applicationID + '/' + languageCode )
            .then( result => {
                context.commit( 'setApp', result.data )
            } )
    },
    getUnselectedForms( context, applicationID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/applications/getUnselectedForms/' + applicationID + '/' + languageCode )
            .then( result => {
                context.commit( 'setSelectedForms', result.data.selectedForms )
                context.commit( 'setUnselectedForms', result.data.unselectedForms )
            } )
    },
    getApplicationpageMetadata( context, applicationpageID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/applications/getApplicationpageMetadata/' + applicationpageID + '/' + languageCode )
    },
    getArticleMetadata( context, applicationpageID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/applications/getArticleMetadata/' + articleID + '/' + languageCode )
    },
    setSelectedApplicationID( context, payload ) {
        context.commit( 'setSelectedApplicationID', payload )
    },
    setNewVersion( context, applicationID ) {
        return api.get( '/api/applications/newVersion/' + applicationID )
    },
    reorderArticleNavigation( context, payload ) {
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload.reorder ) )
        return api.post(
            '/api/applications/reorderArticleNavigation/' +  payload.applicationpageID, formData )
    },
    addButton( context, payload ) {
        payload.languageCode = context.rootState.languageCode
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/applications/addButton', formData )
    },
    saveApplicationpageMetadata( context, payload ) {
        payload.languageCode = context.rootState.languageCode
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/template/saveApplicationpageMetadata/', payload )
    },
    saveApplicationpageButton( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )

        return api.post( '/api/application/saveApplicationpageButton', formData )
    },
    saveApplicationpageRegionpicture( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )

        return api.post( '/api/application/saveApplicationpageRegionpicture', formData )
    },
    createApplicationpage( context, payload ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/template/createApplicationpage/' + payload.applicationID + '/' + payload.templatepageID + '/' + languageCode )
    },
    addFormToApp( context, payload ) {
        return api.get( '/api/application/addFormToApp/' + payload.applicationID + '/' + payload.formID )
    },
    removeFormFromApp( context, payload ) {
        return api.get( '/api/application/removeFormFromApp/' + payload.applicationID + '/' + payload.formID )
    },
    saveApplication( context, payload ) {
        payload.languageCode = context.rootState.languageCode
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/application/saveApplication', formData )
    },
    saveMetadata( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        api.post( '/api/application/saveMetadata', formData )
    },
    saveSelectedTemplateID( context, payload ) {
        return api.get( '/api/application/saveTemplate/' + payload.applicationID + '/' + payload.templateID )
    },
    saveMetadataPropertyValue( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/application/saveMetadataPropertyValue', formData )
    },
    removeButton( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/application/removeButton', formData )
    },
    removeApplicationPage( context, applicationpageID ) {
        return api.get( '/api/application/removeApplicationPage/' + applicationpageID )
    },
    removeApplication( context, applicationID ) {
        return api.get( '/api/application/removeApplication/' + applicationID )
            .then( () => {
                context.dispatch( 'getApplications' )
            } )
    }
}