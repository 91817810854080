<template>
  <div v-if="infoText" class="info-text-holder">
      <span class="info-text fa fa-info-circle info-color"
            @click="showInfotext"></span>
  </div>
  <div>
    <label>{{ metaData.label.value }}</label>
  </div>

  <select class="form-control form-control-sm bg-white border-grey"
          v-if="metaData.itemtype.value=='CATEGORY' && category">
    <option value="null">
      <translation :t="'SELECTITEM'"></translation>
    </option>
    <option v-for="item in category.categoryitems">
      {{ item.translation ? item.translation.translation : 'NB' }}
      <img  :src="item.imagepath" />
    </option>
  </select>


  <select class="form-control form-control-sm bg-white border-grey"
          v-if="metaData.itemtype.value=='DATAFIELD'">
    <option value="null">
      Edvar Grieg
    </option>
    <option>
      David Bree
    </option>
    <option>
      Clara Schumann
    </option>
    <option>
      Fanny Hensel
    </option>
    <option>
      Clara Schumann
    </option>
  </select>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "CheckboxPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] ),
    category() {
      return this.categories.find( category => {
        return category.id == this.metaData.categoryID.value
      } )
    }
  },
  methods   : {
    showInfotext(){}
  }
}
</script>

<style scoped>
.range-holder {
  float: left;
  padding: 0 8px;
  text-align: center;
}

.category-holder {
  float: left;
  padding: 0 10px;
}

.category-holder img {
  width: 40px;
}

.stair {
  position: relative;
  height: 50px;
  border-left: 4px solid #bf0710;
  border-right: 4px solid #bf0710;
  width: 100px;
  cursor: pointer;
  margin-left: 15px;
}

.stair hr {
  float: left;
  margin-top: 10px;
  width: 100%;
  height: 6px;
  background-color: #bf0710;
}

.stair.cleared {
  border-color: #01800d;
}

.stair.cleared > hr, .stair-level.cleared {
  background-color: #01800d;
}

.stair:hover {
  border-color: #024959;
}

.stair:hover hr, .stair-level:hover {
  background-color: #024959;
}

.down-the-stairs {
  background-color: #024959;
  width: 130px;
  margin-top: 0px;
  cursor: pointer;
  height: 5px;
}

.stair-level {
  position: absolute;
  right: -35px;
  top: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: #f03d1b;
  cursor: pointer;
}

.stair-level > span {
  position: relative;
  top: 3px;
  color: #fff;
  font-weight: bold;
}

.stair-indicator {
  width: 100%;
  text-align: center;
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  color: #666;
  position: relative;
  top: -10px;
}
</style>