<template>
  <div class="info-popup" @click="hideInfo" v-show="showInfo">
    <span v-html="infoText"></span>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "infopopup",
  props:['elementID', 'showInfo', 'infoText'],
  emits     : ['hideInfo'],
  data() {
    return {

    }
  },
  components: {},
  computed  : {},
  created(){
    console.log( this.elementID );
  },
  methods   : {
    hideInfo(){
      this.$emit('hideInfo')
    },
    getInfotext(){

    }
  }
}
</script>

<style scoped>
.info-popup {
  position: absolute;
  top: 30px;
  right:20px;
  background-color: #fff;
  width: 280px;
  max-height: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: #999 5px 5px 5px;
  padding: 10px;
  overflow: auto;
}
</style>