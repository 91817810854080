/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import formpageMutations from './formpageMutations.js';
import formpageActions from './formpageActions.js';
import formpageGetters from './formpageGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            formpages        : [],
            formpageID       : null,
            selectedFormpage : {
                id                    : null,
                form_id               : null,
                formpage_id           : null,
                formpagedescription_id: null,
                formpagename          : '',
                formpagedescription   : ''
            },
            elementtypes     : [],
            formelements     : [],
            showInfoTextPanel: false,
            selectedElement  : {},
            selectedElementID: null,
            library          : []
        }
    },
    mutations: formpageMutations,
    actions  : formpageActions,
    getters  : formpageGetters,

};


