<template>
  <div v-if="infoText" class="info-text-holder">
      <span class="info-text fa fa-info-circle info-color"
            @click="showInfotext"></span>
  </div>
  <label>{{ metaData.label.value }}</label>
  <textarea class="form-control form-control-sm bg-white border-grey"
            :rows="metaData.rows.value"></textarea>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Textarea from "@/components/forms/formelements/Textarea";

export default {
  name : "TextareaPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {}
  },
  components: {Textarea},
  computed  : {
    height() {
      return 25 * Number( this.metaData.rows.value )
    }
  },
  methods   : {
    showInfotext(){}
  }
}
</script>

<style scoped>
.textarea-field {
  border: 1px solid #999;
  border-radius: 10px;
  background-color: #fff;
}
</style>