<template>
  <div class="card">
    <div class="card-header text-white"
         :class="selected ? 'bg-success' :'bg-primary'"
         @click="setActiveIndicator(milestoneIndicator.id)">
      <label>
        {{ indicatorName }}
      </label>
      <i class="fa fa-trash right-icon mr-3"
         @click.stop="removeMilestoneindicator"></i>
    </div>
    <div class="card-body"
         v-if="selected">
      <div class="row">
        <div class="col-6">
          <label>
            <translation :t="'DESCRIPTION'"></translation>
          </label>
          <input-translation
              class="mt-2"
              :identifierID="milestoneIndicator.translation_id"
              :toTranslate="indicatorName"
              :textarea="true"
              :rows="2"
              @saveTranslation="saveIndicatorName"></input-translation>
        </div>
        <div class="col-6">
          <label>
            <translation :t="'ADVICE'"></translation>
          </label>
          <input-translation
              class="mt-2"
              :identifierID="milestoneIndicator.advice_id"
              :toTranslate="adviceName"
              :textarea="true"
              :rows="2"
              @saveTranslation="saveAdviceName"></input-translation>
        </div>
      </div>
      <div class="row mt-2 mb-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <translation :t="'STARTAT'"></translation>
            </span>
          </div>
          <input type="number"
                 style="text-align: right"
                 class="form-control"
                 v-model="milestoneIndicator.limit_start"
                 @change="checkLimits"
                 @keyup="checkLimits"/>
          <div class="input-group-append">
            <span class="input-group-text">
              <translation :t="'MONTH'"></translation>
            </span>
          </div>

          <div class="input-group-prepend">
            <span class="input-group-text">
              <translation :t="'WARNING'"></translation>
            </span>
          </div>
          <input type="number"
                 style="text-align: right"
                 class="form-control"
                 v-model="milestoneIndicator.limit_warning"
                 @change="checkLimits"
                 @keyup="checkLimits"/>
          <div class="input-group-append">
            <span class="input-group-text">
              <translation :t="'MONTH'"></translation>
            </span>
          </div>
          <div class="input-group-prepend">
            <span class="input-group-text">
              <translation :t="'CRITICAL'"></translation>
            </span>
          </div>
          <input type="number"
                 style="text-align: right"
                 class="form-control"
                 v-model="milestoneIndicator.limit_critical"
                 @change="checkLimits"
                 @keyup="checkLimits"/>
          <div class="input-group-append">
            <span class="input-group-text">
              <translation :t="'MONTH'"></translation>
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <upload
              @uploadFinished="uploadFinished"
              :simple="true"
              :simpleText="'DRAGDROPIMAGE'"
              :uploadData="uploadData"></upload>
        </div>

        <div class="col-2">
          <button class="btn btn-full btn-small btn-primary mt-4"
                  @click="pictureSelector">
            picture browser
          </button>
        </div>
        <div class="row-6">
          <img :src="milestoneIndicator.imageurl"
               style="max-width:90%;max-height:80px;margin-left:30px;"/>
        </div>
      </div>
    </div>
  </div>
  <picture-selector
      v-if="showPictureSelector"
      @selectPicture="selectPicture">
  </picture-selector>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Upload from '@/components/widgets/Upload'
import InputTranslation from "../../controls/InputTranslation"
import PictureSelector from "../../widgets/pictureSelector";

export default {
  name : "MilestoneIndicator",
  props: ['milestoneIndicator', 'activeIndicators'],
  emits: ['setActiveIndicators', 'removeIndicator'],
  data() {
    return {
      timeout            : null,
      indicatorName      : '',
      showPictureSelector: false,
      uploadData         : {
        milestoneID: this.milestoneIndicator.id,
        treeEntity : 'MILESTONEINDICATOR'
      }
    }
  },
  components: {InputTranslation, Upload, PictureSelector},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] ),
    selected() {
      return this.activeIndicators.find( ai => ai == this.milestoneIndicator.id )
    }
  },
  created() {
    this.indicatorName =
        this.milestoneIndicator.translation ?
            this.milestoneIndicator.translation.translation : ''
    this.adviceName =
        this.milestoneIndicator.advice ?
            this.milestoneIndicator.advice.translation : ''
    this.setUploadID( 0 )
    this.setUploadType( 'MILESTONE' )
  },
  methods: {
    ...mapActions( 'uploadModule', ['setUploadID', 'setUploadType', 'setMultiUpload', 'showUploader', 'doUpload'] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'modalModule', ['getImagesByChapter'] ),
    ...mapActions( 'milestoneModule', ['saveMilestoneindicator', 'removeMilestone', 'saveIndicatorPicture'] ),
    saveIndicatorName( indicatorName ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = this.milestoneIndicator.translation_id
      payload.translation = this.indicatorName = indicatorName
      this.saveTranslationByIdentifier( payload )
    },
    saveAdviceName( adviceName ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = this.milestoneIndicator.advice_id
      payload.translation = this.adviceName = adviceName
      this.saveTranslationByIdentifier( payload )
    },
    setActiveIndicator( milestoneIndicatorID ) {
      this.$emit( 'setActiveIndicators', milestoneIndicatorID )
    },
    checkLimits() {
      if( this.milestoneIndicator.limit_start > this.milestoneIndicator.limit_warning )
        this.milestoneIndicator.limit_warning = this.milestoneIndicator.limit_start

      if ( this.milestoneIndicator.limit_warning > this.milestoneIndicator.limit_critical )
        this.milestoneIndicator.limit_critical = this.milestoneIndicator.limit_warning

      this.saveIndicator()
    },
    saveIndicator() {
      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
      this.timeout = setTimeout( _ => {
        let payload = {}
        payload.id = this.milestoneIndicator.id
        //payload.weight = this.milestoneIndicator.weight
        payload.limit_start = this.milestoneIndicator.limit_start
        payload.limit_warning = this.milestoneIndicator.limit_warning
        payload.limit_critical = this.milestoneIndicator.limit_critical
        this.saveMilestoneindicator( payload )
      }, 400 );
    },
    uploadFinished( link ) {
      this.milestoneIndicator.imageurl = link.link
    },
    pictureSelector() {
      this.getImagesByChapter( 'MILESTONES' )
      this.showPictureSelector = true
    },
    selectPicture( selectedPicture ) {
      this.showPictureSelector = false
      if ( !selectedPicture ) return
      this.milestoneIndicator.imageurl = selectedPicture
      let payload = {}
      payload.milestonIndicatorID = this.milestoneIndicator.id
      payload.imageurl = selectedPicture
      this.saveIndicatorPicture( payload )
    },
    removeMilestoneindicator() {
      let payload = {}
      payload.milestoneID = this.milestoneIndicator.id
      payload.type = 'MILESTONEINDICATOR'

      this.removeMilestone( payload )
          .then( _ => {
            // this.$el.parentNode.removeChild( this.$el );
            this.$emit( 'removeIndicator', payload.milestoneID )
          } )
    }
  }
}
</script>

<style scoped>


.right-icon {
  position: absolute;
  font-weight: 700;
  line-height: 1;
  opacity: .8;
  cursor: pointer;
  right: -10px;
  top: 10px;
}

.right-icon:hover {
  opacity: 1;
}
</style>