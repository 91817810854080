/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setTranslations (state, payload){
        state.translations = payload
    },
    setCountries(state, payload){
        state.countries = payload
    },
    setAllTranslations(state, payload){
        state.allTranslations = payload
    }
}