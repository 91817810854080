<template>
  <div class="row">
    <label class="col-3 control-label">
      <translation :t="'ORGANIZATION'"></translation>
    </label>
    <div class="col-4">
      <select class="form-control form-control-sm"
              @change="getTheUserrights"
              v-model="selectedOrganizationID">
        <option v-for="organization in organizations" :value="organization.id">
          {{ organization.name }}
        </option>
      </select>
    </div>
  </div>
  <table class="table table-condensed table-condensed">
    <thead>
    <tr>
      <th>&nbsp;</th>
      <th v-for="user in userrights">
        {{ user.fullname }}
      </th>
    </tr>
    <tr>
      <th>&nbsp;</th>
      <th v-for="user in userrights">
        <select class="form-control form-control-sm"
                @change="setTheUserrole(user)"
                v-model="user.role_id">
          <option v-for="role in roles"
                  :value="role.id">
            {{ role.translation }}
          </option>
        </select>
      </th>
    </tr>
    <tr style="border-top:5px solid #000; border-bottom:2px solid #000">
      <th>
        Languages
      </th>
      <th v-for="user in userrights">
        <i class="fa fa-square redish"
           style="position:relative;left:-10px;"
           @click="setUseright('DESELECTALL', user.id, 'PAGE')"></i>
        <i class="fas fa-check-square greenish"
           style="position:relative;left:10px;"
           @click="setUseright('SELECTALL', user.id, 'PAGE')"></i>
      </th>
    </tr>
    </thead>
    <tbody v-for="page in pages">
    <tr style="border-top:2px solid #000;">
      <td>
        <h6>
          <translation :t="page.entity"></translation>
        </h6>
      </td>
      <td v-for="user in userrights">
        <i class="fa fa-check greyish"
           v-if="user.role_id==1"></i>
        <i class="fa fa-check pointer greenish"
           @click="setUseright(page.id, user.id, 'PAGE')"
           v-else-if="user.userpages.find(p=>{
              return p.rightable_id == page.id && p.status == 1
            })"></i>
        <i v-else class="far fa-square pointer redish"
           @click="setUseright(page.id, user.id, 'PAGE')"></i>
      </td>
    </tr>
    <tr v-for="subpage in page.subpages">
      <td>&nbsp;&nbsp;<translation :t="subpage.entity"></translation>
      </td>
      <td v-for="user in userrights">
        <i class="fa fa-check greyish"
           v-if="user.role_id==1"></i>
        <i class="fa fa-check pointer greenish"
           @click="setUseright(subpage.id, user.id, 'PAGE')"
           v-else-if="user.userpages.find(p=>{
              return p.rightable_id == subpage.id && p.status == 1
            })"></i>
        <i v-else class="far fa-square pointer redish"
           @click="setUseright(subpage.id, user.id, 'PAGE')"></i>
      </td>
    </tr>
    </tbody>
    <tbody>
    <tr style="border-top:5px solid #000; border-bottom:2px solid #000">
      <th>
        Languages
      </th>
      <th v-for="user in userrights">
        <h5 class="redish pointer"
            style="margin:0 5px 0 0"
           @click="setUseright('DESELECTALL', user.id, 'LANGUAGE', 0)">
          <strong>O</strong>
        </h5>
        <h5 class="blueish pointer"
            style="margin:0 5px"
            @click="setUseright('SELECTALL', user.id, 'LANGUAGE', 1)">
          <strong>R</strong>
        </h5>
        <h5 class="greenish pointer"
            style="margin:0 0 0 5px"
            @click="setUseright('SELECTALL', user.id, 'LANGUAGE', 2)">
          <strong>W</strong>
        </h5>
      </th>
    </tr>
    <tr v-for="language in translatedLanguages">
      <td>{{ language.translation }}</td>
      <td v-for="user in userrights">
        <i class="fa fa-check greyish"
           v-if="user.role_id==1"></i>
        <h5 class="pointer blueish"
              @click="setUseright(language.id, user.id, 'LANGUAGE')"
              v-else-if="user.userlanguages.find(p=>{
              return p.rightable_id == language.id && p.status == 1
            })"><strong>R</strong></h5>
        <h5 class="pointer greenish"
              @click="setUseright(language.id, user.id, 'LANGUAGE')"
              v-else-if="user.userlanguages.find(p=>{
              return p.rightable_id == language.id && p.status == 2
            })"><strong>W</strong></h5>
        <h5 v-else
           @click="setUseright(language.id, user.id, 'LANGUAGE')"
           class="pointer redish">
          <strong>O</strong>
        </h5>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "Userrights",
  props: [],
  emits: [],
  data() {
    return {
      selectedOrganizationID: null,
      oldUserrights         : []
    }
  },
  components: {},
  computed  : {
    ...mapState( ['languages', 'pages', 'translatedLanguages'] ),
    ...mapState( 'organization', ['organizations', 'userrights', 'roles'] ),
    filteredRoles(){
      let userHierarchy = this.roles.find(role=>{
        return role.id == this.user.role_id
      })
      return this.roles.filter(role=>{
        return role.hierarchy >= userHierarchy
      })
    }
  },
  watch     : {
    userrights() {
      this.oldUserrights = this.userrights
    }
  },
  created() {
    this.getAuthorisedRoles()
  },
  methods: {
    ...mapActions( 'organization', ['getUserrights', 'getAuthorisedRoles', 'saveUserrole', 'saveUserright'] ),
    getTheUserrights() {
      this.getUserrights( this.selectedOrganizationID )
    },
    setUseright( rightableID, userID, rightableType, right ) {
      let payload = {}
      payload.userID = userID
      payload.rightableID = rightableID
      payload.rightableType = rightableType
      payload.right = right
      this.saveUserright( payload )
          .then( () => {
            this.getUserrights( this.selectedOrganizationID )
          } )
    },
    setTheUserrole( user ) {
      let payload = {}
      payload.userID = user.id
      payload.roleID = user.role_id
      this.saveUserrole( payload )
          .then( result => {
            let old = this.oldUserrights.find( our => {
              return our.id == user.id
            } )
            if ( !result.data || !result.data.result ) {
              user.role_id = old.role_id
            } else {
              old.role_id = user.role_id
            }
          } )
    }
  }
}
</script>

<style scoped>
h5{
  font-size:15px;
  display:inline-block;
}
</style>