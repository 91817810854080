/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    translations(state){
        return state.translations
    },
    showTranslator(state){
        return state.showTranslator
    },
    toTranslate(state){
        return state.toTranslate
    },
    identifierID(state){
        return state.identifierID
    }
}
