<template>
  <div class="card-header d-flex justify-content-between align-items-center">
    <h6 class="card-title">formelements</h6>
  </div>
  <div class="card-content">
    <div class="card-body p-0">
      <ul class="list-group">
        <li v-for="elementtype in elementtypes"
            @click="addFormelement(elementtype)"
            class="p-2 border-bottom zoom list-group-item pointer">
          {{ elementtype.translation ? elementtype.translation.translation : 'NB' }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "FormelementList",
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'formpageModule', ['formelements', 'selectedFormpage', 'elementtypes'] )
  },
  methods   : {
    ...mapActions( 'formpageModule', ['createFormelement', 'getFormelements'] ),
    addFormelement( elementtype ) {
      let self = this

      let selectedItem = 0
      this.formelements.forEach( formelement => {
        if ( formelement.selected ) selectedItem = formelement.id
      } )

      let formData = {}
      formData.elementtypeID = elementtype.id
      formData.formpageID = self.selectedFormpage.id
      formData.languageCode = self.languageCode
      formData.selectedItem = selectedItem

      self.createFormelement( formData )
          .then( () => {
            self.getFormelements( 0 )
            ra.toasterMessage( 'element was succesfully added', 'success' )
          } )
    },
  }
}
</script>

<style scoped>

</style>