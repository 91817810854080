/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setUploadType(state, payload){
        state.uploadType = payload
    },
    setUploadID(state, payload){
        state.uploadID = payload
    },
    setSelectedFile(state, payload){
        state.selectedFile = payload
    },
    setMultiUpload(state, payload){
        state.multiUpload = payload
    },
    setShowUpload(state, payload){
        state.showUpload = payload
    }
}