/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import formMutations from './formMutations.js';
import formActions from './formActions.js';
import formGetters from './formGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            forms       : [],
            formID      : null,
            selectedForm: {
                id                : null,
                form_id           : null,
                formdescription_id: null,
                formname          : '',
                description       : '',
                cycle             : 1
            },
            showEditFormpage : false
        }
    },
    mutations : formMutations,
    actions   : formActions,
    getters   : formGetters,

};


