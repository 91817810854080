<template>
  <formflow v-if="showFormflow"
            @toggleShowFormflow="toggleShowFormflow"
            :formelement="formelement"></formflow>
  <div class="formtype-holder">
    <div class="formpage-element-widget"
         :class="{ 'half-widget': formelement.metadata.columns.value == 1,
                  'whole-widget': formelement.metadata.columns.value != 1,
                'selected-element-border': formelement.selected}">
      <div class="formpage-element-widget-header"
           @click="selectElement"
           :class="{'selected-element': formelement.selected}">
        <div class="close"
             @click="removeTheFormelement(formelement)">x
        </div>
        <h4>
          <i class="fa fa-angle-down pointer"
             style="padding: 0 3px;"
             @click="moveTheElement(formelement, 'DOWN')"></i>
          <i class="fa fa-angle-up pointer"
             style="padding: 0 3px;"
             @click="moveTheElement(formelement, 'UP')"></i>
          {{ formelement.elementtype.translation ? formelement.elementtype.translation.translation : 'NB' }}
          <div class="fas fa-grip-lines pointer"
               @click.stop="formelement.showBody = !formelement.showBody"></div>
        </h4>
      </div>
      <div class="formpage-element-widget-body"
           v-if=" ! formelement.showBody">
        <component :is="formelement.elementtype.component"
                   @saveMetaData="saveTheMetaData"
                   @showTranslator="showTranslator"
                   :formelementID="formelement.id"
                   :metaData="formelement.metadata"/>
      </div>
      <div class="formpage-element-widget-footer"
           v-if=" ! formelement.showBody">
        <div style="float:left">
          <i :class="formelement.metadata.columns.value == 1 ? 'fa fa-check' : 'far fa-square'"
             @click="toggleColumns(formelement, 1)"></i>
          <label class="fa-label">
            <translation :t="'ONECOLUMN'"></translation>
          </label>
        </div>
        <div style="float:left">
          <i :class=" formelement.metadata.columns.value == 2 ? 'fa fa-check' : 'far fa-square'"
             @click="toggleColumns(formelement, 2)"></i>
          <label class="fa-label">
            <translation :t="'TWOCOLUMNS'"></translation>
          </label>
        </div>
        <div style="float:left" v-if="formelement.metadata.mandatory">
          <i class="far fa-square"
             v-if="formelement.metadata.mandatory.value == 0"
             @click="toggleMandatory(formelement)"></i>
          <i class="fa fa-check"
             v-else
             @click="toggleMandatory(formelement)"></i>
          <translation style="margin-left:5px; margin-right:10px;" :t="'MANDATORY'"></translation>
        </div>
        <div style="float:left">
          <i class="mdi mdi-library dark-blueish parent-popover pointer"
             style="position: relative;top:-2px;font-size:1.3em;"
             @click="addTheElementToLibrary">
            <tooltip :tip="'ADDELEMENTTOLIBRARY'"></tooltip>
          </i>

          <i class="fa fa-copy fa-2x dark-blueish parent-popover pointer"
             style="position: relative;top:-2px;font-size:1.3em;margin-left:15px;"
             @click="copyTheFormpageElement">
            <tooltip :tip="'COPYELEMENT'"></tooltip>
          </i>
        </div>
        <div style="float:right">
          <i class="fa fa-angle-double-right fa-2x parent-popover pointer flow-button"
             v-if="showFormflowButton"
             @click="toggleShowFormflow(formelement)">
            <tooltip :tip="'FORMFLOW'" :placement="'LEFT'"></tooltip>
          </i>
          <i class="fa fa-info-circle info-color parent-popover fa-2x pointer"
             @click="initFroala(formelement)">
            <tooltip :tip="'ADDINFOFORELEMENT'" :placement="'RIGHT'"></tooltip>
          </i>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Inputfield from '@/components/forms/formelements/Inputfield.vue'
import Textareainput from '@/components/forms/formelements/Textarea.vue'
import Radiogroup from '@/components/forms/formelements/Radiogroup.vue'
import Checkbox from '@/components/forms/formelements/Checkbox.vue'
import Selectbox from '@/components/forms/formelements/Selectbox.vue'
import Paragraph from '@/components/forms/formelements/Paragraph.vue'
import Goalgenerator from '@/components/forms/formelements/GoalGenerator.vue'
import Swal from "sweetalert2"
import Tooltip from "@/components/widgets/Tooltip"
import Formflow from "@/components/forms/formpages/builder/flows/Formflow"

export default {
  name : "Formelement",
  props: ['formelement'],
  data() {
    return {
      metaData    : {},
      inputValue  : '',
      showFormflow: false
    }
  },
  components: {
    Formflow,
    Tooltip,
    Inputfield,
    Radiogroup,
    Textareainput,
    Checkbox,
    Selectbox,
    Paragraph,
    Goalgenerator,
  },
  emits     : [],
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'froalaModule', [] ),
    ...mapGetters( 'formpageModule', ['formelements', 'selectedFormpage', 'selectedElementID'] ),
    showFormflowButton(){
      if(this.formelement.elementtype.component == 'Inputfield'
          && this.formelement.metadata.inputType.value == 'DATE' ) return true

      if( this.formelement.elementtype.component == 'Checkbox'  ) return true
      if( this.formelement.elementtype.component == 'Selectbox'  ) return true

      return false
    }
  },
  methods   : {
    ...mapActions( 'formpageModule', [
      'removeFormelement',
      'createMandatory',
      'saveMetaData',
      'toggleShowInfoTextPanel',
      'setSelectedElement',
      'getFormelements',
      'copyFormpageElement',
      'addFormpageElementToLibrary',
      'moveElement'] ),
    ...mapActions( 'editorModule', ['setTextTypeID', 'setTextType', 'showEditor', 'setShowEditor'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    showTranslator( translator ) {
      this.showHideTranslator( 'SHOW' )
      this.getTranslations( translator.translated )
      this.setToTranslate( translator.value )
      this.setIdentifierID( translator.translated )
    },
    moveTheElement( formelement, direction ) {
      let self = this
      let payload = {}
      payload.formelementID = formelement.id
      payload.direction = direction

      this.moveElement( payload )
          .then( function () {
            let payload = {};
            payload.formpageID = formelement.formpage_id;
            payload.languageCode = self.languageCode
            self.getFormelements( payload )
          } )
    },
    toggleColumns( formelement, value ) {
      formelement.metadata.columns.value = value

      this.saveTheMetaData( formelement.metadata )
    },
    toggleShowFormflow() {
      this.showFormflow = ! this.showFormflow
    },
    toggleMandatory(formelement){
        formelement.metadata.mandatory.value =
            formelement.metadata.mandatory.value == 1 ? 0 : 1;
        this.saveTheMetaData( formelement.metadata )
    },
    copyTheFormpageElement() {
      let self = this
      Swal.fire( {
        title            : 'Do you want to copy the element?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return;

        self.setSelectedElement( self.formelement )

        let payload = {};
        payload.languageCode = self.languageCode
        payload.formpageID = self.selectedFormpage.id
        payload.selectedElementID = self.formelement.id
        self.copyFormpageElement( payload )
            .then( function () {
              self.getFormelements( 0 )
              ra.toasterMessage( 'element was succesfully copied', 'success' )
            } )
      } )
    },
    addTheElementToLibrary() {
      let self = this
      Swal.fire( {
        title           : 'Enter a description for the element',
        input           : 'text',
        inputLabel      : 'element description',
        inputValue      : self.inputValue,
        showCancelButton: true,
        inputValidator  : ( value ) => {
          self.setSelectedElement( self.formelement )

          if ( !value ) return 'You need to enter a description!'

          self.addFormpageElementToLibrary( value )
              .then( function () {
                ra.toasterMessage( 'element added to library', 'success' )
              } )
        }
      } )
    },
    selectElement() {
      this.formelements.forEach( formelement => {
        if ( formelement.id == this.formelement.id ) {
          formelement.selected = !formelement.selected
        } else {
          formelement.selected = false
        }
      } )
    },
    saveTheMetaData( metaData ) {
      let payload = {};
      payload.metadatas = metaData;
      payload.languageCode = this.languageCode
      this.saveMetaData( payload )
    },
    removeTheFormelement( formelement ) {
      let self = this
      Swal.fire( {
        title            : 'The element will be deleted, this can not be undone, continue?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return

        self.removeFormelement( {
          formelementID: formelement.id,
          formpageID   : self.selectedFormpage.id,
          languageCode : self.languageCode
        } ).then( () => {
          ra.toasterMessage( 'element was succesfully removed', 'success' )
          self.getFormelements( 0 )
        } )
      } )
    },
    initFroala( formelement ) {
      this.setTextType( 'ELEMENTINFO' )
      this.setTextTypeID( formelement.id )
      this.setShowEditor( true )
    },
  }
}
</script>

<style scoped>

.formpage-element-widget {
  position: relative;
  background-color: #fff;
  border: 3px solid #952176;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.half-widget {
  width: 48%;
  margin-right: 1%;
  float: left;
}

.whole-widget {
  clear: both;
}

.formpage-element-widget-header {
  background-color: #952176;
  border-radius: 5px 5px 0 0;
  margin: -15px -15px 10px -15px;
  padding: 5px 15px;
  color: #fff;
  cursor: move;
}

.selected-element {
  background-color: #027208;
}

.selected-element-border {
  border-color: #027208;
}

.formpage-element-widget-footer {
  padding: 15px 0;
  border-top: 1px solid #ebebeb;
  margin-top: 15px;
}

.close {
  color: #fff !important;
  cursor: pointer;
}

.fa-grip-lines {
  float: right;
  position: relative;
  top: 4px;
  right: 10px;
  opacity: .55;
}

.fa-grip-lines:hover {
  opacity: .9;
}

.flow-button {
  font-size: 13px;
  color: #fff;
  background-color: #526996;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  bottom: 4px;
  right: 5px;
}

</style>