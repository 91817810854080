<template>
  <milestone-category
      v-if="showMilestonecategory"
      @closePopup="closePopup"
      :milestoneDomainID="domain.id"
      :milestoneCategoryID="milestoneCategoryID">
  </milestone-category>
  <div class="card mb-4"
       style="background-color:#F9FDFF">
    <div class="card-header justify-content-between align-items-center text-white"
         :class="domain.active ? 'bg-success': 'bg-info'"
         @click="setActiveDomain">
      <h4>
        {{ domain.age_start }}
        <translation :t="domain.age_start_type"></translation>
        -
        {{ domain.age_end }}
        <translation :t="domain.age_end_type"></translation>
        <i class="fa fa-trash right-icon"
           @click.stop="removeMilestoneDomain"></i>
      </h4>
    </div>

    <div class="card-body"
         v-if="domain.active">
      <div class="row mt-2">
        <div class="input-group mt-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <translation :t="'STARTAGE'"></translation>
            </span>
          </div>
          <input type="number"
                 class="form-control"
                 min="0"
                 step="1"
                 style="text-align:right"
                 v-model="domain.age_start"
                 @change="checkAge">
          <select class="form-control"
                  @change="checkAge"
                  v-model="domain.age_start_type">
            <option value="MONTH">
              <translation :t="'MONTH'"></translation>
            </option>
            <option value="YEAR">
              <translation :t="'YEAR'"></translation>
            </option>
          </select>
          <div class="input-group-prepend">
            <span class="input-group-text">
              <translation :t="'ENDAGE'"></translation>
            </span>
          </div>
          <input type="number"
                 class="form-control"
                 style="text-align:right"
                 min="0"
                 step="1"
                 v-model="domain.age_end"
                 @keyup="delayedSave"
                 @change="checkAge">
          <select class="form-control"
                  @change="checkAge"
                  v-model="domain.age_end_type">
            <option value="MONTH">
              <translation :t="'MONTH'"></translation>
            </option>
            <option value="YEAR">
              <translation :t="'YEAR'"></translation>
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <ul>
            <li v-for="milestoneCategory in orderedMilestonecategories"
                @click="selectDomaincategory(milestoneCategory.id)"
                class="milestonecategory-item pointer"
                :class="milestoneCategory.active ? 'bg-success' : 'bg-info'">
              <span class="fas fa-arrow-down pointer"
                    style="margin:0 5px;"
                    @click.stop="moveMilestoneCategory('DOWN', milestoneCategory.id)"></span>
              <span class="fas fa-arrow-up pointer"
                    style="margin:0 5px 0 0;"
                    @click.stop="moveMilestoneCategory('UP', milestoneCategory.id)"></span>
              {{ milestoneCategory.translation.translation }}
              <span class="fa fa-trash right-icon"
                    style="margin-left:15px;"
                    @click.stop="removeMilestoneCategory(milestoneCategory.id)"></span>
              <span class="fa fa-edit right-icon"
                    @click.stop="editMilestoneCategory(milestoneCategory.id)"></span>
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btn-warning pull-right btn-small"
              style="float:right;"
              @click="addCategory">
        <translation :t="'NEWCATEGORY'"></translation>
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapState, mapGetters, mapActions} from 'vuex'
import InputTranslation from "../../controls/InputTranslation"
import MilestoneAttribute from "./MilestoneAttribute"
import {api} from '@/plugins/axios'
import MilestoneCategory from "@/components/forms/milestones/MilestoneCategory"

export default {
  name : "Milestonedomain",
  props: ['domain'],
  emits: ['setActiveDomain', 'removeMilestoneDomain'],
  data() {
    return {
      inEdit                 : false,
      timeout                : null,
      domainname             : '',
      activeDomainAttributeID: null,
      milestoneCategoryID    : null,
      showMilestonecategory  : false
    }
  },
  components: {InputTranslation, MilestoneAttribute, MilestoneCategory},
  computed  : {
    ...mapState( 'milestoneModule', ['milestoneForm'] ),
    orderedMilestonecategories() {
      return _.orderBy( this.milestoneForm.milestonecategories, 'milestonecategory_order' )
    }
  },
  created() {
    this.domainname = this.domain.translation ? this.domain.translation.translation : ''
  },
  watch  : {},
  methods: {
    ...mapActions( 'milestoneModule',
        ['saveDomain',
          'newDomainCountry',
          'getMilestoneForm',
          'removeMilestone',
          'saveMilestoneCategoryOrder',
          'setActiveMilestoneCategoryID',
          'newMilestonecategory',
          'getMilestoneIndicators',
          'setMilestoneIndicators'] ),
    checkAge() {
      this.domain.age_start = Number( this.domain.age_start ) * 1
      this.domain.age_end = Number( this.domain.age_end ) * 1

      let startAge = this.domain.age_start
      let endAge = this.domain.age_end

      let startAgeMonth =
              this.domain.age_start_type == 'YEAR' ? startAge * 12 : startAge
      let endAgeMonth =
              this.domain.age_end_type == 'YEAR' ? endAge * 12 : endAge

      if ( startAgeMonth > endAgeMonth ) endAgeMonth = startAgeMonth

      this.domain.age_start =
          this.domain.age_start_type == 'YEAR' ? Math.ceil( startAgeMonth / 12 ) : startAgeMonth
      this.domain.age_end =
          this.domain.age_end_type == 'YEAR' ? Math.ceil( endAgeMonth / 12 ) : endAgeMonth

      this.saveTheDomain()
    },
    delayedSave() {
      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
      this.timeout = setTimeout( function () {
        this.saveTheDomain()
      }, 700 );
    },
    saveTheDomain() {
      let payload = {}
      payload.id = this.domain.id
      payload.age_start = this.domain.age_start
      payload.age_start_type = this.domain.age_start_type
      payload.age_end = this.domain.age_end
      payload.age_end_type = this.domain.age_end_type

      this.saveDomain( payload )
    },
    addDomainCountry() {
      this.newDomainCountry( this.domain.id )
          .then( _ => {
            this.getMilestoneForm( this.domain.form_id )
          } )
    },
    addCategory() {
      this.newMilestonecategory( this.domain.milestonecountry_id )
          .then( result => {
            this.milestoneCategoryID = result.data.id
            this.showMilestonecategory = true
          } )
    },
    setActiveDomain() {
      if ( this.domain.active ) return this.domain.active = false
      this.$emit( 'setActiveDomain', this.domain.id )
    },
    setActiveDomainAttribute( domainAttributeID ) {
      this.activeDomainAttributeID = domainAttributeID
      this.domain.milestonedomainattributes.forEach( m => m.active = m.id == domainAttributeID )
    },
    selectDomaincategory( milestoneCategoryID ) {
      this.milestoneForm.milestonecategories.forEach( mc => {
        mc.active = mc.id == milestoneCategoryID
      } )
      let payload = {}
      payload.milestoneDomainID = this.domain.id
      payload.milestoneCategoryID = milestoneCategoryID
      this.setActiveMilestoneCategoryID( milestoneCategoryID )
      this.getMilestoneIndicators( payload )
          .then( result => {
            this.setMilestoneIndicators( result.data )
          } )
    },
    editMilestoneCategory( milestoneCategoryID ) {
      this.milestoneCategoryID = milestoneCategoryID
      this.showMilestonecategory = true
    },
    moveMilestoneCategory( direction, milestoneCategoryID ) {
      let cIndex = this.orderedMilestonecategories.findIndex( mc => mc.id == milestoneCategoryID )

      if ( direction == 'UP' && cIndex == 0 ) return;
      if ( direction == 'DOWN' && cIndex == (this.orderedMilestonecategories.length - 1) ) return

      let oldOrder = this.orderedMilestonecategories[cIndex].milestonecategory_order

      if ( direction == 'UP' ) {
        this.milestoneForm.milestonecategories[cIndex].milestonecategory_order =
            this.milestoneForm.milestonecategories[cIndex - 1].milestonecategory_order

        this.milestoneForm.milestonecategories[cIndex - 1].milestonecategory_order = oldOrder
      } else {
        this.milestoneForm.milestonecategories[cIndex].milestonecategory_order =
            this.milestoneForm.milestonecategories[cIndex + 1].milestonecategory_order

        this.milestoneForm.milestonecategories[cIndex + 1].milestonecategory_order = oldOrder
      }

      let order = 0;
      let categories = []
      this.orderedMilestonecategories.forEach( om => {
        order += 10
        categories.push( {id: om.id, order: order} )
      } )
      this.saveMilestoneCategoryOrder( categories )
    },
    closePopup() {
      console.log( this.domain );
      this.showMilestonecategory = false
      this.getMilestoneForm( this.domain.milestonecountry_id )
    },
    removeMilestoneDomain() {
      let payload = {}
      payload.milestoneID = this.domain.id
      payload.type = 'MILESTONEDOMAIN'

      this.removeMilestone( payload )
          .then( result => {
            if ( !result.data.success )
              return alert( 'Domain has indicators, remove them first' )

            this.$emit('removeMilestoneDomain', this.domain.id)
          } )
    },
    removeMilestoneCategory( milestoneCategoryID ) {
      let payload = {}
      payload.milestoneID = milestoneCategoryID
      payload.type = 'MILESTONECATEGORY'

      this.removeMilestone( payload )
          .then( result => {
            console.log( 'RESULT:', result );
            if ( !result.data.success )
              return alert( 'Category has indicators, remove them first' )

            let index = this.milestoneForm.milestonecategories.findIndex( mc => mc.id == milestoneCategoryID )
            this.milestoneForm.milestonecategories.splice( index, 1 )
          } )
    }
  }
}
</script>

<style scoped>
.right-icon {
  float: right;
  font-weight: 700;
  line-height: 1;
  opacity: .8;
  cursor: pointer;
}

.right-icon:hover {
  opacity: 1;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.milestonecategory-item {
  padding: 3px 10px;
  border-radius: 3px;
  margin-bottom: 3px;
  color: #fff;
}
</style>