<template>
  <span v-if="showEditor">
    <div id="froala-backdrop" class="my-backdrop"></div>
    <div id="froala-popup" class="my-popup">
      <div class="my-popup-header">
        <h3>
          <translation :t="'TEXTEDITOR'"></translation>
          <button class="close" @click="setShowEditor(false)">x</button>
        </h3>
      </div>
      <div class="my-popup-content">
          <div id="my-froala-editor"></div>
        <br />
        <button class="btn btn-success btn-small"
                @click="saveTheEditortext">
          <translation :t="'SAVE'"></translation>
        </button>
<!--        <iframe src="/froala.html"-->
<!--                style="width:100%; min-height:400px; border:0;"></iframe>-->
          <hr/>
          <div v-for="language in languages"
               class="language-selector-box"
               :class="language.languagecode == selectedLanguage ? 'bg-orange' : ''"
               @click="setEditortranslation(language)">
            {{ language.translation }}
          </div>
      </div>
    </div>
  </span>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation";
import {api} from '@/plugins/axios'

let myEditor = null

export default {
  name : "FroalaEditor",
  data() {
    return {
      selectedLanguage  : null,
      editorTranslations: []
    }
  },
  components: {Translation},
  computed  : {
    ...mapGetters( ['languageCode', 'languages'] ),
    ...mapGetters( 'translate', ['translations'] ),
    ...mapGetters('editorModule', ['showEditor', 'editorText', 'textType', 'textTypeID'])
  },
  watch     : {
//     textTypeID() {
//       this.getTheEditorTranslations()
//     },
    showEditor(){
      if( ! this.showEditor ) return

      let self = this

      if( myEditor ) myEditor.destroy();
      setTimeout(function(){
        myEditor = new FroalaEditor( '#my-froala-editor', {
          imageEditButtons   : ['imageReplace', 'imageAlign', 'imageRemove', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
          imageUpload        : true,
          key                : '7MD3aA3E3C5C4A3B2xROKLJKYHROLDXDRE1b1YYGRi1Bd1C4F4B3A2G3A14A15A12C4D5==',
          zIndex             : 2501999,
          imageDefaultDisplay: 'inline-block',
          imageMaxSize       : 1024 * 500,
          imageAllowedTypes  : ['jpeg', 'jpg', 'png'],
          attribution        : false,
          charCounterCount   : false,
          events             : {
            'image.beforeUpload': function ( images ) {
              // Before image is uploaded
              const data = new FormData();
              data.append( 'image', images[0] );

              api.post( '/api/texteditor/upload_image', data, {
                headers: {
                  'accept'      : 'application/json',
                  'Content-Type': `multipart/form-data`
                }
              } )
                  .then( result => {
                    myEditor.image.insert( result.data.link, null, null, myEditor.image.get() );
                  } ).catch( err => {
                console.log( err );
              } )
              return false;
            },
            'image.error'       : function ( error, response ) {
              console.log( error );
              console.log( response );
            }
          }
        } )
        self.getTheEditorTranslations();
      },700);
    }
  },
  created() {
    this.selectedLanguage = this.languageCode
  },
  methods: {
    ...mapActions( 'editorModule', ['saveEditortext', 'getEditorText', 'translateEditortext', 'setShowEditor', 'updateEditortext'] ),
    ...mapActions( 'translator', ['getTranslations'] ),
    setEditortranslation( language ) {
      this.selectedLanguage = language.languagecode

      this.setEditortext()
    },
    getTheEditorTranslations() {
      let self = this
      this.getEditorText( )
          .then( result => {
            self.editorTranslations = result.data
            self.setEditortext()
          } )
    },
    setEditortext() {
      let self = this
      let isTranslated = false
      myEditor.html.set('')
      if ( !self.editorTranslations ) return
      self.editorTranslations.forEach( translation => {
        if ( self.selectedLanguage == translation.languagecode ) {
          myEditor.html.set( translation.translation )
          isTranslated = true
        }
      } )
      if ( !isTranslated ) {
        let payload = {}
        payload.textType = this.textType
        payload.textTypeID = this.textTypeID
        payload.languageCode = self.selectedLanguage

        this.translateEditortext( payload )
            .then( result => {
              self.getTheEditorTranslations();
            } )
      }
    },
    saveTheEditortext() {
      let self = this
      let payload = {}
      payload.html = myEditor.html.get()
      payload.languageCode = this.languageCode
      payload.textType = this.textType
      payload.textTypeID = this.textTypeID

      this.updateEditortext(payload.html)

      this.saveEditortext( payload )
          .then( function () {
            let msg = ra.findTranslation( self.translations, 'TEXTSUCCESSFULLYSAVED' )
            ra.toasterMessage( msg, 'success' )
          } )
    }
  }
}
</script>

<style>
.language-selector-box {
  float: left;
  padding: 5px 10px;
  border: 1px solid #3c2c2c;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #652b74;
  color: #fff;
  cursor:pointer;
}
.language-selector-box:hover{
  box-shadow: #aaa 3px 3px 3px;
  background-color: #8c6097;
}
.language-selector-box-selected{
  background-color: #ffc107;
  border: 1px solid #dea904;
  color: #000;
}
.language-selector-box-selected:hover{
  cursor:default;
  box-shadow: none;
  background-color:  #ffc107;
}
#froala-popup{
  width:700px;
  left:50%;
  margin-left:-350px;
}
</style>