<template>
  <datecondition v-for="flowelementcondition in flowelementconditions"
                 @refreshFlow="refreshFlow"
                 @removeUnsavedCondition="removeUnsavedCondition"
                 :flowelementcondition="flowelementcondition">
  </datecondition>
  <hr/>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-warning float-right"
              @click="addTheCondition">
        <translation :t="'ADDCONDITION'"></translation>
      </button>
      <button class="btn btn-success float-right"
              :disabled="inValidConditions"
              @click="saveTheConditions">
        <translation :t="'SAVE'"></translation>
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Datecondition from "./Datecondition"

export default {
  name : "Dateconditions",
  props: ['formelement'],
  emits: [],
  data() {
    return {
      flowelementconditions: [],
      flowelementID        : null
    }
  },
  components: {Datecondition},
  computed  : {
    inValidConditions() {
      console.log( this.flowelementconditions.length );
      if ( this.flowelementconditions.length == 0 ) return true
console.log( 'hjjhhj' );
      let inValid = false
      console.log( inValid );
      this.flowelementconditions.forEach( condition => {
        let months1 =
                condition.date_comparator_greater == 'MONTH'
                    ? condition.greater_and_equal : condition.greater_and_equal * 12
        let months2 =
                condition.date_compararator_smaller == 'MONTH'
                    ? condition.smaller_than : condition.smaller_than * 12
console.log( months1, months2 );
        if ( Number( months1 ) < 0 ) inValid = true
        if ( Number( months2 ) <= Number( months1 ) || !months2 ) inValid = true
      console.log( 'condition 2' );
        if ( condition.formpage_id == 0 || !condition.formpage_id ) inValid = true
      } )

      return inValid
    }
  },
  created() {
    this.refreshFlow()
  },
  methods: {
    ...mapActions( 'formpageModule', ['getFlowmelement', 'addCondition', 'saveConditions'] ),
    addTheCondition() {
      let self = this
      this.flowelementconditions.push( {
        id                     : 0,
        uuid                   : getUuid(),
        formpage_id            : null,
        flowelement_id         : self.flowelementID,
        greater_and_equal      : 0,
        smaller_than           : 0,
        date_comparator_greater: 'YEAR',
        date_comparator_smaller: 'YEAR',
        category_value         : null
      } )
    },
    refreshFlow() {
      let self = this
      self.getFlowmelement( self.formelement.id )
          .then( result => {
            self.flowelementID = result.data.id
            self.flowelementconditions = result.data.flowelementconditions
          } )
    },
    saveTheConditions() {
      let self = this
      self.saveConditions( self.flowelementconditions )
          .then( () => {
            self.refreshFlow()
          } )
    },
    removeUnsavedCondition(flowelementcondition){
      let self = this
      let index = self.flowelementconditions.findIndex(c=>{
        return c.uuid == flowelementcondition.uuid
      })
      self.flowelementconditions.splice(index, 1);
    },
  }
}
</script>

<style scoped>

</style>