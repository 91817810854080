<template>

  <!-- label for -->
  <div class="row">
    <div class="col-12">
      <label class="">
        <translation :t="'LABELFORRADIO'"></translation>
      </label>
      <div class="input-group">
        <input v-model="metaData.label.value"
               @keyup="saveTypedMetaData"
               class="form-control form-control-sm"/>
        <div class="input-group-append"
             style="position:relative"
             @click="toggleShowTranslations(metaData.label)">
          <span class="input-group-text
                      bg-transparent border-left-0">
            <i class="far fa-flag"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- end label for -->

  <!-- counter -->
  <div class="row" v-if="metaData.itemtype.value != 'CATEGORY'">
    <div class="col-12">
      <hr/>
      <button class="btn btn-info"
              @click="saveTheMetaData('PLUS')">+
      </button>
      <label class="counter-label">
        {{ metaData.items.value }}
      </label>
      <button class="btn btn-info"
              @click="saveTheMetaData('MINUS')"
              :disabled="metaData.items.value == 0">-
      </button>
      <label style="margin:5px 15px 0 15px;">
        <translation :t="'ITEMAMMOUNT'"></translation>
      </label>
    </div>
  </div>
  <!-- end counter -->

  <!-- category choice -->
  <div class="row" v-if="metaData.itemtype.value == 'CATEGORY'">
    <div class="col-12">
        <label>
          <translation :t="'CHOOSECATEGORY'"></translation>
        </label>
        <select class="form-control form-control-sm"
                @change="savecategoryID"
                v-model="categoryID">
          <option value="null">
            <translation :t="'SELECTITEM'"></translation>
          </option>
          <option v-for="category in categories" :value="category.id">
            {{ category.translation ? category.translation.translation : 'NB' }}
          </option>
        </select>
    </div>
  </div>
  <!-- end category choice -->

  <!-- itemtype choice -->
  <div class="row">
    <div class="col-12">
      <hr/>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'RANGE'"></i>
        <i class="far fa-square"
           @click="setItemtype('RANGE')"
           v-if="metaData.itemtype.value != 'RANGE'"></i>
        <label>
          <translation :t="'ASCENDINGRANGE'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'LADDER'"></i>
        <i class="far fa-square"
           @click="setItemtype('LADDER')"
           v-if="metaData.itemtype.value != 'LADDER'"></i>
        <label>
          <translation :t="'LADDER'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'CATEGORY'"></i>
        <i class="far fa-square"
           @click="setItemtype('CATEGORY')"
           v-if="metaData.itemtype.value != 'CATEGORY'"></i>
        <label>
          <translation :t="'CATEGORY'"></translation>
        </label>
      </div>
      <div class="item-choice" style="margin-left:15px;">
        <i class="fa fa-check"
           v-if="! metaData.itemStyle || metaData.itemStyle.value != 'COLUMN'"></i>
        <i class="far fa-square"
           @click="setItemstyle('ROW')"
           v-else></i>
        <label>
          <translation :t="'ROW'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemStyle && metaData.itemStyle.value == 'COLUMN'"></i>
        <i class="far fa-square"
           @click="setItemstyle('COLUMN')"
           v-else></i>
        <label>
          <translation :t="'COLUMN'"></translation>
        </label>
      </div>
    </div>
  </div>
  <!-- end itemtype choice -->
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"

export default {
  name : "Radiogroup",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      level     : 0,
      categoryID: null
    }
  },
  components: {Translation},
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] ),
    items() {
      let items = []
      for ( let i = this.metaData.items.value; i > 0; i-- ) {
        items.push( i )
      }
      return items
    }
  },
  created() {
    this.categoryID = this.metaData.categoryID.value
  },
  methods: {
    toggleShowTranslations( translator ) {
      this.$emit( 'showTranslator', translator )
    },
    setItemtype( type ) {
      this.metaData.itemtype.value = type

      this.$emit( 'saveMetaData', this.metaData )
    },
    setItemstyle(itemStyle){
      this.metaData.itemStyle.value = itemStyle

      this.$emit( 'saveMetaData', this.metaData )
    },
    showItemlabels() {
      $( '#item-labels' ).modal( 'show' )
    },
    saveTypedMetaData() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.$emit( 'saveMetaData', self.metaData )
      }, 700 );
    },
    savecategoryID() {
      this.metaData.categoryID.value = this.categoryID
      this.saveTheMetaData()
    },
    saveTheMetaData( direction ) {
      if ( direction == 'MINUS' ) this.metaData.items.value--
      if ( direction == 'PLUS' ) this.metaData.items.value++

      this.$emit( 'saveMetaData', this.metaData )
    }
  }
}
</script>

<style>
.item-choice {
  float: left;
  padding-right: 10px;
}

.item-choice label {
  margin-left: 5px;
}

.counter-label {
  width: 20px;
  text-align: center;
  font-weight: bold;
  position: relative;
  left: 5px;
}


</style>