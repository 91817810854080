<template>
  <div class="side-panel side-panel-phone"
       :class="showPhonePanel ? 'open-panel' : ''">
    <h3 class="side-button side-phone-button"
        @click="toggleShowPhonePanel">
      <translation :t="'PHONEEXAMPLE'"></translation>
    </h3>
    <div class="card bg-primary-light" style="width:600px;height:100%;">
      <div class="card-body">
        <div class="smartphone">
          <span>
            <div class="content">
                <div class="formelement-holder"
                     :class="formelement.metadata.columns.value == 2 ? 'full' : 'half'"
                     v-for="formelement in formelements">
                  <component :is="formelement.elementtype.component"
                             :infoText="formelement.editortext ? formelement.editortext.translation : false"
                             :elementID="formelement.id"
                             :metaData="formelement.metadata"/>
                  <!--              <br v-if="formelement.metadata.columns.value == 2">-->
                </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Inputfield from '@/components/forms/formelements/phone/InputfieldPhone.vue'
import Textareainput from '@/components/forms/formelements/phone/TextareaPhone.vue'
import Radiogroup from '@/components/forms/formelements/phone/RadiogroupPhone.vue'
import Checkbox from '@/components/forms/formelements/phone/CheckboxPhone.vue'
import Selectbox from '@/components/forms/formelements/phone/SelectboxPhone.vue'
import Paragraph from '@/components/forms/formelements/phone/ParagraphPhone.vue'
import Goalgenerator from '@/components/forms/formelements/phone/GoalGeneratorPhone.vue'

export default {
  name : "PhoneExample",
  props: ['editorText'],
  data() {
    return {
      showPhonePanel: false
    }
  },
  components: {
    Inputfield, Textareainput, Radiogroup, Checkbox, Selectbox, Paragraph, Goalgenerator
  },
  computed  : {
    ...mapGetters( 'formpageModule', ['formelements', 'showInfoTextPanel', 'selectedElement'] )
  },
  methods   : {
    toggleShowPhonePanel() {
      this.showPhonePanel = !this.showPhonePanel
    }
  }
}
</script>

<style>
.content {
  padding: 15px;
  background-color: #ebebeb;
}
.cms-content{
  height:100%;
  position: relative;
}

.smartphone {
  position: relative;
  width: 395px;
  height: 760px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: #ebebeb;
  overflow: auto;
}

.info-text-holder {
  position: relative;
}

.info-text {
  position: absolute;
  right: 0;
  font-size: 1.3em;
  cursor: pointer;
}

.formelement-holder {
  padding: 10px 0;
  float: left;
}

.formelement-holder:after {
  clear: both;
}

label {
  font-weight: 600;
  font-size: 1.1em;
}

.full {
  clear: both;
  width: 100%;
}

.half {
  width: 47%;
  float: left;
  padding-right: 5px;
  padding-left: 5px;
}


.demo_wrap {
  position: relative;
  overflow: hidden;
  padding: 16px;
  height:100%;
}
.demo_wrap_color{
  background-color: #3e8125;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.demo_wrap h1 {
  padding: 100px 10px;
  position: relative;
  z-index: 2;
  text-align: center;
  color:#fff;
}
.demo_wrap img {
  position: absolute;
  left: 0;
  top: 0;
  bottom:0;
  right:0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  filter: blur(1.5px) brightness(0.5) contrast(0.5) grayscale(0.43) saturate(2.3) url('#gold-sunset');

  /*filter: grayscale(90%);*/
}
</style>