/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router";

export default {
    getFormpages( context, library ) {
        let formID = context.rootState.formModule.selectedForm.id
        let languageCode = context.rootState.languageCode
        api.get( '/api/formpages/getFormpages/' + formID + '/' + languageCode + '/' + library )
            .then( ( result ) => {
                if ( library == 0 ) {
                    context.commit( 'setFormpages', result.data )
                } else {
                    context.commit( 'setLibrary', result.data )
                }
            } )
    },
    resetFormpages( context ) {
        context.commit( 'setFormpages', [] )
    },
    getElementtypes( context, payload ) {
        api.get( '/api/formpages/getElementtypes/' + payload )
            .then( ( result ) => {
                context.commit( 'setElementtypes', result.data )
            } )
    },
    getFormelements( context, library ) {
        let languageCode = context.rootState.languageCode
        let formpageID = context.rootState.formpageModule.selectedFormpage.id
        api.get( '/api/formpages/getFormelements/' + formpageID + '/' + languageCode + '/' + library )
            .then( ( result ) => {
                if ( library == 0 ) {
                    context.commit( 'setFormelements', result.data )
                } else {
                    context.commit( 'setLibrary', result.data )
                }
            } )
    },
    getFlowmelement( context, formelementID ) {
        return api.get( '/api/flowelement/getFlowmelement/' + formelementID )
    },
    getCycleformDomains( context, pageformID ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/cycleforms/getCycleformDomains/' + pageformID + '/' + languageCode )
    },
    getLinkedCycledomainArticles(context, cycleformID) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/cycleforms/getCycleformDomainlinkedArticles/' + cycleformID + '/' + languageCode )
    },
    setFormID( context, payload ) {
        context.commit( 'setFormID', payload )
    },
    setClientFormpage( context, payload ) {
        return api.get( '/api/formpages/setClientFormpage/' + payload.id + '/' + payload.formpagetype )
    },
    selectFormpage( context, payload ) {
        context.commit( 'selectFormpage', payload )
    },
    setSelectedElement( context, payload ) {
        context.commit( 'setSelectedElement', payload )
    },
    resetSelectedFormpage( context ) {
        context.dispatch( 'resetFormpage' )
    },
    toggleShowInfoTextPanel( context ) {
        context.commit( 'setShowInfoTextPanel' )
    },
    reorderFormpages( context, payload ) {
        const formData = new FormData;
        formData.append( 'reorderedFormpages', JSON.stringify( payload ) )
        return api.post( '/api/formpages/reorderFormpages', formData )
    },
    reorderFormelements( context, payload ) {
        const formData = new FormData;
        formData.append( 'reorderedFormelements', JSON.stringify( payload ) )
        return api.post( '/api/formelements/reorderFormelements', formData )
    },
    moveFormpage( context, payload ) {
        return api.get( '/api/formpages/moveFormpage/' + payload.formpageID + '/' + payload.direction )
    },
    moveElement( context, payload ) {
        return api.get( '/api/formpages/moveelement/' + payload.formelementID + '/' + payload.direction )
    },
    copyFormpage( context, payload ) {
        let languageCode = context.rootState.languageCode
        let formID = context.rootState.formModule.selectedForm.id

        return api.get( '/api/formpages/copyFormpage/' + formID + '/' + payload.formpageID + '/' + languageCode + '/' + payload.library )
    },
    copyFormpageElement( context, payload ) {
        const formData = new FormData;
        formData.append( 'languageCode', context.rootState.languageCode )
        formData.append( 'formpageID', payload.formpageID )
        formData.append( 'formpageElementID', payload.selectedElementID )
        return api.post( '/api/formpages/copyFormpageElement', formData )
    },
    addFormpageElementToLibrary( context, libraryName ) {
        const formData = new FormData;
        formData.append( 'languageCode', context.rootState.languageCode )
        formData.append( 'formpageID', context.rootState.formpageModule.selectedElement.formpage_id )
        formData.append( 'formpageElementID', context.rootState.formpageModule.selectedElement.id )
        formData.append( 'libraryName', libraryName )
        return api.post( '/api/formpages/addFormpageElementToLibrary', formData )
    },
    updateFormpagename( context, payload ) {
        context.commit( 'updateFormpagename', payload )
    },
    addCondition( context, formelementID ) {
        return api.get( '/api/flowelement/addCondition/' + formelementID )
    },
    saveConditions( context, payload ) {
        const formData = new FormData;
        formData.append( 'formdata', JSON.stringify( payload ) )
        return api.post( '/api/flowelement/saveConditions', formData )
    },
    saveCondition( context, payload ) {
        const formData = new FormData;
        formData.append( 'formdata', JSON.stringify( payload ) )
        return api.post( '/api/flowelement/saveCondition', formData )
    },
    addCycledomain(context, payload){
        const formData = new FormData;
        payload.languageCode = context.rootState.languageCode
        formData.append( 'formdata', JSON.stringify( payload ) )
        return api.post( '/api/cycleforms/addCycledomain', formData )
    },
    attachCycledomainTree(context, payload){
        return api.get('/api/cycleforms/attachCycledomainTree/' + payload.cycledomainID + '/' + payload.treeID)

    },
    detachCycledomainTree(context, payload){
        return api.get('/api/cycleforms/detachCycledomainTree/' + payload.cycledomainID + '/' + payload.treeID)
    },
    detachArticle(){

    },
    removeCondition( context, conditionID ) {
        return api.get( '/api/flowelement/removeCondition/' + conditionID )
    },
    saveFollowup( context, payload ) {
        return api.get(
            '/api/formpages/saveFollowup/' + payload.id + '/' + payload.followup )
    },
    saveFormpagename( context, payload ) {
        const formData = new FormData;
        formData.append( 'formpage', JSON.stringify( payload ) )
        return api.post( '/api/formpages/saveFormpagename', formData )
            .then( () => {
                context.dispatch( 'updateFormpagename', payload.formpagename )
            } )
    },
    saveFormpage( context, payload ) {
        const formData = new FormData;
        formData.append( 'formpage', JSON.stringify( payload ) )
        return api.post( '/api/formpages/saveformpage', formData )
            .then( ( result ) => {
                return result.data
            } )
    },
    saveMetaData( context, payload ) {
        const formData = new FormData;
        formData.append( 'formdata', JSON.stringify( payload.metadatas ) )
        api.post( '/api/formpages/saveMetadata/' + payload.languageCode, formData )
    },
    createFormelement( context, payload ) {
        const formData = new FormData;
        formData.append( 'formdata', JSON.stringify( payload ) )

        return api.post( '/api/formpages/createFormelement', formData )
    },
    createMandatory(context, formelementID){
        return api.get('/api/formelement/createMandatory/' + formelementID)
    },
    removeFormpage( context, formpageID ) {
        return api.get( '/api/forms/removeformpage/' + formpageID )
    },
    removeFormelement( context, payload ) {
        const formData = new FormData;
        formData.append( 'formdata', JSON.stringify( payload ) )

        return api.post( '/api/formpages/removeFormelement', formData )
    },
    removeCycledomain(context, cycledomainID){
        return api.get('/api/cycleforms/removeCycledomain/' + cycledomainID )
    }

}