/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    translations( state ) {
        return state.translations
    },
    countries(state){
        return state.countries
    },
    allTranslations(state){
        return state.allTranslations
    }
}
