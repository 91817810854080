<template>
  <div class="my-popup-content"
       id="drop-area"
       v-cloak
       :class="{'highlight': draggedEntered}"
       @dragenter.prevent.stop="dragIn"
       @dragover.prevent.stop="dragIn"
       @dragleave.prevent.stop="dragOut"
       @drop.prevent="handleFiles">
    <form class="my-form">
      <span v-if="! simple">
        <p v-if="multiUpload">Upload multiple files with the file dialog (blue button) or by dragging and dropping images
          onto the dashed region</p>
        <p v-else>Upload a single with the file dialog (blue button) or by dragging and dropping an image onto the dashed
          region</p>
      </span>
      <label v-else>
        <translation :t="simpleText"></translation>
      </label>
      <input type="file"
             id="fileElem"
             @change="handleFiles">
      <label class="button" for="fileElem" v-if="! simple">
              <span v-if="multiUpload">
                Select some files
              </span>
        <span v-else>
                Select a file
              </span>
      </label>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {guest, api} from '@/plugins/axios'
import Translation from "@/components/controls/Translation";

export default {
  name : "Upload",
  props: ['multiUpload', 'simple', 'simpleText', 'uploadData'],
  data() {
    return {
      draggedEntered: false
    }
  },
  components: {Translation},
  emits     : ['uploadFinished'],
  computed  : {
    ...mapGetters( 'uploadModule', ['selectedFile'] )
  },
  methods   : {
    ...mapActions( 'uploadModule', ['doUpload', 'setSelectedFile', 'setShowUpload'] ),

    dragIn() {
      this.draggedEntered = true
    },
    dragOut() {
      this.draggedEntered = false
    },
    handleFiles( event ) {
      let self = this
      self.draggedEntered = false

      let droppedFiles = event.dataTransfer.files
      if ( !droppedFiles ) return

      let formData = new FormData()
      let files = [...droppedFiles]

      files.forEach( ( f, x ) => {
        if ( x == 0 || self.multiUpload )
          formData.append( 'file' + (x + 1), f )
      } );

      if( this.uploadData ) formData.append('uploadData', JSON.stringify(this.uploadData))
      self.doUpload( formData )
          .then( result => {
            self.$emit( 'uploadFinished', result.data )
            self.setShowUpload( false )
          } )
    },

    onFileSelected( event ) {
      if ( !event.target.files[0] ) return

      this.setSelectedFile( event.target.files[0] )
      this.doUpload()
          .then( result => {
            this.$emit( 'uploadFinished', result.data )
            this.setShowUpload( false )
          } )
    }
  }
}
</script>

<style scoped>
#drop-area {
  border: 2px dashed #16a3b8;
  border-radius: 20px;
  width: 100%;
  font-family: sans-serif;
  padding: 20px;
}

#drop-area.highlight {
  border-color: purple;
  background-color: #fde7fd;
}

p {
  margin-top: 0;
}

.my-form {
  margin-bottom: 10px;
}

#gallery {
  margin-top: 10px;
}

#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.button {
  display: inline-block;
  padding: 10px;
  background: #16a3b8;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #16a3b8;
  color: #fff;
}

.button:hover {
  background: #23bdd4;
}

#fileElem {
  display: none;
}
</style>