<template>
  <span v-if="formelement.metadata.itemtype.value == 'CATEGORY' && category">
    <div v-for="item in category.categoryitems"
         class="row"
         style="border-bottom: 1px solid #d2d2d2;
              padding:3px 0;
              margin:3px 0;">
      <div class="col-4">
        <select class="form-control form-control-sm"
                style="margin-top:7px;"
                v-model="item.formpage_id"
                @change="saveTheCondition(item)">
          <option value="0">
            <translation :t="'SELECTIT'" value="0"></translation>
          </option>
          <option v-for="formpage in formpages"
                  :value="formpage.id">
            {{ formpage.formpagename }}
          </option>
        </select>
      </div>
      <div class="col-8">
        <label v-if="item.imagepath"
               class="category-item">
          <img :src="item.imagepath" height="40"/>
        </label>
        <label>
          {{ item.translation ? item.translation.translation : 'NB' }}
        </label>
      </div>
    </div>
  </span>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "Checkboxconditions",
  props: ['formelement'],
  emits: [],
  data() {
    return {
    }
  },
  components: {},
  computed  : {
    ...mapGetters( 'formpageModule', ['formpages'] ),
    ...mapGetters( 'categoryModule', ['categories'] ),
    category() {
      return this.categories.find( category => {
        return category.id == this.formelement.metadata.categoryID.value
      } )
    }
  },
  created() {
    this.refreshFlow()
  },
  methods: {
    ...mapActions( 'formpageModule', ['getFlowmelement', 'saveCondition'] ),
    refreshFlow() {
//       let self = this
      this.getFlowmelement( this.formelement.id )
          .then( result => {
            this.category.categoryitems.forEach(cat =>{
              let condition = false
              if( result.data != '' ){
                condition = result.data.flowelementconditions.find(f=>{
                  return f.category_id == cat.id
                })
              }
              cat.formpage_id = condition ? condition.formpage_id : null
            })
          } )
    },
    saveTheCondition( item ) {
      console.log( item );
      let payload = {}
      payload.formelement_id = this.formelement.id
      payload.category_id = item.id
      payload.formpage_id = item.formpage_id
      this.saveCondition(payload)
    }
  }
}
</script>

<style scoped>
.category-item {
  width: 60px;
}
</style>