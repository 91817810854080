<template>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="row row-margin-top">
          <div class="col-12">
            <upload v-if="selectedTreenodeID"
                    :multiUpload="true"
                    @uploadFinished="uploadFinished"></upload>
          </div>
        </div>
        <div class="row row-margin-top">
          <div class="col-12">
          </div>
        </div>
        <div class="row row-margin-top">
          <div class="col-6">
            <found-tags
                @selectTag="selectTag"
                @buttonAction="buttonAction"
                :addTags="false"
                :icon="'fa fa-search'"
                :placeHolder="'search tags'"
                :buttonClass="'btn-danger'"
                :buttonText="'REMOVESELECTEDIMAGES'"
                :buttonDisabled="selectedItems.length == 0 "
                :reset="true"
                :type="'SEARCH'"></found-tags>
          </div>
          <div class="col-6">
            <found-tags
                @selectTag="selectTag"
                @buttonAction="buttonAction"
                :addTags="true"
                :icon="'fa fa-tags'"
                :placeHolder="'add tags'"
                :buttonClass="'btn-success'"
                :buttonText="'ADDTAGS'"
                :buttonDisabled="selectedItems.length == 0 "
                :reset="true"
                :type="'ADDTAGS'"></found-tags>
          </div>
        </div>
        <div class="row">
          <div class="image-browser">
            <div v-for="image in images">
              <thumbnail :thumbnail="image"
                         @refreshImages="refreshImages"
                         @tagsClicked="tagsClicked"></thumbnail>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <i class="fa fa-folder-plus fa-2x dark-greenish hover-effect"
             @click="addTheTreenode(0)"></i>
          <tree :tree="tree"
                :treeType="entities.IMAGE_MANAGER"
                @refreshTree="refreshTree"
                @atSelection="atSelection"
                v-if="tree.length > 0"></tree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Tree from "@/components/cms/Tree"
import router from "@/router"
import Upload from "@/components/widgets/Upload"
import Thumbnail from "@/components/cms/Thumbnail"
import Translation from "../../components/controls/Translation"
import Swal from "sweetalert2"
import FoundTags from "@/components/imagebank/FoundTags";

export default {
  name: "Images",
  data() {
    return {
      tree              : [],
      images            : [],
      selectedThumbnails: [],
      searchTag         : '',
      tagsToAdd         : [],
      selectedTags      : [],
      selectedTreenodeID: null
    }
  },
  components: {FoundTags, Translation, Thumbnail, Image, Upload, Tree},
  computed  : {
    ...mapGetters( ['languageCode', 'entities'] ),
    ...mapGetters( 'tagModule', ['tags'] ),
    ...mapGetters( 'treeModule', ['selectedItems', 'refreshSource', 'lastSelectedItem', 'shiftClickedItem'] )
  },
  watch     : {
    refreshSource( val ) {
//       if ( val == 'images' ) {
//         this.atSelection()
//         this.setRefreshSource( null )
//       }
    },
    shiftClickedItem() {
      let firstIndex = this.images.findIndex( image => image.id == this.lastSelectedItem )
      let lastIndex = this.images.findIndex( image => image.id == this.shiftClickedItem )

      let start = firstIndex < lastIndex ? firstIndex : lastIndex
      let end = firstIndex > lastIndex ? firstIndex : lastIndex

      this.images.forEach( ( image, index ) => {
        if ( index >= start && index <= end ) {
          this.selectItemsWithMeta( {itemID: image.id, bypassUnselect: true} )
        }
      } )
    }
  },
  created() {
    let self = this
    self.selectedTreenodeID = null
    self.refreshTree( () => {
      if ( self.tree[0] ) {
        self.selectedTreenodeID = self.tree[0].id
        self.atSelection( self.selectedTreenodeID )
        self.setUploadID( self.selectedTreenodeID )
        self.setUploadType( self.entities.IMAGELIBRARY )
      }
    } )
  },
  methods: {
    ...mapActions( 'tagModule', ['searchContentTags', 'addTaggs'] ),
    ...mapActions( 'treeModule', ['getTree', 'setSecondkey', 'selectItemsWithMeta', 'setRefreshSource', 'addTreenode', 'getNodeContent', 'removeItem', 'removeItems'] ),
    ...mapActions( 'uploadModule', ['showUploader', 'setMultiUpload', 'setUploadType', 'setUploadID'] ),
    refreshTree( callback ) {
      let self = this
      self.getTree( this.entities.IMAGE_MANAGER )
          .then( result => {
            self.tree = result.data
            if ( typeof (callback) == 'function' ) callback()
          } )
    },
    tagsClicked() {
      this.images.forEach( image => {
        image.showTags = false
      } )
    },
    selectTag( selectedTags, type ) {
      switch ( type ) {
        case 'SEARCH':
          this.selectedTags = selectedTags
          this.getImageTags()
          break
      }
    },
    buttonAction( selectedTags, type ) {
      console.log( type );
      switch ( type ) {
        case 'SEARCH':
          this.removeSelectedimages();
          break
        case 'ADDTAGS':
          this.addTheTaggs( selectedTags );
          break
      }

    },
    atSelection( treenodeID ) {
      console.log( 'inside atSelection' );
      let self = this
      self.selectedTreenodeID = treenodeID
      self.setUploadID( treenodeID )

      self.refreshImages()
    },
    refreshImages(){
      let self = this
      console.log( self.selectedTreenodeID );
      let payload = {}
      payload.languageCode = self.languageCode
      payload.treenodeID = self.selectedTreenodeID

      self.getNodeContent( payload )
          .then( result => {
            self.images = result.data
          } )
    },
    getImageTags() {
      let self = this
      if ( this.selectedTags.length == 0 ) {
        this.atSelection(self.selectedTreenodeID)
      } else {
        let payload = {}
        payload.tags = this.selectedTags
        payload.languageCode = this.languageCode
        payload.searchType = 'IMAGES'
        self.searchTag = ''
        this.searchContentTags( payload )
            .then( function ( result ) {
              self.images = result.data
            } )
      }
    },
    addTheTreenode() {
      let payload = new FormData()
      payload.parentID = 0
      payload.treeType = this.entities.IMAGE_MANAGER
      payload.languageCode = this.languageCode

      let formData = new FormData();
      formData.append( 'formData', JSON.stringify( payload ) )

      this.addTreenode( formData )
          .then( () => {
            self.refreshTree()
          } )
    },
    uploadFinished() {
      this.atSelection( this.selectedTreenodeID )
    },
    removeSelectedimages() {
      let self = this

      Swal.fire( {
        title            : 'Are you sure you want to delete these imagesd',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return
        self.removeItems( btoa( self.selectedItems ) )
            .then( () => {
//               self.setRefreshSource( router.currentRoute.value.name )
              self.refreshImages()
            } )
      } )
    },
    addTheTaggs( selectedTags ) {
      let self = this
      let payload = {}
      payload.selectedTags = selectedTags
      payload.selectedItems = self.selectedItems

      console.log( self.selectedTreenodeID );
      self.addTaggs( payload ).then( () => {
        self.getImageTags()
        self.atSelection(self.selectedTreenodeID)
      } )
    }
  }
}
</script>

<style scoped>
.image-browser {
  padding: 15px;
  width: 100%
}
</style>