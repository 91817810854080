/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import translatorMutations from './translatorMutations.js';
import translatorActions from './translatorActions.js';
import translatorGetters from './translatorGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            showTranslator: false,
            toTranslate   : '',
            identifierID  : null,
            translations  : []
        }
    },
    mutations : translatorMutations,
    actions   : translatorActions,
    getters   : translatorGetters
};


