/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import treeMutations from './treeMutations.js';
import treeActions from './treeActions.js';
import treeGetters from './treeGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            treenodes            : [],
            selectedTreenodeID   : null,
            articleText          : '',
            sourceNodeID         : null,
            selectedItems        : [],
            lastSelectedItem     : false,
            shiftClickedItem     : false,
            selectedArticlenodeID: null
        }
    },
    mutations    : treeMutations,
    actions      : treeActions,
    getters      : treeGetters,
    refreshSource: null

};


