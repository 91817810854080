<template>
  <li
      class="list-group-item list-group-item-primary pointer"
      :class="{'selected-list-item': organization.id == selectedOrganization.id }">
    <span
        v-if="organization.childorganizations.length > 0"
        @click="toggleSelectedOrganization(organization)">
      <i
          class="fas fa-chevron-down pointer"
          v-if="showChildren"
          style="position:relative;left:-10px;font-size:1.5em"></i>
      <i
          class="fas fa-chevron-right pointer"
          v-else
          style="position:relative;left:-10px;font-size:1.5em"></i>
    </span>
    <span v-else>
      <i
          class="fas fa-ellipsis-h"
          style="position:relative;left:-10px;font-size:1.5em"></i>
    </span>
    <span @click="selectOrganization(organization)">
      {{ organization.name }} ( {{ organization.country }} )
    </span>
    <i class="fas fa-plus-circle float-right dark-blueish"
       @click="showNewOrganization(organization)"
       style="margin-left:10px;"></i>
    <i
        class="far fa-trash-alt redish float-right"
        @click="removeTheOrganization(organization)"
        v-if="organization.childorganizations.length == 0 && organization.users.length == 0"></i>
    <i
        class="fas fa-users dark-blueish float-right"
        @click="showUsers = ! showUsers"
        v-else-if="organization.users.length != 0"></i>
    <i class="fas fa-user-plus float-right greenish"
       style="margin-right:10px;"
       @click="showNewUser(organization)"></i>
    <ul v-if="showUsers"
        style="padding:0; margin-top:10px">
      <userlistitem
          :userList="organization.users"
          v-if="showUsers"></userlistitem>
    </ul>
    <ul style="padding:0; margin-top:10px">
      <organizationlistitem
          v-if="showChildren"
          v-for="organization in organization.childorganizations"
          :organization="organization">
      </organizationlistitem>
    </ul>
  </li>
  <neworganization></neworganization>
  <newuser></newuser>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Organizationlistitem from "@/components/organizations/Organizationlistitem"
import Userlistitem from "@/components/users/Userlistitem"
import Neworganization from "@/components/organizations/Neworganization"
import Newuser from "@/components/users/Newuser"

export default {
  name      : "Organizationlistitem",
  data() {
    return {
      showChildren: false,
      showUsers   : false
    }
  },
  components: {
    Organizationlistitem,
    Neworganization,
    Userlistitem,
    Newuser
  },
  props     : ['organization'],
  computed  : {
    ...mapGetters('organization', ['selectedOrganization']),
    ...mapGetters('translate', ['countries', 'translations'])
  },
  created() {
  },
  methods   : {
    ...mapActions('organization', [
      'setSelectedOrganization',
      'setEditOrganization',
      'setOrganziationID',
      'resetOrganization',
      'removeOrganization'
    ]),
    ...mapActions('user', ['getOrganizationUsers', 'resetUser']),
    selectOrganization(organization) {
      this.setSelectedOrganization(organization)
      this.setEditOrganization(true);
    },
    toggleSelectedOrganization(organization) {
      this.showChildren = !this.showChildren
      this.selectOrganization(organization);
    },
    setShowUsers() {
      this.showUsers = true
    },
    showNewOrganization(organization) {
      this.resetOrganization();
      this.resetUser();
      this.setOrganziationID(organization.id)
      $('#new-organization').modal('show')
    },
    showNewUser(organization) {
      this.resetOrganization();
      this.setOrganziationID(organization.id)
      this.resetUser()
      $('#new-user').modal('show')
    },
    removeTheOrganization(organization) {
      const self = this
      console.log('inside remove');
      this.removeOrganization(organization.id)
          .then(function () {
            let message = ra.findTranslation(self.translations, 'ORGANIZATIONREMOVED')
            ra.toasterMessage(message, 'success')
          }).catch(function () {
        let message = ra.findTranslation(self.translations, 'ORGANIZATIONREMOVEERROR')
        ra.toasterMessage(message, 'danger')
      });
    }
  }
}
</script>

<style scoped>
.list-group-item-primary:hover {
  color: #000;
}
</style>