<template>
  <div style="margin-top:10px;">
    <label>
      <translation :t="'LABELFORGOAL'"></translation>
      {{ goal.goalorder }}
    </label>
    <div style="width:92%;float:left">
      <input-translation
          :identifierID="goal.translation_id"
          :toTranslate="goal.translation"
          @saveTranslation="saveGoallabel"></input-translation>
    </div>
    <div style="border:1px solid #ebebeb;padding:3px 9px 4px 9px; float:left">
      <i class="fa fa-trash redish parent-popover pointer"
         @click="removeTheGoal">
        <tooltip :tip="'REMOVE'" :placement="'LEFT'"></tooltip>
      </i>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {api} from "@/plugins/axios";
import Translation from "@/components/controls/Translation";
import InputTranslation from "@/components/controls/InputTranslation"

export default {
  name : "Goal",
  emits: ['removeGoal'],
  props: ['goal'],
  data() {
    return {}
  },
  components: {InputTranslation, Translation},
  computed  : {
    ...mapGetters( ['languageCode'] ),
  },
  methods   : {
    saveGoallabel( goalLabel ) {
      this.goal.translation = goalLabel
      let formData = new FormData
      formData.append( 'formData', JSON.stringify( this.goal ) )
      api.post( '/api/formpagelement/saveGoallabel/' + this.languageCode, formData )
    },
    removeTheGoal( goal ) {
      this.$emit( 'removeGoal', goal )
    }
  }
}
</script>

<style scoped>

</style>