<template>
  <!-- label for -->
  <div class="row">
    <div class="col-12">
      <label class="">
        <translation :t="'LABELFORGOALGENERATOR'"></translation>
      </label>
      <input-translation
          :identifierID="metaData.label.translated"
          :toTranslate="metaData.label.value"
          @saveTranslation="savelabel"></input-translation>
    </div>
  </div>
  <hr/>
  <!-- end label for -->
  <div class="row" v-for="goal in metaData.goalgenerator.value">
    <div class="col-12">
      <goal :goal="goal" @removeGoal="removeGoal(goal)"></goal>
    </div>
  </div>

  <hr/>
  <div class="row">
    <div class="col-12">
      <button class="btn btn-success btn-small"
              @click="addGoal">
        <translation :t="'ADDGOAL'"></translation>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"
import draggable from 'vuedraggable'
import Goal from '@/components/forms/formelements/Goal.vue'
import {api} from "@/plugins/axios"
import InputTranslation from "@/components/controls/InputTranslation"
import Swal from 'sweetalert2'

export default {
  name : "Goalgenerator",
  props: ['metaData', 'formelementID'],
  emits: ['saveMetaData', 'showTranslator'],
  data() {
    return {
      timout: null
    }
  },
  components: {InputTranslation, Translation, draggable, Goal},
  computed  : {
    ...mapGetters( ['languageCode'] ),
  },
  methods   : {
    addGoal() {
      api.get( '/api/formpagelement/addGoal/' + this.formelementID + '/' + this.languageCode )
          .then( result => {
            this.metaData.goalgenerator.value = result.data
          } )
    },
    savelabel( translation ) {
      this.metaData.label.value = translation
      this.$emit( 'saveMetaData', this.metaData )
    },
    removeGoal( goal ) {
      let self = this

      Swal.fire( {
        title            : 'The goal will be removed, continue?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return
        api.get( '/api/formpages/removeGoal/' + self.formelementID + '/' + goal.translation_id )
            .then( () => {
              let index = self.metaData.goalgenerator.value.findIndex( g => {
                 return goal.translation_id == g.translation_id
              } )
              if (index !== -1) self.metaData.goalgenerator.value.splice(index, 1);

              ra.toasterMessage( 'goal successfully removed', 'success' )
            } )
      } )
    }
  },
}
</script>

<style scoped>

</style>