<template>
  <ul class="tree" :class="{'left-tree-border': tree.length > 0}">
    <span v-for="node in tree">
      <treenode :node="node"
                :treeType="treeType"
                :withID="withID ? withID : false"
                :hideIcons="hideIcons"
                :selectedTreenodeID="selectedTreenodeID"
                @atSelection="atSelection"
                @refreshTree="refreshTree"></treenode>
      <tree v-if="node.showchildren"
            @refreshTree="refreshTree"
            @atSelection="atSelection"
            :tree="node.allchildren"
            :withID="withID ? withID : false"
            :treeType="treeType"
            :hideIcons="hideIcons"></tree>
    </span>
  </ul>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Treenode from "@/components/cms/Treenode";

export default {
  name : "Tree",
  props: ['tree', 'treeType', 'withID', 'hideIcons'],
  emits: ['refreshTree', 'atSelection'],
  data() {
    return {
      selectedTreenodeID : null
    }
  },
  components: {Treenode},
  computed  : {},
  watch     : {
    tree(val){
      val.forEach(node=>{
        let showChildren = localStorage.getItem( 'showChildren' )
        showChildren = showChildren ? JSON.parse( showChildren ) : []
        let itShow = showChildren.find( child => {
          return child == node.id
        } )
        if ( itShow ) node.showchildren = 1;
      })
    }
  },
  created() {
  },
  methods: {
    refreshTree() {
      this.$emit( 'refreshTree' )
    },
    atSelection(nodeID){
      this.$emit('atSelection', nodeID)

      console.log( 'indside Tree',nodeID );
      this.selectedTreenodeID = nodeID
    }
  }
}
</script>

<style scoped>
</style>