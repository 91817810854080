/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setCategories( state, payload ) {
        state.categories = payload
    },
    setSelectedCategoryID( state, payload ) {
        state.selectedCategoryID = payload
    },
    setFollowup( state, payload ) {
        state.followup = payload
    }
}