<template>
  <div class="milestone-categories"
       v-if="activeMilestoneAttribute">
    <div class="card">
      <div class="card-header">
        <button class="btn btn-secondary btn-sm"
                style="float:right"
                @click="addMilestonecategory">
          <translation :t="'ADDCATEGORY'"></translation>
        </button>
      </div>
      <div class="card-body">
        <draggable v-model="activeMilestoneAttribute.milestonecategories"
                   v-if="activeMilestoneAttribute.milestonecategories.length > 0"
                   ghost-class="ghost"
                   tag="transition-group"
                   @end="reorderMilestonecategories"
                   :component-data="{name: 'flip-list', type: 'transition'}"
                   item-key="id">
          <template #item="{element}">
            <div class="form-page-block">
              <milestone-category
                  @setActivecategory="setActivecategory"
                  :milestoneCategory="element"></milestone-category>
            </div>
          </template>
        </draggable>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import MilestoneCategory from "./MilestoneCategory"
import draggable from "vuedraggable"

export default {
  name : "MilestoneCategories",
  props: ['milestonecategories'],
  emits: [],
  data() {
    return {
      activeMilestoneAttribute: null
    }
  },
  components: {MilestoneCategory, draggable},
  computed  : {
    ...mapState( 'milestoneModule', ['milestoneForm'] ),
  },
  created() {
  },
  watch  : {
    milestoneForm: {
      handler( val ) {
        this.activeMilestoneAttribute = null
        this.milestoneForm.milestonedomains.forEach( m => {
          if ( m.active ) {
            return m.milestonedomainattributes.forEach( ma => {
              if ( ma.active ) {
                this.activeMilestoneAttribute = ma
              }
            } )
          }
        } )
      },
      deep: true
    },
  },
  methods: {
    ...mapActions( 'milestoneModule', ['newMilestonecategory', 'saveMilestoneCategoryOrder'] ),
    addMilestonecategory() {
      this.newMilestonecategory( this.activeMilestoneAttribute.id )
          .then( result => {
            this.activeMilestoneAttribute.milestonecategories.push( result.data )
          } )
    },
    reorderMilestonecategories() {
      let order = 0;
      let categories = []
      this.activeMilestoneAttribute.milestonecategories.forEach( mc => {
        order += 10
        categories.push( {id: mc.id, order: order} )
      } )
      this.saveMilestoneCategoryOrder(categories)
    },
    setActivecategory( milestoneCategoryID ) {
      this.activeMilestoneAttribute.milestonecategories.forEach( mc => {
        mc.active = mc.id == milestoneCategoryID
      } )
    }
  }
}
</script>

<style scoped>
</style>