/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router";

export default {

    getForms( context, formType) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/forms/getforms/' + languageCode + '/' + formType )
            .then( ( result ) => {
                context.commit( 'setForms', result.data )
            } )
    },
    getForm( context, payload ) {
        return api.get( '/api/forms/getform/' + payload.languageCode + '/' + payload.formID )
    },
    selectForm( context, formID ) {
        return context.commit( 'selectForm', formID )
    },
    resetForm( context ) {
        context.commit( 'resetForm' );
    },
    toggleShowEditFormpage( context ) {
        if ( !context.rootState.formModule.selectedForm.id ) return
        if ( !context.rootState.formpageModule.formpageID ) return
        context.commit( 'toggleShowEditFormpage' )
    },
    updateFormname( context, formname ) {
        context.commit( 'updateFormname', formname )
    },
    saveForm( context, payload ) {
        const formData = new FormData;
        formData.append( 'form', JSON.stringify( payload ) )
        return api.post( '/api/forms/saveform', formData )
    },
    removeForm( context, formID, formType ) {
        return api.get( '/api/form/removeForm/' + formID )
    }

}