<template>
  <div v-if="infoText" class="info-text-holder">
    <span class="info-text fa fa-info-circle info-color"
          @click="showInfotext"></span>
  </div>
  <div>
    <label>{{ metaData.label.value }}</label>
  </div>

  <span v-if="metaData.itemtype.value == 'RANGE'">
    <span v-for="index in Number(metaData.items.value)" :key="index">

      <span v-if="metaData.itemStyle.value == 'ROW'"
            class="range-holder">
        <i class="far fa-circle"></i>
        <br/>
        <label>{{ index }}</label>
      </span>

      <div v-else
           v-for="index in Number(metaData.items.value)" :key="index">
        <i class="far fa-circle"></i>
        <label style="margin-left:10px;">{{ index }}</label>
      </div>

    </span>
  </span>

  <span v-if="metaData.itemtype.value == 'LADDER'">
    <div class="stair"
         v-for="stair in stairs">
      <hr>
      <span class="stair-indicator">
        <translation :t="'WORST'" v-if="stair==1"></translation>
      </span>
      <span class="stair-indicator">
        <translation :t="'BEST'" v-if="stair==metaData.items.value"></translation>
      </span>
      <div class="stair-level">
        <span>{{ stair }}</span>
      </div>
    </div>
    <div class="down-the-stairs"></div>
    <hr/>
  </span>
  <span v-if="metaData.itemtype.value == 'CATEGORY' && category">
    <div v-for="item in category.categoryitems"
         v-if="metaData.itemStyle.value == 'ROW'"
         class="category-holder">

      <span>
        <span v-if="item.imagepath"
              class="category-item">
          <img :src="item.imagepath"  class="category-image" />
        </span>
        <br/>
        <p style="text-align:center;width:100%;margin-bottom: 0">
          {{ item.translation ? item.translation.translation : 'NB' }}
        </p>
        <div style="width:100%; text-align:center;">
          <i class="far fa-circle"></i>
        </div>
      </span>
    </div>
    <div v-for="item in category.categoryitems"
         class="category-holder-column"
         v-else>
      <i class="far fa-circle"></i>
      <span v-if="item.imagepath"
            class="category-item">
        <img :src="item.imagepath" class="category-image" />
      </span>
      {{ item.translation ? item.translation.translation : 'NB' }}
    </div>
  </span>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "RadiogroupPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] ),
    category() {
      return this.categories.find( category => {
        return category.id == this.metaData.categoryID.value
      } )
    },
    stairs() {
      let stairs = []
      for ( let i = this.metaData.items.value; i > 0; i-- ) {
        stairs.push( i )
      }
      return stairs
    }
  },
  methods   : {
    showInfotext() {
    }
  }
}
</script>

<style scoped>
.range-holder {
  float: left;
  padding: 0 8px;
  text-align: center;
}

.category-holder {
  float: left;
  padding: 0 10px;
}

.category-holder-column {
  display:block;
  padding: 0 10px;
}

.category-image {
  width: 40px;
  margin-bottom:15px
}

.stair {
  position: relative;
  height: 50px;
  border-left: 4px solid #bf0710;
  border-right: 4px solid #bf0710;
  width: 100px;
  cursor: pointer;
  margin-left: 15px;
}

.stair hr {
  float: left;
  margin-top: 10px;
  width: 100%;
  height: 6px;
  background-color: #bf0710;
}

.stair.cleared {
  border-color: #01800d;
}

.stair.cleared > hr, .stair-level.cleared {
  background-color: #01800d;
}

.stair:hover {
  border-color: #024959;
}

.stair:hover hr, .stair-level:hover {
  background-color: #024959;
}

.down-the-stairs {
  background-color: #024959;
  width: 130px;
  margin-top: 0px;
  cursor: pointer;
  height: 5px;
}

.stair-level {
  position: absolute;
  right: -35px;
  top: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: #f03d1b;
  cursor: pointer;
}

.stair-level > span {
  position: relative;
  top: 3px;
  color: #fff;
  font-weight: bold;
}

.stair-indicator {
  width: 100%;
  text-align: center;
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  color: #666;
  position: relative;
  top: -10px;
}
</style>