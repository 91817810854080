<template>
<h1>no access to Rehapp</h1>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "Nopage",
  props: [],
  emits: [],
  data() {
    return {}
  },
  components: {},
  computed  : {},
  created() {
  },
  methods: {}
}
</script>

<style scoped>

</style>