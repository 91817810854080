/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '@/plugins/axios'

export default {

    toggleArticlesSelector(context){
        context.commit('toggleArticlesSelector')
    },
    getModalPictures( context, pictureType ) {
        return api.get( '/api/images/getModalImages/' + pictureType )
            .then( ( result ) => {
                context.commit('setModalpictures', result.data)
            } )
    },
    getImagesByChapter( context, chapter ) {
        return api.get( '/api/images/getImagesByChapter/' + chapter)
            .then( ( result ) => {
                context.commit('setModalpictures', result.data)
            } )
    },
    setPageTypeID(context, pageTypeID){
        context.commit('setPageTypeID', pageTypeID)
    },
    saveApplicationpageRegionpicture(conext, payload){
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post('/api/application/saveApplicationpageRegionpicture', formData)
    }

}