/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import translationMutations from './translationMutations.js';
import translationActions from './translationActions.js';
import translationGetters from './translationGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            translations   : [],
            countries      : [],
            languages      : [],
            allTranslations: []
        }
    },
    mutations : translationMutations,
    actions   : translationActions,
    getters   : translationGetters
};


