<template>
  <li :class="{'left-tree-border': node.allchildren.length > 0,
                'selected-text': node.id == selectedTreenodeID,
                'selected-list-item-light': node.id == selectedTreenodeID,
                'highlight': draggedEntered}"
      v-cloak
      @dragenter.prevent.stop="dragIn"
      @dragover.prevent.stop="dragIn"
      @dragleave.prevent.stop="dragOut"
      @dragstart="startDrag(node)"
      @drop.prevent="handleNode(node)">
    <div style="witdh:5%; float:left;">
      <i class="far fa-square fa-15x fa-special pointer"
         v-if="node.allchildren.length == 0"
         style="margin-right:10px;"></i>
      <i class="far fa-plus-square fa-15x fa-special pointer"
         style="margin-right:10px;"
         v-else-if="node.showchildren == 0"
         @click.stop="toggleNode"></i>
      <i class="far fa-minus-square fa-15x fa-special pointer"
         v-else
         style="margin-right:10px;"
         @click.stop="toggleNode"></i>
      <label class="tree-connector"></label>
    </div>
    <span v-if="inEdit">
      <div style="width:100%">
        <input-translation
            style="width:95%;position: relative;top:-5px"
            :saveOnEnter="true"
            :identifierID="node.translation_id"
            :toTranslate="treenodeName"
            :deleteID="node.allchildren.length == 0 ? node.id : false"
            @removeAction="removeTheTreenode"
            @saveTranslation="saveTheTreenodeName"></input-translation>
      </div>
    </span>
    <span v-else>
      <label @click.stop="selectTreenode"
             draggable="true"
             class="pointer hover-effect-darker">
        <span v-if="withID" class="id-marker">
          #{{ nodeID }}
        </span>
        {{ treenodeName }}
      </label>
      <!--    <i class="fa fa-edit blueish hover-effect right-pull"-->
      <!--       style="margin-right:10px;"-->
      <!--       @click="inEdit = true"></i>-->
      <span v-if="! hideIcons">
        <i class="fa fa-trash redish right-pull hover-effect"
           style="margin-right:15px;"
           v-if="allowRemoveNode"
           @click.stop="removeTheTreenode"></i>
        <i class="fa fa-trash greyish right-pull hover-effect"
           style="margin-right:15px;"
           v-else></i>
        <i class="fa fa-folder-plus dark-greenish right-pull hover-effect"
           style="margin-right:10px;font-size:1.3em;margin-top:-2px;"
           @click.stop="addTheTreenode"></i>
        <i class="icofont-bucket right-pull dark-blueish pointer"
           style="margin-right:10px;font-size:1.3em;margin-top:-2px"
           @click.stop="dropClickItems"
           v-if="selectedItems.length > 0"></i>
      </span>
    </span>
    <div class="clearfix"></div>
  </li>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Tree from "@/components/cms/Tree"
import InputTranslation from "@/components/controls/InputTranslation"
import Swal from "sweetalert2"

export default {
  name : "Treenode",
  props: ['node', 'treeType', 'withID', 'hideIcons', 'selectedTreenodeID'],
  emits: ['refreshTree', 'atSelection'],
  data() {
    return {
      nodeID        : null,
      treenodeName  : '',
      inEdit        : false,
      showchildren  : false,
      myChild       : 1,
      draggedEntered: false,
      counter       : 0,
      timer         : null,
      selectedNodeID: null,
    }
  },
  components: {Tree, InputTranslation},
  computed  : {
    ...mapGetters( ['languageCode', 'entities'] ),
    ...mapGetters( 'treeModule', ['sourceNodeID', 'selectedItems'] ),
    allowRemoveNode() {
      if ( this.node.allchildren.length > 0 ) return false
      if ( this.node.images && this.node.images[0] && this.node.images[0].images > 0 ) return false

      return true;
    }
  },
  watch     : {},
  created() {
    let self = this;
    self.myChild = this.node.showchildren
    if ( !self.node.translation || !self.node.translation.translation ) {
      self.treenodeName = ''
    } else {
      self.treenodeName = self.node.translation.translation
    }
    self.inEdit = self.treenodeName.length == 0
    self.nodeID = self.node.id

    let showChildren = localStorage.getItem( 'showChildren' )
    showChildren = showChildren ? JSON.parse( showChildren ) : []
    let itShow = showChildren.find( child => {
      return child == self.node.id
    } )
    if ( itShow ) self.node.showchildren = 1;

    this.clearSelection()
  },
  methods: {
    ...mapActions( 'treeModule', ['getTree', 'clearSelection', 'setSourceNodeID', 'clickDropItems', 'switchNodes', 'saveTreenodeName', 'addTreenode', 'removeTreenode'] ),
    ...mapActions( 'editorModule', ['getTranslatedText'] ),
    selectTreenode() {
      let self = this
      self.counter++;

//       if( ! self.hideIcons ) self.setSelectedTreenodeID( self.node.id )
      if ( self.counter == 1 ) {
        self.timer = setTimeout( () => {
          self.counter = 0
          console.log( self.selectedNodeID, self.node.id );
          if(self.selectedNodeID == self.node.id ){
            self.$emit( 'atSelection', null )
            self.selectedNodeID = null
          }else{
            self.$emit( 'atSelection', self.node.id )
            self.selectedNodeID = self.node.id
          }

        }, 200 );
        return;
      }

      clearTimeout( this.timer );
      self.counter = 0;
      self.inEdit = true
    },
    startDrag( node ) {
      if ( this.hideIcons ) return
      this.setSourceNodeID( node.id )
    },
    handleNode( node ) {
      if ( this.hideIcons ) return
      let self = this
      let payload = {}
      payload.sourceID = this.sourceNodeID;
      payload.targetID = node.id
      self.draggedEntered = false
      self.switchNodes( payload )
          .then( () => {
            setTimeout( () => {
              self.$emit( 'refreshTree' )
            }, 1000 )

          } )
    },
    dragIn() {
      if ( this.hideIcons ) return
      this.draggedEntered = true
    },
    dragOut() {
      if ( this.hideIcons ) return
      this.draggedEntered = false
    },
    addTheTreenode() {
      if ( this.hideIcons ) return
      let self = this
      let payload = {}
      payload.parentID = self.node.id
      payload.treeType = self.treeType
      payload.languageCode = self.languageCode

      let formData = new FormData();
      formData.append( 'formData', JSON.stringify( payload ) )

      this.addTreenode( formData )
          .then( () => {
            self.$emit( 'refreshTree' )
          } )
    },
    dropClickItems() {
      if ( this.hideIcons ) return
      this.clickDropItems( this.node.id )
    },
    saveTheTreenodeName( nodeName ) {
      if ( this.hideIcons ) return
      let self = this
      self.treenodeName = nodeName
      if ( self.treenodeName / length == 0 ) return;

      let payload = {}
      payload.languageCode = self.languageCode
      payload.treenodeName = self.treenodeName
      payload.translationID = self.node.translation_id

      let formData = new FormData();
      formData.append( 'formData', JSON.stringify( payload ) )

      this.saveTreenodeName( formData )
          .then( () => {
            let payload = {}
            payload.nodeID = self.node.id
            payload.treenodeName = self.treenodeName
            self.inEdit = false
            ra.toasterMessage( 'name saved', 'success' )
          } )
    },
    removeTheTreenode() {
      if ( this.hideIcons ) return
      let self = this

      Swal.fire( {
        title            : 'are you sure you want to delete the branche, related content will be delated aswell',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return

        let payload = {}
        payload.treenodeID = self.node.id

        let formData = new FormData();
        formData.append( 'formData', JSON.stringify( payload ) )
        self.removeTreenode( self.node.id )
            .then( () => {
              self.$emit( 'refreshTree' )
            } )
      } )
    },
    toggleNode() {
      if ( this.hideIcons ) return
      let showChildren = localStorage.getItem( 'showChildren' )
      showChildren = showChildren ? JSON.parse( showChildren ) : []

      if ( this.node.showchildren == 0 ) {
        this.node.showchildren = 1
        if ( showChildren.indexOf( this.node.id ) == -1 ) showChildren.push( this.node.id )
      } else {
        this.node.showchildren = 0
        let sci = showChildren.indexOf( this.node.id );
        showChildren.splice( sci, 1 );
      }
      localStorage.setItem( 'showChildren', JSON.stringify( showChildren ) )
    }
  }
}
</script>

<style scoped>
.highlight {
  background-color: #ebebeb;
}

.id-marker {
  background-color: #6700a9;
  color: #fff;
  padding: 2px 5px;
  font-size: .8em;
  height: 15px;
  border-radius: 50%;
}
</style>