/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    upoloadType(state){
        return state.upoloadType
    },
    uploadID(state){
        return state.uploadID
    },
    selectedFile(state){
        return state.selectedFile
    },
    multiUpload(state){
        return state.multiUpload
    },
    showUpload(state){
        return state.showUpload
    }
}
