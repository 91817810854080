<template>
  <form>
    <div class="form-group">
      <validated-input
          v-model="selectedOrganization.name"
          :validations="'required'"
          :placeholder="'NAME'"
          :errorMessage="'VALIDORGANIZATIONNAME'"
          :labelCaption="'ORGANIZATIONNAME'"></validated-input>
    </div>
    <div class="form-group">
      <validated-input
          v-model="selectedOrganization.address"
          :placeholder="'ADDRESS'"
          :labelCaption="'ADDRESS'"></validated-input>
    </div>
    <div class="form-group">
      <validated-input
          v-model="selectedOrganization.place"
          :placeholder="'PLACE'"
          :labelCaption="'PLACE'"></validated-input>
    </div>
    <div class="form-group">
      <label>
        <translation :t="'COUNTRY'"></translation>
      </label>
      <select
          class="form-control"
          v-model="selectedOrganization.country_id">
        <option
            v-for="country in countries"
            :value="country.id">
          {{ country.translation }}
        </option>
      </select>
    </div>
    &nbsp;&nbsp;
    <hr/>
    <div class="form-group" v-if="mode != 'NEW'">
      <button
          type="submit"
          :disabled="invalidForm"
          @click.prevent="saveTheOrganization"
          class="btn btn-primary">
        <translation :t="'SAVE'"></translation>
      </button>
      <button
          type="button"
          @click="resetOrganization(null)"
          class="btn btn-danger">
        <translation :t="'CANCEL'"></translation>
      </button>
    </div>
  </form>
</template>

<script>
import ValidatedInput from "@/components/controls/ValidatedInput";
import CheckboxInput from "@/components/controls/CheckboxInput";
import {mapActions, mapGetters} from 'vuex'
import Translation from "@/components/controls/Translation";

export default {
  components: {Translation, ValidatedInput, CheckboxInput},
  data() {
    return {}
  },
  props: ['mode'],
  computed: {
    ...mapGetters('translate', ['countries', 'translations']),
    ...mapGetters('organization', ['selectedOrganization', 'organizationID', 'organizations']),
    invalidForm() {
      if (this.selectedOrganization.name.length == 0) return true
      return false
    }
  },
  methods: {
    ...mapActions('organization', [
      'saveOrganization',
      'resetOrganization',
      'setSelectedOrganization',
      'getOrganizations',
      'setOrganziationID']),
    saveTheOrganization() {
      const self = this
      const form = new FormData()

      form.append('name', this.selectedOrganization.name)
      form.append('address', this.selectedOrganization.address)
      form.append('place', this.selectedOrganization.place)
      form.append('country_id', this.selectedOrganization.country_id)

      form.append('id', this.selectedOrganization.id)
      form.append('organization_id', this.selectedOrganization.organization_id || this.organizationID)

      this.saveOrganization(form).then(function () {
        $('#new-organization').modal('hide')
        let translation = ra.findTranslation(self.translations, 'ORGANIZATIONSAVED')

        ra.toasterMessage(translation, 'success')
      })
    }
  }
}
</script>

<style scoped>

</style>