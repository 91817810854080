<template>
  <div v-if="infoText" class="info-text-holder">
    <span class="info-text fa fa-info-circle info-color"
          @click="showInfotext"></span>
  </div>
  <span v-if="metaData.itemtype.value=='SINGLE'" style="float:left;margin: 0 10px;">
    <i class="far fa-square"></i>
  </span>
  <div>
    <label>{{ metaData.label.value }}</label>
  </div>

  <span v-if="metaData.itemtype.value == 'CATEGORY' && category">
    <span v-for="item in category.categoryitems">

      <div class="category-holder" v-if="metaData.itemStyle.value == 'ROW'">
        <span v-if="item.imagepath"
              class="category-item">
          <img :src="item.imagepath"/>
        </span>
        <br/>
        <p style="text-align:center;width:100%;margin-bottom: 0">
          {{ item.translation ? item.translation.translation : 'NB' }}
        </p>
        <div style="width:100%; text-align:center;">
          <i class="far fa-square"></i>
        </div>
      </div>

      <div v-else style="padding-bottom:10px;">
        <i class="far fa-square"></i>
        <span v-if="item.imagepath"
              style="margin-left:10px;"
              class="category-item">
          <img :src="item.imagepath" width="40" />
        </span>
        <label style="margin-left:10px;">
          {{ item.translation ? item.translation.translation : 'NB' }}
        </label>
      </div>
    </span>

  </span>


</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "CheckboxPhone",
  props: ['metaData', 'infoText', 'elementID'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] ),
    category() {
      return this.categories.find( category => {
        return category.id == this.metaData.categoryID.value
      } )
    }
  },
  created() {

  },
  methods: {
    showInfotext() {
    }
  }
}
</script>

<style scoped>
.range-holder {
  float: left;
  padding: 0 8px;
  text-align: center;
}

.category-holder {
  float: left;
  padding: 0 10px;
}

.category-holder img {
  width: 35px;
}

.stair {
  position: relative;
  height: 50px;
  border-left: 4px solid #bf0710;
  border-right: 4px solid #bf0710;
  width: 100px;
  cursor: pointer;
  margin-left: 15px;
}

.stair hr {
  float: left;
  margin-top: 10px;
  width: 100%;
  height: 6px;
  background-color: #bf0710;
}

.stair.cleared {
  border-color: #01800d;
}

.stair.cleared > hr, .stair-level.cleared {
  background-color: #01800d;
}

.stair:hover {
  border-color: #024959;
}

.stair:hover hr, .stair-level:hover {
  background-color: #024959;
}

.down-the-stairs {
  background-color: #024959;
  width: 130px;
  margin-top: 0px;
  cursor: pointer;
  height: 5px;
}

.stair-level {
  position: absolute;
  right: -35px;
  top: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  background-color: #f03d1b;
  cursor: pointer;
}

.stair-level > span {
  position: relative;
  top: 3px;
  color: #fff;
  font-weight: bold;
}

.stair-indicator {
  width: 100%;
  text-align: center;
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  color: #666;
  position: relative;
  top: -10px;
}
</style>