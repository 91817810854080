/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    selectedUser(state){
        return state.selectedUser
    },
    users (state){
        return state.users
    },
    organizationUsers(state){
        return state.organizationUsers
    }
}
