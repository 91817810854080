<template>
  <div class="row"
       id="element-holder">
    <category-article v-if="showCategoryArticle"
                      :articles="linkedArticles"
                      @doClickAction="saveCycledomainTree"
                      @detachArticle="detachArticle"
                      @toggleCategoryArticle="toggleCategoryArticle"></category-article>
    <input type="text"
           class="form-control"
           v-if="editFormpagename"
           @keyup.enter="saveTheFormpagename"
           v-model="formpagename">
    <h3 v-else>
      <i class="fa fa-edit pointer"
         style="font-size:.6em;position: relative;left:20px;top:-10px;"
         @click="toggleEditFormname"></i>
      <label style="position: relative;left:35px;">
        {{ selectedFormpage.formpagename }}
      </label>
    </h3>
    <br>
  </div>
  <div class="row"
       style="margin-top:20px;">
    <div class="col-8">
      <button class="btn btn-success"
              @click="addDomain">
        <translation :t="'ADDDOMAIN'"></translation>
      </button>
    </div>
  </div>

  <div class="row"
       style="margin-top:20px;">
    <div class="col-6">
      <ul style="list-style: none;">
        <li v-for="cycledomain in cycledomains"
            style="padding:0;margin-bottom:10px;position:relative">
          <input-translation
              :identifierID="cycledomain.translation_id"
              :toTranslate="cycledomain.domainname"
              :nonEmitSave="true"
              :deleteID="cycledomain.id"
              @removeAction="removeTheCycledomain"></input-translation>
          <button class="btn btn-small btn-info btn-article"
                  @click="toggleCategoryArticle(cycledomain)">
            <i class="far fa-file-alt"></i>
          </button>
          <button class="btn btn-small btn-warning btn-popup"
                  @click="initFroala(cycledomain)">
            <i class="fa fa-info-circle" style="color:#fff"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import InputTranslation from "../../../controls/InputTranslation"
import CategoryArticle from "@/components/management/categories/CategoryArticle"

export default {
  name : "CycleformBuilder",
  props: [],
  emits: [],
  data() {
    return {
      cycledomains       : [],
      editFormpagename   : false,
      linkedArticles     : [],
      formpagename       : '',
      showCategoryArticle: false,
      selectedItem       : null
    }
  },
  components: {InputTranslation, CategoryArticle},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'formpageModule', ['selectedFormpage'] )
  },
  created() {
    this.refreshDomains()
  },
  watch  : {
    selectedFormpage() {
      this.refreshDomains()
    }
  },
  methods: {
    ...mapActions( 'editorModule', ['setTextTypeID', 'setTextType', 'showEditor', 'setShowEditor'] ),
    ...mapActions( 'formpageModule', [
      'getCycleformDomains',
      'getCycleformDomains',
      'getLinkedCycledomainArticles',
      'addCycledomain',
      'attachCycledomainTree',
      'detachCycledomainTree',
      'saveFormpagename',
      'removeCycledomain'] ),
    refreshDomains() {
      this.getCycleformDomains( this.selectedFormpage.id )
          .then( result => {
            this.cycledomains = []

            result.data.forEach( domain => {
              this.cycledomains.push( {
                id            : domain.id,
                formpage_id   : domain.formpage_id,
                translation_id: domain.translation_id,
                domainname    : domain.translation ? domain.translation.translation : '',
                trees         : domain.trees
              } )
            } )
          } )
    },
    toggleEditFormname() {
      this.editFormpagename = true
      this.formpagename = this.selectedFormpage.formpagename
    },
    toggleCategoryArticle( item ) {
      this.selectedItem = item
      this.showCategoryArticle = !this.showCategoryArticle
      this.getTheLinkedCycledomainArticles()
    },
    addDomain() {
      let payload = {}
      payload.formpageID = this.selectedFormpage.id
      payload.domainname = ''

      this.addCycledomain( payload )
          .then( result => {
            this.cycledomains.push( {
              id            : result.data.id,
              formpage_id   : result.data.formpage_id,
              translation_id: result.data.translation_id,
              domainname    : ''
            } )
          } )
    },
    getTheLinkedCycledomainArticles() {
      if( ! this.selectedItem ) return
      this.getLinkedCycledomainArticles(this.selectedItem.id)
          .then( result => {
            this.linkedArticles = result.data
          } )
    },
    saveCycledomainTree( node ) {
      let payload = {}
      payload.cycledomainID = this.selectedItem.id
      payload.treeID = node.id
      console.log( 'inside saveCycledomainTree' );
      this.attachCycledomainTree( payload )
          .then( _ => {
            this.getTheLinkedCycledomainArticles()
          } )
    },
    detachArticle( articleID ) {
      let payload = {}
      payload.cycledomainID = this.selectedItem.id
      payload.treeID = articleID

      this.detachCycledomainTree( payload )
          .then( () => {
            this.getTheLinkedCycledomainArticles()
          } )
    },
    removeTheCycledomain( cycledomainID ) {
      this.removeCycledomain( cycledomainID )
          .then( () => {
            let index = this.cycledomains.findIndex( cycledomain => {
              return cycledomain.id == cycledomainID
            } )
            this.cycledomains.splice( index, 1 )
          } )
    },
    saveTheFormpagename() {
      let payload = {}
      payload.id = this.selectedFormpage.id
      payload.languageCode = this.languageCode
      payload.formpagename = this.formpagename
      payload.languageCode = this.languageCode

      this.saveFormpagename( payload )
      this.editFormpagename = false
    },
    initFroala( cycledomain ) {
      this.setTextType( 'CYCLEDOMAININFO' )
      this.setTextTypeID( cycledomain.id )
      this.setShowEditor( true )
    }
  }
}
</script>

<style scoped>
.btn-article {
  position: absolute;
  top: 0;
  right: -31px;
  padding: 4px 10px 5px;
}
.btn-popup {
  position: absolute;
  top: 0;
  right: -71px;
  padding: 4px 10px 5px;
}
</style>