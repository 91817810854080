/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import editorMutations from './editorMutations.js';
import editorActions from './editorActions.js';
import editorGetters from './editorGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            showEditor   : false,
            editorText   : '',
            textType     : '',
            textTypeID   : null,
            editorRefresh: false,
            showRevisions: false,
            revisions    : []
        }
    },
    mutations: editorMutations,
    actions  : editorActions,
    getters  : editorGetters
};


