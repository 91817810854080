<template>
  <div class="side-panel side-panel-formpage"
       :class="showEditFormpage ? 'open-panel' : ''">
    <h3 class="side-button side-formpage-button"
        id="side-formpage-button"
        @click="toggleShowEditFormpage">
      <translation :t="'EDITFORMPAGE'"></translation>
    </h3>
    <div class="card bg-primary-light" style="width:1000px;height:100%">
      <div class="card-body">
        <div class="row">
          <div class="col-12"
                :class="{'col-sm-7':showFormElements}">
            <div style="height:100%;overflow:auto">
              <formpage-builder v-if="showFormElements"></formpage-builder>
              <goalform-builder v-if="selectedFormpage.formtype == 'GOAL'"></goalform-builder>
            </div>
          </div>
          <div class="col-12 col-sm-5" v-if="showFormElements">
            <elementtype-list></elementtype-list>
          </div>
        </div>
      </div>
    </div>
    <phone-example></phone-example>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import FormpageBuilder from '@/components/forms/formpages/builder/FormpageBuilder.vue'
import GoalformBuilder from '@/components/forms/formpages/builder/CycleformBuilder.vue'
import ElementtypeList from '@/components/forms/formpages/builder/ElementtypeList.vue'
import PhoneExample from "@/components/forms/formelements/phone/PhoneExample";

export default {
  name      : "EditFormpage",
  data() {
    return {
      inEdit      : false,
      formpagename: '',
      files       : []
    }
  },
  components: {
    PhoneExample,
    ElementtypeList,
    FormpageBuilder,
    GoalformBuilder
  },
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'formModule', ['showEditFormpage', 'selectedForm'] ),
    ...mapGetters( 'formpageModule', ['selectedFormpage'] ),
    showFormElements(){
      if( this.selectedFormpage.formtype == 'DEFAULT') return true
      if( this.selectedFormpage.formtype == 'CLIENTFORM') return true
      return false
    }
  },
  created() {
  },
  methods   : {
    ...mapActions( 'formModule', ['toggleShowEditFormpage'] ),
    ...mapActions( 'formpageModule', ['updateFormpagename', 'saveFormpage', 'getFormelements'] ),
    ...mapActions( 'translator', ['setToTranslate', 'getTranslations', 'setIdentifierID'] ),
    editFormpagename() {
      this.inEdit = true
      this.setToTranslate( this.selectedFormpage.formpagename )
      this.getTranslations( this.selectedFormpage.formpage_id )
      this.setIdentifierID( this.selectedFormpage.formpage_id )
    },
    saveTheFormpage() {
      let self = this
      self.inEdit = false

      if ( self.formpagename == self.selectedFormpage.formpagename ) return;

      self.updateFormpagename( self.formpagename )
      self.saveFormpage( {
        selectedFormpage: self.selectedFormpage,
        languageCode    : self.languageCode
      } ).then( () => {
        self.setToTranslate( self.formpagename )
        let message = ra.findTranslation( self.translations, 'FORMPAGESAVED' )
        ra.toasterMessage( message, 'success' )
      } )
    }
  }
}
</script>

<style scoped>
.side-panel {
}

.card {
  height: 100%
}

.i-super {
  font-size: 16px;
  position: relative;
  top: -10px;
  cursor: pointer;
  color: #999;
  left: 20px;
}

.i-super:hover {
  color: #222;
}
</style>