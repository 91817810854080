<template>
  <app-header :background="true"></app-header>
  <div v-if="metadata" class="blix-navigation-page">
    <h4 class="blix-start-leader pointer"
           @click.stop="editHeader = true">
      <input-translation
          style="width:200%"
          v-if="editHeader"
          :saveOnEnter="true"
          :identifierID="metadata.header.translation_id"
          :toTranslate="metadata.header.translation"
          @saveTranslation="saveLeader">
      </input-translation>
      <span v-else>
        {{ metadata.header.translation }}
      </span>
    </h4>
    <ul class="blix-navigation-list" >
      <li v-for="listitem in metadata.listitems">
        <action-button-default
            :buttonClass="'NAVIGATION_LIST'"
            :applicationpageID="applicationpageID"
            :button="listitem">
        </action-button-default>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import AppHeader from '@/components/phoneapp/templates/blix/partials/Appheader.vue'
import InputTranslation from "../../../../controls/InputTranslation"
import ActionButtonDefault from '@/components/phoneapp/templates/blix/controls/ActionButtonDefault.vue'

export default {
  name : "Navigation",
  props: ['applicationpageID'],
  emits: [],
  data() {
    return {
      metadata  : false,
      editHeader: false
    }
  },
  components: {AppHeader, InputTranslation, ActionButtonDefault},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] )
  },
  created() {
    this.setMetadata()
  },
  methods: {
    ...mapActions( 'applicationModule', ['getApplicationpageMetadata', 'saveMetadataPropertyValue'] ),
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    setMetadata() {
      let self = this
      self.getApplicationpageMetadata( self.applicationpageID )
          .then( ( result ) => {
            self.metadata = result.data
          } )
    },
    saveLeader( translation, translationID ) {
      this.editHeader = false
      this.metadata.header.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveTheTranslation( translation, translationID ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    }
  }
}
</script>

<style scoped>
.blix-navigation-page {
  height: calc(100% - 50px);
  background-color: #fff;
  margin-top: 50px;
  padding:15px;
}
.blix-navigation-list{
  list-style: none;
  padding:0;
  font-size:1.1em;
  margin:0;
}
</style>