<template>

  <!-- label for -->
  <div class="row">
    <div class="col-12">
      <label class="">
        <translation :t="'LABELFORSELECTBOX'"></translation>
      </label>
      <div class="input-group">
        <input v-model="metaData.label.value"
               @keyup="saveTypedMetaData"
               class="form-control form-control-sm"/>
        <div class="input-group-append"
             style="position:relative"
             @click="toggleShowTranslations(metaData.label)">
          <span class="input-group-text
                      bg-transparent border-left-0">
            <i class="far fa-flag"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- end label for -->

  <!-- category choice -->
  <div class="row" v-if="metaData.itemtype.value == 'CATEGORY'">
    <div class="col-12">
      <hr/>
      <label>
        <translation :t="'CHOOSECATEGORY'"></translation>
      </label>
      <select class="form-control form-control-sm"
              @change="savecategoryID"
              v-model="categoryID">
        <option value="null">
          <translation :t="'SELECTITEM'"></translation>
        </option>
        <option v-for="category in categories" :value="category.id">
          {{ category.translation ? category.translation.translation : 'NB' }}
        </option>
      </select>
    </div>
  </div>
  <!-- end category choice -->

  <!-- datafield choice -->
  <div class="row" v-if="metaData.itemtype.value == 'DATAFIELD'">
    <div class="col-12">
      <hr/>
      <label>
        <translation :t="'CHOOSEDATAFIELD'"></translation>
      </label>
      <select class="form-control form-control-sm"
              @change="savedatafield"
              v-model="datatype">
        <option value="null">
          <translation :t="'SELECTITEM'"></translation>
        </option>
        <option value="CLIENTNAME">
          <translation :t="'CLIENTNAME'"></translation>
        </option>
        <option value="ORGANIZATIONNAME">
          <translation :t="'ORGANIZATIONNAME'"></translation>
        </option>
      </select>
    </div>
  </div>
  <!-- end datafield choice -->

  <!-- itemtype choice -->
  <div class="row">
    <div class="col-12">
      <hr/>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'CATEGORY'"></i>
        <i class="far fa-square"
           @click="setItemtype('CATEGORY')"
           v-if="metaData.itemtype.value != 'CATEGORY'"></i>
        <label>
          <translation :t="'CATEGORY'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'DATAFIELD'"></i>
        <i class="far fa-square"
           @click="setItemtype('DATAFIELD')"
           v-if="metaData.itemtype.value != 'DATAFIELD'"></i>
        <label>
          <translation :t="'DATAFIELD'"></translation>
        </label>
      </div>
    </div>
  </div>
  <!-- end itemtype choice -->
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"

export default {
  name : "Selectbox",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      categoryID: null,
      datatype  : null
    }
  },
  components: {
    Translation
  },
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] )
  },
  created() {
    this.categoryID = this.metaData.categoryID.value
    this.datatype = this.metaData.datatype.value
  },
  methods: {
    toggleShowTranslations( translator ) {
      this.$emit( 'showTranslator', translator )
    },
    setItemtype( type ) {
      this.metaData.itemtype.value = type

      this.$emit( 'saveMetaData', this.metaData )
    },
    saveTypedMetaData() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.$emit( 'saveMetaData', self.metaData )
      }, 700 );
    },
    savecategoryID() {
      this.metaData.categoryID.value = this.categoryID
      this.saveTheMetaData()
    },
    savedatafield() {
      this.metaData.datatype.value = this.datatype
      this.saveTheMetaData()
    },
    saveTheMetaData( direction ) {
      if ( direction == 'MINUS' ) this.metaData.items.value--
      if ( direction == 'PLUS' ) this.metaData.items.value++

      this.$emit( 'saveMetaData', this.metaData )
    }
  }
}
</script>

<style scoped>

</style>