<template>
  <form>
    <div class="form-group">
      <validated-input
          v-model="selectedUser.firstname"
          :validations="'required'"
          :placeholder="'FIRSTNAME'"
          :errorMessage="'VALIDFIRSTNAME'"
          :labelCaption="'FIRSTNAME'"></validated-input>
    </div>
    <div class="form-group">
      <validated-input
          v-model="selectedUser.lastname"
          :validations="'required'"
          :placeholder="'LASTNAME'"
          :errorMessage="'VALIDLASTNAME'"
          :labelCaption="'LASTNAME'"></validated-input>
    </div>
    <div class="form-group">
      <validated-input
          v-model="selectedUser.email"
          :validations="'required|email'"
          :placeholder="'EMAILADDRESS'"
          :errorMessage="'VALIDEMAIL'"
          :labelCaption="'EMAILADDRESS'"></validated-input>
    </div>
    <label>
      <translation :t="'LANGUAGE'"></translation>
    </label>
    <div class="form-group">
      <select class="form-control"
              v-model="selectedUser.languagecode">
        <option v-for="language in translatedLanguages"
                :value="language.languagecode">
          {{ language.translation }}
        </option>
      </select>

    </div>
    <div class="form-group">
      <checkbox-input
          v-model="selectedUser.archive"
          :labelCaption="'archive'"
          :boxColor="'info'"></checkbox-input>
      <checkbox-input
          v-model="selectedUser.blocked"
          :labelCaption="'blocked'"
          :boxColor="'danger'"></checkbox-input>
    </div>
    &nbsp;&nbsp;
    <hr/>
    <div class="form-group">
      <button
          type="submit"
          :disabled="invalidForm"
          @click.prevent="saveTheUser"
          class="btn btn-primary">Save
      </button>
      <button
          type="button"
          @click="closeAndReset"
          class="btn btn-danger">Cancel
      </button>
    </div>
  </form>
</template>

<script>
import ValidatedInput from "@/components/controls/ValidatedInput";
import CheckboxInput from "@/components/controls/CheckboxInput";
import {mapActions, mapGetters} from 'vuex'
import Translation from "@/components/controls/Translation";

export default {
  components: {Translation, ValidatedInput, CheckboxInput},
  data() {
    return {}
  },
  computed  : {
    ...mapGetters(['languageCode', 'translatedLanguages']),
    ...mapGetters('user', ['selectedUser']),
    ...mapGetters('translate', ['countries', 'translations']),
    ...mapGetters('organization', ['organizationID']),
    invalidForm() {
      if (this.selectedUser.firstname.length == 0) return true
      if (this.selectedUser.lastname.length == 0) return true
      if (!ra.validate.email(this.selectedUser.email)) return true
      if (!this.selectedUser.languagecode) return true

      return false
    }
  },
  methods   : {
    ...mapActions( 'organization', ['getOrganizations']),
    ...mapActions('user', ['saveUser', 'resetUser']),
    toggleCheckbox(type, status) {
      this.selectedUser[type] = status
    },
    saveTheUser() {
      const self = this
      const form = new FormData()
      if(this.selectedUser.id != 0) form.append('id', this.selectedUser.id)
      form.append('firstname', this.selectedUser.firstname)
      form.append('lastname', this.selectedUser.lastname)
      form.append('email', this.selectedUser.email)
      form.append('languagecode', this.selectedUser.languagecode)
      form.append('organization_id', this.organizationID)
      this.saveUser(form).then(function(){
        let message = ra.findTranslation(self.translations, 'USERSUCCESSFULLYSAVED')
        ra.toasterMessage(message, 'success')
        self.getOrganizations()
        self.closeAndReset()
      })
    },
    closeAndReset(){
      $('#new-user').modal('hide')
      this.resetUser()
    }
  }
}
</script>

<style scoped>

</style>