<template>
  <div class="my-popover"
       :style="{top: topPlacement + 'px', left: leftPlacement + 'px'}">
    <p ref="infobox">
      <translation :t="tip"></translation>
    </p>
    <div v-if="placement == 'LEFT'" class="arrow-right"></div>
    <div v-else class="arrow-down"></div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"

export default {
  name : "Tooltip",
  props: ['tip', 'placement'],
  data() {
    return {
      topPlacement: 0,
      leftPlacement:0
    }
  },
  components: {Translation},
  computed  : {},
  mounted() {
    this.setInfoboxHeight();
  },
  methods: {
    setInfoboxHeight() {
      if( this.placement == 'LEFT'){
        this.topPlacement = Math.round(this.$refs.infobox.clientHeight / 2) - 23
        this.leftPlacement = -210
      }else{
        this.topPlacement = - (this.$refs.infobox.clientHeight + 10 )
        this.leftPlacement = -93
      }
    }
  }
}
</script>

<style scoped>
</style>