/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import categoryMutations from './categoryMutations.js';
import categoryActions from './categoryActions.js';
import categoryGetters from './categoryGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            categories        : [],
            selectedCategoryID: null,
            followup          : null
        }
    },
    mutations: categoryMutations,
    actions  : categoryActions,
    getters  : categoryGetters,

};


