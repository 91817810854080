<template>

  <!-- START: Pre Loader-->
  <!--  <div class="se-pre-con">-->
  <!--    <div class="loader"></div>-->
  <!--  </div>-->
  <!-- END: Pre Loader-->

  <changepassword></changepassword>
  <froalaeditor></froalaeditor>
  <alertme></alertme>
  <uploadpopup></uploadpopup>
  <button-action-selector></button-action-selector>
  <div
      id="header-fix"
      class="header fixed-top">
    <div class="site-width">
      <nav class="navbar navbar-expand-lg  p-0">
        <div class="navbar-header  h-100 h4 mb-0 align-self-center logo-bar text-left">
          <a
              href="index.html"
              class="horizontal-logo text-left">
            <img
                src="img/Enablement-logo.png"
                width="75px"
                alt="Enablement logo"/>
            <span class="h4 font-weight-bold align-self-center mb-0 ml-auto"> RehApp</span>
          </a>
        </div>
        <div class="navbar-header h4 mb-0 text-center h-100 collapse-menu-bar">
          <a
              href="#"
              class="sidebarCollapse"
              id="collapse"><i class="icon-menu"></i></a>
        </div>
        <div class="navbar-right ml-auto h-100">
          <ul class="ml-auto p-0 m-0 list-unstyled d-flex top-icon h-100 rehap-nav-top-link">
            <li>
              <select class="form-control"
                      @change="setLanguage"
                      v-model="language">
                <option v-for="language in languages"
                        :value="language.languagecode">
                  {{ language.translation }}
                </option>
              </select>
            </li>
            <li @click="showChangePassword">
              <span class="nav-link rehapp-nav-link">
                <translation :t="'CHANGEPASSWORD'"></translation>
              </span>
            </li>
            <li @click="logOut">
              <span class="nav-link rehapp-nav-link">
                <translation :t="'LOGOUT'"></translation>
              </span>
            </li>
            <li class="d-inline-block align-self-center  d-block d-lg-none">
              <a
                  href="#"
                  class="nav-link mobilesearch"
                  data-toggle="dropdown"
                  aria-expanded="false"><i class="icon-magnifier h4"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <!-- END: Header-->
  <!-- START: Main Menu-->
  <div class="sidebar">
    <div class="site-width">
      <!-- START: Menu-->
      <ul
          id="side-menu"
          class="sidebar-menu">
        <li
            v-for="p in pages"
            class="dropdown active">
          <a to="">
            <i class="mr-1 head" :class="p.icon"></i>
            <translation :t="p.entity"></translation>
          </a>
          <ul>
            <li v-for="subpage in p.subpages">
              <router-link
                  :to="{ name: subpage.route }">
                <i :class="subpage.icon"></i>
                <translation :t="subpage.entity"></translation>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <!-- END: Menu-->
    </div>
  </div>
  <!-- END: Main Menu-->
  <main>
    <div class="container-fluid site-width">
      <router-view></router-view>
    </div>
  </main>

</template>

<script>
import {api} from '../plugins/axios'
import Translation from "@/components/controls/Translation"
import {mapActions, mapGetters, mapState} from 'vuex'
import Changepassword from "../components/users/Changepassword.vue"
import Froalaeditor from '@/components/widgets/FroalaEditor.vue'
import Alertme from "@/components/widgets/Alertme"
import Uploadpopup from "@/components/widgets/Uploadpopup"
import ButtonActionSelector from "@/components/widgets/partials/ButtonActionSelector"

export default {
  components: {
    ButtonActionSelector,
    Uploadpopup,
    Alertme,
    Translation,
    Changepassword,
    Froalaeditor
  },
  data() {
    return {
      language: null
    }
  },
  computed: {
    ...mapGetters( ['languages', 'languageCode'] ),
    ...mapState( ['pages'] )
  },
  created() {
    var self = this
    this.countries()
    this.getElementtypes( this.languageCode )
    this.getCategories( this.languageCode )
    this.getTags( this.languageCode )
    this.language = this.languageCode
  },
  methods: {
    ...mapActions( 'auth', ['logOut'] ),
    ...mapActions( 'translate', ['countries'] ),
    ...mapActions( ['changeLanguageCode'] ),
    ...mapActions( 'formpageModule', ['getElementtypes'] ),
    ...mapActions( 'categoryModule', ['getCategories'] ),
    ...mapActions( 'tagModule', ['getTags'] ),
    setLanguage() {
      this.changeLanguageCode( this.language )
          .then( function () {
            window.location.reload()
          } )
    },
    showChangePassword() {
      $( '#change-password' ).modal()
    }
  }
}

</script>

<style scoped>

a.router-link-active {
  color: #ffc208 !important;
}

.head {
  color: #c2c2c2 !important;
}

.rehapp-nav-link {
  color: #888;
  cursor: pointer;
}

.rehapp-nav-link:hover {
  color: #111;
}

.rehap-nav-top-link li:hover {
  background-color: #ebebeb;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>