<template>
  <div class="my-backdrop"></div>
  <div class="my-popup my-popup-md">
    <div class="my-popup-header">
      <h3>
        <translation :t="'ICONSELECTOR'"></translation>
        <button class="close" @click="selectPicture(false)">x</button>
      </h3>
    </div>
    <div class="my-popup-body">
      <ul class="my-picture-list" style="position:relative;left:5px;">
        <li v-for="picture in modalPictures"
            @click="selectPicture(picture.imageUrl)"
            :class="'my-picture-list-item-' + pictureformat">
          <img :src="picture.imageUrl" class="my-picture-list-item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
  name: "PictureSelector",
  emits:['selectPicture'],
  props:['pictureType', 'pictureformat'],
  data() {
    return {
    }
  },
  components: {},
  computed  : {
    ...mapState('modalModule', ['modalPictures'])
  },
  watch: {},
  methods   : {
    selectPicture(selectedPicture){
      this.$emit('selectPicture', selectedPicture)
    }
  }
}

</script>

<style scoped>
.my-popup {
  position: fixed !important;
}
.my-picture-list{
  padding: 0;
  margin: 0;
  list-style: none;
  height:500px;
  overflow:auto;
}
.my-picture-list-item{
  float:left;
  margin:5px;
  max-width:100px;
  border:2px solid #999;
  border-radius:10px;
  padding:10px;
  cursor:pointer;
}
.my-picture-list-item:hover{
  box-shadow: #999 5px 5px 5px;
}
.my-picture-list-item-PORTRAIT img{
  height:300px;
  width:195px;
  border-radius:5px;
  box-shadow: #aaa 5px 10px 5px;
}

.my-picture-list-item-LANDSCAPE img{
  width:300px;
  height:195px;
  border-radius:5px;
  box-shadow: #aaa 5px 10px 5px;
}
</style>