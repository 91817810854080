<template>
  <div v-for="region in regions"
       @click="toggleRegion(region)"
       :class="{'background-orangenish': metadata.selectedRegions.find( r=>{return r.id == region.id})}"
       class="contoured-radio-box contoured-radio-box-hover">
    <i class="fa fa-check"
       v-if="metadata.selectedRegions.find( r=>{return r.id == region.id})"></i>
    <i class="far fa-square pointer"
       v-else></i>
    <label style="margin-left:5px;">
      {{ region.translation ? region.translation.translation : 'NB' }}
    </label>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapState} from 'vuex'

export default {
  name : "regionSelector",
  props: ['metadata', 'applicationID'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapState( ['regions'] )
  },
  methods   : {
    ...mapActions( 'applicationModule', ['saveMetadata'] ),
    toggleRegion( region ) {
      let self = this
      let splicer = false
      self.metadata.selectedRegions.forEach( ( l, i ) => {
        if ( l.id == region.id ) splicer = i
      } );

      if ( splicer !== false ) {
        self.metadata.selectedRegions.splice( splicer, 1 );
      } else {
        self.metadata.selectedRegions.push( {id: region.id} )
      }

      let payload = {
        applicationID: this.applicationID,
        metadata      : JSON.stringify( self.metadata )
      }
      this.saveMetadata( payload )
    }
  }
}
</script>

<style scoped>

</style>