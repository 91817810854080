<template>
  <div v-if="showRegionPicture">
    <region-picture
        @toggleRegionPicture="toggleRegionPicture"
        @setDefaultPicture="setDefaultPicture"
        :pictureType="'COVER'"
        :pictureformat="'PORTRAIT'"
        :applicationpageID="applicationpageID"
        :defaultPicture="metadata.startpicture.default"
        :regions="app.metadata.selectedRegions">
    </region-picture>
  </div>
  <span v-else>
  <div class="blix-start-background"
       :style="{'background-image': 'url(' + metadata.startpicture.default +')'}"
       @click="toggleRegionPicture"
       v-if="metadata">
    <app-header></app-header>
    <label class="blix-start-leader pointer"
           @click.stop="editLeader = true">
      <input-translation
          v-if="editLeader"
          :saveOnEnter="true"
          :identifierID="metadata.leader.translation_id"
          :toTranslate="metadata.leader.translation"
          @saveTranslation="saveLeader">
      </input-translation>
      <span v-else>
        {{ metadata.leader.translation }}
      </span>
    </label>
    <h4 class="blix-start-header"
        @click.stop="editHeader = true">
      <input-translation
          style="background-color:#fff"
          v-if="editHeader"
          :saveOnEnter="true"
          :identifierID="metadata.header.translation_id"
          :toTranslate="metadata.header.translation"
          @saveTranslation="saveHeader">
      </input-translation>
      <span v-else>
      {{ metadata.header.translation }}
      </span>
    </h4>
    <div class="blix-start-intro"
         @click.stop="editIntro = true">
      <input-translation
          style="background-color:#fff;width:350px"
          v-if="editIntro"
          :textarea="true"
          :rows="8"
          :saveOnEnter="true"
          :identifierID="metadata.intro.translation_id"
          :toTranslate="metadata.intro.translation"
          @saveTranslation="saveIntro">
      </input-translation>
      <p v-else>
        {{ metadata.intro.translation }}
      </p>
    </div>
    <div class="blix-start-button-holder">
      <action-button-default
          v-for="button in metadata.buttons"
          :buttonClass="'DEFAULT'"
          :applicationpageID="applicationpageID"
          :button="button"
          :pageEntity="pageEntity">
      </action-button-default>
    </div>
  </div>
  </span>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import AppHeader from '@/components/phoneapp/templates/blix/partials/Appheader.vue'
import InputTranslation from "../../../../controls/InputTranslation"
import ActionButtonDefault from '@/components/phoneapp/templates/blix/controls/ActionButtonDefault.vue'
import TreeModal from '@/components/widgets/TreeModal.vue'
import RegionPicture from '@/components/phoneapp/templates/partials/RegionPicture'

export default {
  name : "Startpage",
  props: ['applicationpageID'],
  data() {
    return {
      pageEntity       : 'BLIX-STARTPAGE',
      metadata         : false,
      editLeader       : false,
      editHeader       : false,
      editIntro        : false,
      leader           : '',
      selectedButton   : {uuid: ''},
      selectedRegion   : '',
      showRegionPicture: false,
      showLinkSelector : false
    }
  },
  components: {InputTranslation, AppHeader, ActionButtonDefault, TreeModal, RegionPicture},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] ),
    ...mapState( ['regions'] ),
    ...mapState( 'modalModule', ['selectedPicture', 'activePicturepage'] ),
    ...mapState( 'applicationModule', ['app', 'selectedApplicationID'] )
  },
  created() {
    this.setMetadata()
  },
  watch  : {
    applicationpageID() {
      this.setMetadata()
    }
  },
  methods: {
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'applicationModule', ['getApplicationpageMetadata', 'saveApplicationpageRegionpicture'] ),
    setMetadata() {
      let self = this
      self.getApplicationpageMetadata( self.applicationpageID )
          .then( ( result ) => {
            self.metadata = result.data
            self.makeRegions()
          } )
    },
    setDefaultPicture( selectedPicture ) {
      this.metadata.startpicture.default = selectedPicture
    },
    toggleRegionPicture() {
      this.showRegionPicture = !this.showRegionPicture

      if ( !this.showRegionPicture ) return

      let payload = {}
      payload.pageEntity = this.pageEntity
      payload.pictureType = 'COVER'
      payload.pictureFormat = 'PORTRAIT'
    },
    editTheBgPicture() {
      if ( this.editLeader || this.editHeader || this.editIntro ) return

      let mayEdit = true
      this.metadata.buttons.forEach( button => {
        if ( button.editButton ) mayEdit = false
      } )
      if ( !mayEdit ) return;

      this.editBgPicture = true
    },
    makeRegions() {
      let self = this

      self.app.metadata.selectedRegions.forEach( region => {
        region.translation = self.regions.find( reg => {
          return region.id == reg.id
        } )
        region.picture = self.metadata.startpicture.regions.find( reg => {
          return region.id == reg.regionID
        } )
      } )
    },
    saveMetadata() {
      this.editLeader = false
    },
    saveLeader( translation, translationID ) {
      this.editLeader = false
      this.metadata.leader.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveHeader( translation, translationID ) {
      this.editHeader = false
      this.metadata.header.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveIntro( translation, translationID ) {
      this.editIntro = false
      this.metadata.intro.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveTheTranslation( translation, translationID ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    }
  }
}
</script>

<style scoped>
.blix-start-background {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.blix-start-leader {
  position: absolute;
  top: 200px;
  left: 30px;
  font-size: .8em;
  color: #fff;
  font-weight: normal;
  background-color: #f16a02;
  padding: 2px 5px;
}

.blix-start-header {
  position: absolute;
  top: 230px;
  color: #fff;
  left: 30px;
  padding-right: 30px;
}

.blix-start-intro {
  position: absolute;
  top: 260px;
  left: 30px;
  color: #fff;
}

.blix-start-button-holder {
  position: absolute;
  bottom: 0;
  height: 200px;
  background-color: #f16a02;
  width: 100%;
}

</style>