<template>
  <div class="card">
    <div class="card-header bg-dark text-white">
    </div>
    <div class="card-body">
      {{ milestoneIndicators }}
<!--      <draggable v-model="milestoneIndicators"-->
<!--                 v-if="milestoneIndicators.length > 0"-->
<!--                 ghost-class="ghost"-->
<!--                 tag="transition-group"-->
<!--                 @end="reorderMilestoneindicators"-->
<!--                 :component-data="{name: 'flip-list', type: 'transition'}"-->
<!--                 item-key="id">-->
<!--        <template #item="{element}">-->
<!--          <div class="form-page-block">-->
<!--            <milestone-indicator-->
<!--                @setActiveIndicator="setActiveIndicator"-->
<!--                :milestoneIndicator="element"></milestone-indicator>-->
<!--          </div>-->
<!--        </template>-->
<!--      </draggable>-->
    </div>
  </div>


</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import draggable from "vuedraggable"
import MilestoneIndicator from "./MilestoneIndicator";

export default {
  name : "MilestoneIndicators",
  props: ['milestoneIndicators'],
  emits: [],
  data() {
    return {}
  },
  components: {MilestoneIndicator, draggable},
  computed  : {},
  created() {
  },
  methods: {
    ...mapActions( 'milestoneModule', ['saveMilestoneIndicatorOrder'] ),
    reorderMilestoneindicators() {
      let order = 0;
      let categories = []
      this.milestoneIndicators.forEach( mc => {
        order += 10
        categories.push( {id: mc.id, order: order} )
      } )
      this.saveMilestoneIndicatorOrder(categories)
    },
    setActiveIndicator( indicatorID ) {
      this.milestoneIndicators.forEach( mi => {
        mi.active = mi.id == indicatorID
      } )
    }
  }
}
</script>

<style scoped>

</style>