/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setShowEditor( state, payload ) {
        state.showEditor = payload
    },
    setEditorText( state, payload ) {
        state.editorText = payload
    },
    setRevisions(state, revisions){
        state.revisions = revisions
    },
    toggleRevision(state){
        state.showRevisions = ! state.showRevisions
    },
    updateEditortext( state, payload ) {
        state.editorText = payload
    },
    setTextType( state, payload ) {
        state.textType = payload
    },
    setTextTypeID( state, payload ) {
        state.textTypeID = payload
    }
}