<template>
  <router-view/>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapActions} from 'vuex'

export default {
  created() {
    this.setLanguageCode()

    this.translations()
    this.getLanguages()
    this.getRegions()
    this.getTranslatedLanguages()
    this.getAuthorizedLanguages()

    this.getEntities()
        .then(()=>{
          if ( sessionStorage.getItem( 'loggedin' ) ) {
            this.setLoginState();
            this.getPages()
                .then( result => {
                  if( result.data.length == 0 ) return this.$router.push('nopage')
                  let startRoute = false
                  result.data.forEach(p=>{
                    if( p.subpages.length > 0 && ! startRoute ){
                      startRoute = p.subpages[0].route
                    }
                  })
                  this.$router.push(startRoute ? startRoute : 'nopage')
                } );
          } else if ( !this.loggedIn ) {
            if (window.location.pathname != '/requestforpassword')
              this.$router.push( '/login' )
          }
        })
  },
  computed: {
    ...mapGetters( 'auth', ['loggedIn'] ),
    ...mapGetters( ['languageCode', 'translatedLanguages'] )
  },
  methods : {
    ...mapActions( ['setLanguageCode', 'getLanguages', 'getTranslatedLanguages', 'getRegions', 'getPages', 'getEntities', 'getAuthorizedLanguages'] ),
    ...mapActions( 'auth', ['setLoginState'] ),
    ...mapActions( 'translate', ['translations'] )
  }

}

</script>

<style>
</style>
