<template>
  <label class="">
    <translation :t="'LABELFORINPUT'"></translation>
  </label>
  <div class="row">
    <div class="col-12">
      <div class="input-group">
<!--        <input-translation @saveTranslation="saveTypedMetaData"-->
<!--                            :toTranslate="metaData.label.value"-->
<!--                            :identifierID="metaData.label.translated">-->
<!--        </input-translation>-->
        <input v-model="metaData.label.value"
               @keyup="saveTypedMetaData"
               class="form-control form-control-sm"/>
        <div class="input-group-append"
             style="position:relative"
             @click="toggleShowTranslations(metaData.label)">
          <span class="input-group-text
                        bg-transparent border-left-0">
            <i class="far fa-flag"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <i class="fa fa-check"
         v-if="metaData.inputType.value=='TEXT'"></i>
      <i v-else class="far fa-square pointer"
         @click="setMetadafield('TEXT', 'inputType')"></i>
      <label class="fa-label">
        <translation :t="'TEXT'"></translation>
      </label>
      <i class="fa fa-check"
         v-if="metaData.inputType.value == 'DATE'"></i>
      <i v-else class="far fa-square pointer"
         @click="setMetadafield('DATE', 'inputType')"></i>
      <label class="fa-label">
        <translation :t="'DATE'"></translation>
      </label>
      <span v-if="selectedFormpage.formtype == 'CLIENTFORM'">
        <!-- clientname -->
        <span v-if="metaData.inputType.value=='TEXT'">
          <i class="fa fa-check"
             @click="setMetadafield('0', 'clientfield')"
             v-if="metaData.clientfield.value == '1'"></i>
          <i v-else class="far fa-square pointer"
             @click="setMetadafield('1', 'clientfield')"></i>
          <label class="fa-label">
            <translation :t="'CLIENTNAME'"></translation>
          </label>
        </span>
        <!-- end clientname -->

        <!-- birthdate -->
        <span v-else>
          <i class="fa fa-check"
             @click="setMetadafield('0', 'birthdate')"
             v-if="metaData.birthdate.value == '1'"></i>
          <i v-else class="far fa-square pointer"
             @click="setMetadafield('1', 'birthdate')"></i>
          <label class="fa-label">
            <translation :t="'BIRTHDATE'"></translation>
          </label>
        </span>
        <!-- end birthdate -->

      </span>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation";
import InputTranslation from "@/components/controls/InputTranslation";


export default {
  name : "Inputfield",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      timeout: null
    }
  },
  components: {InputTranslation, Translation},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'formpageModule', ['selectedFormpage'] )
  },
  created() {
  },
  methods: {
    ...mapActions( 'formpageModule', ['saveMetaData'] ),
    saveTypedMetaData() {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        self.$emit( 'saveMetaData', self.metaData )
      }, 700 );
    },
    toggleShowTranslations( translator ) {
      this.$emit( 'showTranslator', translator )
    },
    setMetadafield(status, field){
      this.metaData[field].value = status
      this.$emit( 'saveMetaData', this.metaData )
    }
  }
}
</script>

<style scoped>

</style>