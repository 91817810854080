<template>
  <div class="row">
    <div class="col-12 mt-4">
      <ul class="milestonedomain">
        <draggable v-model="domains"
                   v-if="domains.length > 0"
                   ghost-class="ghost"
                   tag="transition-group"
                   @end="reorderDomains"
                   :component-data="{name: 'flip-list', type: 'transition'}"
                   item-key="id">
          <template #item="{element}">
            <div class="form-page-block">
              <milestonedomain :domain="element"
                               @removeMilestoneDomain="removeMilestoneDomain"
                               @setActiveDomain="setActiveDomain">
              </milestonedomain>
            </div>
          </template>
        </draggable>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import Milestonedomain from '@/components/forms/milestones/Milestonedomain'
import draggable from 'vuedraggable'

export default {
  name : "Milestonedomains",
  props: ['domains'],
  emits: [],
  data() {
    return {
      activeDomain: null
    }
  },
  components: {Milestonedomain, draggable},
  computed  : {
    ...mapState( 'milestoneModule', ['activeMilestoneCategoryID', 'activeMilestoneDomainID'] )
  },
  created() {
  },
  watch  : {
    domains() {
      this.domains.forEach( d => {
        if ( d.id == this.activeDomain ) d.active = true
      } )
    }
  },
  methods: {
    ...mapActions( 'milestoneModule', [
      'saveMilestoneorder',
      'setActiveMilestoneDomainID',
      'getMilestoneIndicators',
      'setMilestoneIndicators'] ),
    setActiveDomain( domainID ) {
      this.setActiveMilestoneDomainID( domainID )
      this.activeDomain = domainID
      this.domains.forEach( d => d.active = d.id == domainID )

      let payload = {}
      payload.milestoneDomainID = this.activeMilestoneDomainID
      payload.milestoneCategoryID = this.activeMilestoneCategoryID

      this.getMilestoneIndicators( payload )
          .then( result => {
            this.setMilestoneIndicators( result.data )
          } )
    },
    reorderDomains() {
      let domains = []
      let order = 0
      this.domains.forEach( d => {
        domains.push( {id: d.id, order: order} )
        order += 10
      } )
      this.saveMilestoneorder( domains )
    },
    removeMilestoneDomain( milestoneDomainID ) {
      let index = this.domains.findIndex( d => d.id == milestoneDomainID )

      this.domains.splice( index, 1 )
    }
  }
}
</script>

<style scoped>
.milestonedomain {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>