/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '../../../plugins/axios'

export default {

    getCategories( context, languageCode ) {
        if( ! languageCode ) languageCode = context.rootState.languageCode
        return api.get( '/api/management/getCategories/' + languageCode )
            .then( function ( result ) {
                result.data.forEach( category => {
                    category.categoryitems.forEach( categoryItem => {
                        if ( !categoryItem.translation ) {
                            categoryItem.translation = {};
                            categoryItem.translation.translation = '';
                        }
                    } )
                } )
                context.commit( 'setCategories', result.data )
            } )
    },
    getLinkedCategoryitemArticles(context, categoryitemID) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/management/getLinkedCategoryitemArticles/' + categoryitemID + '/' + languageCode )
    },
    setFollowup( context, payload ) {
        context.commit( 'setFollowup', payload )
    },
    saveCategory( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/saveCategory', formData )
    },
    addCategoryItem( context, categoryID ) {
        return api.get( '/api/management/addCategoryItem/' + categoryID )
    },
    setSelectedCategoryID( context, categoryID ) {
        context.commit( 'setSelectedCategoryID', categoryID )
    },
    attachCategoryTree(context, payload){
        return api.get('/api/managment/attachCategoryTree/' + payload.categoryID + '/' + payload.treeID)
    },
    detachCategoryTree(context, payload){
        return api.get('/api/managment/detachCategoryTree/' + payload.categoryID + '/' + payload.treeID)
    },
    reorderCategories(context, payload){
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post('/api/management/reorderCategories', formData)
    },
    saveCategoryItem( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post('/api/management/saveCategoryItem', formData)
    },
    saveFollowup( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/saveFollowup', formData )
            .then( function ( result ) {
                context.dispatch( 'setFollowup', result.data )
            } )
    },
    saveFollowupname( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/saveFollowupname', formData )
    },
    removeCategory( context, categoryID ) {
        return api.get( '/api/management/removeCategory/' + categoryID )
    },
    removeCategoryItem( context, categoryItemID ) {
        return api.get( '/api/management/removeCategoryItem/' + categoryItemID )
    },
    removeFollowup( context, followup ) {
        let categoryitemID = followup.categoryitem_id
        return api.get( '/api/management/removeFollowup/' + followup.id )
            .then( function () {
                let followup = {
                    categoryitem_id: categoryitemID,
                    followuptype   : '',
                    translation_id : null,
                    translation    : null
                }
                context.dispatch( 'setFollowup', followup )
            } )
    }
}