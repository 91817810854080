<template>
  <translators></translators>
  <edit-formpage></edit-formpage>
  <form-library></form-library>
  <new-formpage></new-formpage>
  <div class="row">
    <div class="col-6 mt-4">
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'FORMS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <select-edit
                  @showTranslator="showTranslator"
                  @setItem="setForm"
                  @saveItem="saveTheForm"
                  @newItem="newForm"
                  @removeItem="removeTheForm"
                  :deleteAble="formDeleteAble"
                  :items="compForms"></select-edit>
            </div>
          </div>
          <div class="row" style="margin-top:15px;">
            <div class="col-12">
              <button class="btn btn-warning"
                      style="float:right;"
                      @click="showNewFormpage"
                      :disabled="! formID">
                <translation :t="'NEWFORMPAGE'"></translation>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <br/>
      <draggable v-model="localFormpages"
                 v-if="localFormpages.length > 0"
                 ghost-class="ghost"
                 tag="transition-group"
                 @end="reorderTheFormpages"
                 :component-data="{name: 'flip-list', type: 'transition'}"
                 item-key="id">
        <template #item="{element}">
          <div class="form-page-block">
            <formpage :formpage="element"></formpage>
          </div>
        </template>
      </draggable>
    </div>
    <!--    <formpage v-for="formpage in formpages" :formpage="formpage"></formpage>-->
  </div>
</template>

<script>

import draggable from 'vuedraggable'
import Translators from "@/components/widgets/Translators.vue"
import {mapGetters, mapActions} from "vuex"
import Translation from "@/components/controls/Translation"
import Formpage from "@/components/forms/formpages/Formpage"
import NewFormpage from "@/components/forms/formpages/NewFormpage"
import EditFormpage from "@/components/forms/formpages/builder/EditFormpage"
import FormLibrary from "@/components/forms/FormLibrary"
import SelectEdit from "@/components/controls/SelectEdit"

export default {

  name      : "forms",
  components: {
    FormLibrary,
    Translation,
    Translators,
    Formpage,
    NewFormpage,
    EditFormpage,
    draggable,
    SelectEdit
  },
  data() {
    return {
      localFormpages: [],
      formID        : null
    }
  },
  created() {
    this.getForms( 'DEFAULT' );
  },
  computed: {
    ...mapGetters( 'formModule', ['forms', 'selectedForm'] ),
    ...mapGetters( 'formpageModule', ['formpages'] ),
    ...mapGetters( ['languages', 'languageCode'] ),
    ...mapGetters( 'translator', ['toTranslate'] ),
    compForms() {
      let compForms = []
      this.forms.forEach( f => {
        f.name = f.formname
        compForms.push( f )
      } )
      return compForms
    },
    formDeleteAble(){
      return this.formpages.length == 0
    },
    dragOptions() {
      return {
        animation : 0,
        group     : "description",
        disabled  : false,
        ghostClass: "ghost",
        formType:'DEFAULT'
      };
    }
  },
  watch   : {
    formpages() {
      this.localFormpages = this.formpages
    }
  },
  methods : {
    ...mapActions( 'formModule', ['selectForm', 'getForms', 'resetForm', 'getForm', 'saveForm', 'removeForm'] ),
    ...mapActions( 'formpageModule', ['getFormpages', 'setFormID', 'updateFormpagename', 'reorderFormpages', 'resetFormpages'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'getTranslations', 'setIdentifierID'] ),
    showTranslator() {
      if ( !this.selectedForm ) return

      this.showHideTranslator( 'SHOW' )

      this.getTranslations( this.selectedForm.form_id )
      this.setToTranslate( this.selectedForm.formname )
      this.setIdentifierID( this.selectedForm.form_id )
    },
    reorderTheFormpages() {
      let reorderdFormpages = [];
      let index = 1;
      this.localFormpages.forEach( formpage => {
        reorderdFormpages.push( {
          id   : formpage.id,
          index: index
        } )
        index++
      } )
      this.reorderFormpages( reorderdFormpages )
    },
    setForm(formID){
      this.selectForm( formID )

      this.getFormpages( 0 )
    },
    newForm(){
      this.resetFormpages()
      this.selectForm(0)
    },
    showNewFormpage() {
      $( '#new-formpage' ).modal( 'show' )
    },
    saveTheForm(payload) {
      payload.formname = payload.name
      payload.description = 'PLACEHOLDER'
      payload.languageCode = this.languageCode
      payload.formtype = this.formType
      payload.cycle = 1
      this.saveForm( payload )
          .then( result => {
            this.getForms( this.formType )
                .then( () => {
                  this.selectForm( result.data.id )
                  this.formID = result.data.id
                } )
          } )
    },
    removeTheForm(formID){
      if( ! formID ) return
      this.removeForm(formID, this.formType)
      this.getForms(formtype)
    }
  }
}
</script>

<style scoped>


.form-page-block {
  width: 220px;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: move;
}

.edit-form {
  /*top:-100%;*/
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 100;
  padding: 15px;
}
</style>