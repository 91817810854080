<template>
  <div class="my-backdrop"></div>
  <div class="my-popup my-popup-md">
    <div class="my-popup-header">
      <h3>
        <translation :t="'ICONSELECTOR'"></translation>
        <button class="close" @click.stop="setTheSelectedIcon(false)">x</button>
      </h3>
    </div>
    <div class="my-popup-body">
      <ul class="my-icon-list" style="position:relative;left:25px;">
        <li v-for="icon in icons"
            @click.stop="setTheSelectedIcon(icon)"
            class="my-icon-list-item">
          <span  class="material-icons">
            {{ icon }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
  name : "IconSelector",
  emits:['chosenicon'],
  data() {
    return {
      icons: [
        'search',
        'home',
        'account_circle',
        'settings',
        'info',
        'favorite',
        'lock',
        'language',
        'list',
        'calendar_today',
        'history',
        'pending_actions',
        'article',
        'alarm',
          'close',
          'check',
          'arrow_downward',
          'east',
          'arrow_back',
          'arrow_upward',
          'more_vert',
          'add',
          'inventory',
          'create',
          'undo',
          'push_pin',
          'share',
          'groups',
          'person_add',
          'notifications_active',
          'edit',
          'image',
          'collections',
          'call',
          'email',
          'message',
          'location_on',
          'download',
          'folder',
          'create_new_folder'

      ]
    }
  },
  components: {},
  computed  : {},
  methods   : {
    setTheSelectedIcon( icon ) {
      this.$emit( 'chosenicon', icon )
    }
  }
}
</script>

<style scoped>
.my-popup {
  position: fixed !important;
}

.my-icon-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.my-icon-list-item {
  width: 61px;
  height: 61px;
  padding: 10px;
  margin: 3px;
  float: left;
  font-size: 2em;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  background-color: #fff;
  color: #777;
  cursor: pointer;
}

.my-icon-list-item:hover {
  box-shadow: #bbb 3px 3px 5px;
  color: #000;
}
</style>


<!--'fab fa-accessible-icon',-->
<!--'far fa-address-book',-->
<!--'fas fa-allergies',-->
<!--'fas fa-ambulance',-->
<!--'fas fa-atom',-->
<!--'fas fa-baby-carriage',-->
<!--'fas fa-band-aid',-->
<!--'fas fa-bath',-->
<!--'fas fa-bed',-->
<!--'fas fa-bell',-->
<!--'fas fa-birthday-cake',-->
<!--'fas fa-book-medical',-->
<!--'fas fa-bus-alt',-->
<!--'far fa-calendar-alt',-->
<!--'fas fa-car-side',-->
<!--'fas fa-carrot',-->
<!--'fas fa-chalkboard-teacher',-->
<!--'fas fa-child',-->
<!--'fas fa-church',-->
<!--'fas fa-cloud-rain',-->
<!--'fas fa-crow',-->
<!--'fas fa-dog',-->
<!--'fas fa-female',-->
<!--'fas fa-glasses',-->
<!--'fas fa-guitar',-->
<!--'fas fa-handshake',-->
<!--'fas fa-home',-->
<!--'fas fa-laptop',-->
<!--'fas fa-lock',-->
<!--'fas fa-male',-->
<!--'fas fa-motorcycle',-->
<!--'fas fa-pastafarianism',-->
<!--'fas fa-people-carry',-->
<!--'fas fa-plane',-->
<!--'fas fa-school',-->
<!--'fas fa-shuttle-van',-->
<!--'fas fa-spider',-->
<!--'fas fa-store',-->
<!--'fas fa-swimmer',-->
<!--'far fa-thumbs-up',-->
<!--'fas fa-tractor',-->
<!--'fas fa-truck',-->
<!--'fas fa-user-graduate',-->
<!--'fas fa-user-nurse',-->
<!--'fas fa-thermometer',-->
<!--'fas fa-sign-language',-->
<!--'fas fa-scroll',-->
<!--'fas fa-print',-->
<!--'fas fa-plus',-->
<!--'fas fa-pills',-->
<!--'far fa-newspaper',-->
<!--'fas fa-mosque',-->
<!--'fas fa-medkit',-->
<!--'fab fa-medapps',-->
<!--'fas fa-list',-->
<!--'far fa-hospital',-->
<!--'fas fa-hospital-alt',-->
<!--'fas fa-hospital-symbol',-->
<!--'fas fa-hands',-->
<!--'fas fa-graduation-cap',-->
<!--'far fa-file-alt',-->
<!--'far fa-comments',-->
<!--'fas fa-clinic-medical',-->
<!--'far fa-calendar-alt',-->
<!--'mdi mdi-tortoise',-->
<!--'mdi mdi-blood-bag',-->
<!--'mdi mdi-bookmark-plus-outline',-->
<!--'pe-is-w-heavy-hail',-->
<!--'home icon-home',-->
<!--'like icon-like'-->


<!--'mdi mdi-hand-peace-variant',-->
<!--'mdi mdi-hand-pointing-down',-->
<!--'mdi mdi-hand-pointing-left',-->
<!--'mdi mdi-hand-pointing-right',-->
<!--'mdi mdi-hand-pointing-up',-->
<!--'mdi mdi-heart',-->
<!--'mdi mdi-heart-box',-->
<!--'mdi mdi-heart-box-outline',-->
<!--'mdi mdi-heart-half-full',-->
<!--'mdi mdi-helicopter',-->
<!--'mdi mdi-hexagram',-->
<!--'mdi mdi-highway',-->
<!--'mdi mdi-tortoise',-->
<!--'mdi mdi-bread-slice-outline',-->
<!--'mdi mdi-fountain-pen-tip',-->
<!--'mdi mdi-drama-masks',-->
<!--'mdi mdi-home-city',-->
<!--'mdi mdi-home-city-outline',-->
<!--'mdi mdi-rollerblade',-->
<!--'mdi mdi-tea',-->
<!--'mdi mdi-tea-outline',-->
<!--'mdi mdi-hiking',-->
<!--'mdi mdi-google-street-view',-->
<!--'mdi mdi-emoticon',-->
<!--'mdi mdi-emoticon-excited',-->
<!--'mdi mdi-emoticon-cool',-->
<!--'mdi mdi-emoticon-neutral',-->
<!--'mdi mdi-emoticon-sad',-->
<!--'mdi mdi-sheep',-->
<!--'mdi mdi-account',-->
<!--'mdi mdi-account-box-outline',-->
<!--'mdi mdi-account-child-circle',-->
<!--'mdi mdi-account-heart-outline',-->
<!--'mdi mdi-account-star-outline',-->
<!--'mdi mdi-account-outline',-->
<!--'mdi mdi-account-off-outline',-->
<!--'mdi mdi-account-multiple-minus-outline',-->
<!--'mdi mdi-account-arrow-right-outline',-->
<!--'mdi mdi-account-edit',-->
<!--'mdi mdi-account-minus-outline',-->
<!--'mdi mdi-motorbike',-->
<!--'mdi mdi-train-car',-->
<!--'mdi mdi-car-multiple',-->
<!--'mdi mdi-delete-circle-outline',-->
<!--'mdi mdi-airplane',-->
<!--'mdi mdi-dump-truck',-->
<!--'mdi mdi-alarm',-->
<!--'mdi mdi-coffee-outline',-->
<!--'mdi mdi-desktop-mac',-->
<!--'mdi mdi-deskphone',-->
<!--'mdi mdi-hospital',-->
<!--'mdi mdi-hospital-building',-->
<!--'mdi mdi-hotel',-->
<!--'mdi mdi-church',-->
<!--'mdi mdi-book-outline',-->
<!--'mdi mdi-login',-->
<!--'mdi mdi-logout',-->
<!--'mdi mdi-domain',-->
<!--'mdi mdi-home',-->
<!--'mdi mdi-home-circle',-->
<!--'mdi mdi-home-variant',-->
<!--'mdi mdi-newspaper',-->
<!--'mdi mdi-seat-recline-extra',-->
<!--'mdi mdi-seat-legroom-extra',-->
<!--'mdi mdi-human-handsup',-->
<!--'mdi mdi-gauge-low'-->