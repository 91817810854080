<template>
  <div v-if="showRegionPicture">
    <region-picture
        @toggleRegionPicture="toggleRegionPicture"
        @setDefaultPicture="setDefaultPicture"
        :pictureType="'ILLUSTRATION'"
        :defaultPicture="metadata.headerpicture.default"
        :regions="app.metadata.selectedRegions"
        :pictureformat="'LANDSCAPE'"
        :applicationpageID="applicationpageID"
    >
    </region-picture>
  </div>
  <span v-else>
    <div class="blix-about-background"
         v-if="metadata">
      <app-header :background="true"></app-header>
      <label class="blix-about-leader pointer"
             style="margin-top:60px;padding:0 15px"
             @click.stop="editLeader = true">
        <input-translation
            style="width:200%"
            v-if="editLeader"
            :saveOnEnter="true"
            :identifierID="metadata.leader.translation_id"
            :toTranslate="metadata.leader.translation"
            @saveTranslation="saveLeader">
        </input-translation>
        <span v-else>
          <div style="white-space:pre-wrap;">
            {{ metadata.leader.translation }}
          </div>
        </span>
      </label>
      <div class="about-image-holder"
           @click="toggleRegionPicture">
        <img :src="metadata.headerpicture.default">
      </div>

      <div class="about-grey-text"
           @click.stop="editAbouttext = true">
        <input-translation
            style="background-color:#fff;width:350px"
            v-if="editAbouttext"
            :textarea="true"
            :rows="8"
            :saveOnEnter="true"
            :identifierID="metadata.abouttext.translation_id"
            :toTranslate="metadata.abouttext.translation"
            @saveTranslation="saveAbouttext">
        </input-translation>
        <p v-else v-html="formattedTranslation"></p>
      </div>
      <div class="about-list-holder">
        <ul>
          <input-translation
              style="width:100%"
              v-if="editListheader"
              :saveOnEnter="true"
              :identifierID="metadata.listheader.translation_id"
              :toTranslate="metadata.listheader.translation"
              @saveTranslation="saveListheader">
          </input-translation>
          <li class="about-list-header"
              @click.stop="editListheader = true"
              v-else>
            {{ metadata.listheader.translation }}
          </li>
          <li v-for="listitem in metadata.listitems"
              class="about-list-item">
            <action-button-default
                @removeButton="removeTheButton"
                :withDelete="true"
                :buttonClass="'LISTITEM'"
                :applicationpageID="applicationpageID"
                :button="listitem">
            </action-button-default>
          </li>
          <li>
            <i class="fa fa-plus"
               style="float:right"
               @click.stop="addAButton"></i>
          </li>
        </ul>
      </div>
    </div>
  </span>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import AppHeader from '@/components/phoneapp/templates/blix/partials/Appheader.vue'
import InputTranslation from "@/components/controls/InputTranslation"
import ActionButtonDefault from '@/components/phoneapp/templates/blix/controls/ActionButtonDefault.vue'
import RegionPicture from '@/components/phoneapp/templates/partials/RegionPicture'

export default {
  name : "Aboutpage",
  props: ['applicationpageID'],
  data() {
    return {
      pageEntity          : 'BLIX-ABOUTPAGE',
      metadata            : false,
      editLeader          : false,
      editAbouttext       : false,
      editHeaderPicture   : false,
      editListheader      : false,
      showRegionPicture   : false,
      formattedTranslation: ''
    }
  },
  components: {InputTranslation, AppHeader, RegionPicture, ActionButtonDefault},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] ),
    ...mapState( ['regions'] ),
    ...mapState( 'modalModule', [] ),
    ...mapState( 'applicationModule', ['app', 'selectedApplicationID'] )
  },
  created() {
    this.setMetadata()
  },
  watch  : {},
  methods: {
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'applicationModule', ['getApplicationpageMetadata', 'saveApplicationpageRegionpicture', 'addButton', 'removeButton'] ),
    setMetadata() {
      let self = this
      self.getApplicationpageMetadata( self.applicationpageID )
          .then( ( result ) => {
            self.metadata = result.data
            self.makeRegions()
          } )
    },
    toggleTheRegionPicture() {
      this.toggleRegionPicture()
    },
    setDefaultPicture( selectedPicture ) {
      this.metadata.headerpicture.default = selectedPicture
    },
    selectRegion( selectedRegion ) {
      let payload = {}
      payload.pageEntity = this.pageEntity
      payload.pictureType = 'ILLUSTRATIONS'
      payload.pictureFormat = 'LANDSCAPE'
      this.togglePictureselector( payload )
      this.selectedRegion = selectedRegion
    },
    toggleRegionPicture() {
      console.log( 'inside toggleRegionPicture' );
      this.showRegionPicture = !this.showRegionPicture

      if ( !this.showRegionPicture ) return

      let payload = {}
      payload.pageEntity = this.pageEntity
      payload.pictureType = 'ABOUT'
      payload.pictureFormat = 'LANDSCAPE'
    },
    makeRegions() {
      let self = this
      self.app.metadata.selectedRegions.forEach( region => {
        region.translation = self.regions.find( reg => {
          return region.id == reg.id
        } )
        region.picture = self.metadata.headerpicture.regions.find( reg => {
          return region.id == reg.regionID
        } )
      } )
    },
    addAButton() {
      let self = this
      let payload = {}
      payload.applicationpageID = this.applicationpageID

      self.addButton( payload )
          .then( () => {
            self.setMetadata()
          } )
    },
    saveLeader( translation, translationID ) {
      this.editLeader = false
      this.metadata.leader.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveAbouttext( translation, translationID ) {
      this.editAbouttext = false
      this.metadata.abouttext.translation = translation

      this.saveTheTranslation( translation, translationID )

      this.formattedTranslation = translation.replace( /(?:\r\n|\r|\n)/g, '<br>' );
    },
    saveListheader( translation, translationID ) {
      this.editListheader = false
      this.metadata.listheader.translation = translation

      this.saveTheTranslation( translation, translationID )
    },
    saveTheTranslation( translation, translationID ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    },
    removeTheButton( button ) {
      let self = this
      let payload = {}
      payload.applicationpageID = self.applicationpageID
      payload.buttonUuid = button.uuid

      self.removeButton( payload )
          .then( () => {
            self.setMetadata()
          } )
    }
  }
}
</script>

<style scoped>
.blix-about-background {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: auto;
}

.blix-about-leader {
  font-weight: normal;
  font-size: 1.3em;
}

.about-image-holder {
  width: 100%;
  border: 1px solid #000;
}

.about-image-holder img {
  max-width: 100%;
  object-fit: cover;
}

.about-grey-text {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #e2e2e2;
  padding: 15px;
  border-radius: 2px;
}

.about-list-holder {
  margin-top: 20px;
}

.about-list-holder ul {
  margin: 0;
  padding: 0 15px;
  list-style: none;
  margin-bottom: 20px;
}

.about-list-header {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.about-list-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
</style>


