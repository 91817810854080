<template>
  <div v-for="language in translatedLanguages"
       @click="toggleLanguage(language)"
       :class="{'background-greenish': metadata.selectedLanguages.find( l=>{return l.languagecode == language.languagecode})}"
       class="contoured-radio-box contoured-radio-box-hover">
    <i class="fa fa-check"
       v-if="metadata.selectedLanguages.find( l=>{return l.languagecode == language.languagecode})"></i>
    <i class="far fa-square pointer"
       v-else></i>
    <label style="margin-left:5px;">
      {{ language.translation }}
    </label>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name : "LanguageSelector",
  props: ['metadata', 'applicationID'],
  data() {
    return {}
  },
  components: {},
  computed  : {
    ...mapGetters( ['translatedLanguages'] )
  },
  methods   : {
    ...mapActions( 'applicationModule', ['saveMetadata'] ),
    toggleLanguage( language ) {
      let self = this
      let splicer = false
      self.metadata.selectedLanguages.forEach( ( l, i ) => {
        if ( l.languagecode == language.languagecode ) splicer = i
      } );

      if ( splicer !== false ) {
        self.metadata.selectedLanguages.splice( splicer, 1 );
      } else {
        self.metadata.selectedLanguages.push( {languagecode: language.languagecode} )
      }

      let payload = {
        applicationID: this.applicationID,
        metadata      : JSON.stringify( self.metadata )
      }
      this.saveMetadata( payload )
    }
  }
}
</script>

<style scoped>

</style>