/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '../../../plugins/axios'
import router from "@/router";

export default {
    getOrganizations(context) {
        api.get('/api/organizations/getallorganizations')
            .then(result => {
                context.commit('setOrganizations', result.data)
            })
    },
    getUserrights(context, organizationID){
        api.get('/api/users/getUserrights/' + organizationID )
            .then((result=>{
                context.commit('setUserrights', result.data)
            }))
    },
    getAuthorisedRoles(context){
        let languageCode = context.rootState.languageCode
        api.get( '/api/users/getAuthorisedRoles/' + languageCode )
            .then( ( result ) => {
                context.commit( 'setRoles', result.data )
            } )
    },
    // setSelectOrganization(context, organization){
    //     context.commit('selectedOrganization', organization)
    // },
    resetOrganization(context) {

        let organization = {
            id: null,
            organization_id: null,
            name: '',
            address: '',
            place: '',
            country_id: null
        }
        context.dispatch('setSelectedOrganization', organization)
    },
    saveOrganization(context, submitDATA) {
        api.post('/api/organizations/saveorganization', submitDATA).then(() => {
            context.dispatch('getOrganizations')
            context.dispatch('resetOrganization')
        })
    },
    setSelectedOrganization(context, organization) {
        context.commit('selectedOrganization', organization)
    },
    setEditOrganization(context, status){
        context.commit('setEditOrganization', status)
    },
    setOrganziationID(context, organizationID) {
        context.commit('setOrganizationID', organizationID)
    },
    saveUserrole(context, payload){
        return api.get(
            '/api/users/saveUserrole/' + payload.userID + '/' + payload.roleID)
    },
    saveUserright(context, payload){
        return api.get(
            '/api/users/saveUserright/' + payload.userID + '/' + payload.rightableID + '/' + payload.rightableType + '/' + payload.right)
    },
    removeOrganization(context, organizationID){
        return api.get('/api/organization/removeorganization/' + organizationID)
            .then(response => {
                context.dispatch('getOrganizations')
            })

    }

}