<template>
  <div class="side-panel side-panel-formlibrary"
       :class="showFormlibrary ? 'open-panel' : ''">
    <h3 class="side-button side-formlibrary-button"
        @click="toggleShow">
      <translation :t="'FORMLIBRARY'"></translation>
    </h3>
    <div class="card bg-primary-light" style="width:400px;">
      <div class="card-body">
        <ul class="list-group" style="height:100%">
          <li class="list-group-item" v-for="item in library">
            <span v-if="level == 'FORMPAGE'">{{ item.formpagename }}</span>
            <span v-else>
              {{ item.translation }}
            </span>
            <i class="fa fa-trash redish right-pull parent-popover pointer"
               style="font-size:1.3em;margin-left:10px"
               @click="removeItem(item)">
              <tooltip :tip="'DELETEFROMLIBRARY'" :placement="'LEFT'"></tooltip>
            </i>
            <i class="fa fa-plus-circle right-pull parent-popover pointer"
               style="font-size:1.3em;"
               @click="addItem(item)">
              <tooltip :tip="'ADDTOFORM'" :placement="'LEFT'"></tooltip>
            </i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Swal from "sweetalert2";

export default {
  name: "FormLibrary",
  data() {
    return {
      showFormlibrary: false,
      level          : ''
    }
  },
  components: {},
  computed  : {
    ...mapGetters( 'formModule', ['showEditFormpage', 'selectedForm'] ),
    ...mapGetters( 'formpageModule', ['selectedFormpage', 'library'] ),
  },
  methods   : {
    ...mapActions( 'formpageModule', ['getFormpages', 'getFormelements', 'copyFormpage', 'copyFormpageElement', 'removeFormpage'] ),
    toggleShow() {
      if ( !this.selectedForm.id ) return this.showFormlibrary = false;
      if ( this.showEditFormpage && !this.selectedFormpage ) return this.showFormlibrary = false;
      this.showFormlibrary = !this.showFormlibrary

      if ( !this.showFormlibrary ) return;

      this.refreshLibrary()
    },
    addItem( item ) {
      let self = this
      self.setLevel()
      if ( this.level == 'FORMPAGE' ) {
        let payload = {}
        payload.formpageID = item.id
        payload.library = 0
        this.copyFormpage( payload )
            .then( function () {
              self.getFormpages( 0 )
              ra.toasterMessage( 'The formpage was succesfully added to the library', 'success' )
            } )
      } else {
        let payload = {};
        payload.languageCode = self.languageCode
        payload.formpageID = self.selectedFormpage.id
        payload.selectedElementID = item.id
        payload.library = 0
        self.copyFormpageElement( payload )
            .then( () => {
              self.getFormelements(0);
              ra.toasterMessage( 'element sucessfully added', 'success' )
            } )
      }
    },
    removeItem( item ) {
      let self = this

      Swal.fire( {
        title            : 'The item will be deleted from the library, this can not be undone, continue?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( result.isConfirmed ) {
          self.removeFormpage( item.id )
              .then( () => {
                self.refreshLibrary()
                ra.toasterMessage( 'The item was succesfully removed from the library', 'success' )
              } )
        }
      } )
    },
    setLevel() {
      this.level = this.showEditFormpage ? 'ELEMENT' : 'FORMPAGE'
    },
    refreshLibrary() {
      this.setLevel()
      if ( this.level == 'FORMPAGE' ) {
        this.getFormpages( 1 )
      } else {
        this.getFormelements( 1 )
      }
    }
  }
}
</script>

<style scoped>

</style>