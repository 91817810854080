<template>
  <category-article v-if="showCategoryArticle"
                    :articles="linkedArticles"
                    @doClickAction="saveCategoryTree"
                    @detachArticle="detachArticle"
                    @toggleCategoryArticle="toggleCategoryArticle"></category-article>
  <draggable v-model="items"
             ghost-class="ghost"
             tag="transition-group"
             @end="reorderTheCategories"
             :component-data="{name: 'flip-list', type: 'transition'}"
             item-key="id">

    <template #item="{element}">
      <li class="list-group-item"
          :class="{'selected-list-item-light': followup && element.id == followup.categoryitem_id}">
        <div class="input-group">
          <input class="form-control form-control-sm"
                 @keyup="saveTheCategoryItem(element)"
                 v-model="element.translation.translation"/>
          <div class="input-group-append pointer"
               style="position:relative"
               @click="showTranslator(element)">
            <span class="input-group-text bg-transparent border-left-0">
              <i class="far fa-flag"></i>
            </span>
          </div>
          <div class="input-group-append pointer"
               style="position:relative"
               @click="showTheUploader(element)">
            <span class="input-group-text bg-transparent border-left-0">
              <img v-if="element.imagepath"
                   :src="element.imagepath" height="22"/>
              <i v-else class="far fa-image"></i>
            </span>
          </div>
          <div class="input-group-append pointer"
               style="position:relative"
               @click="toggleCategoryArticle(element)">
            <span class="input-group-text bg-transparent border-left-0">
              <i class="far fa-file-alt"></i>
            </span>
          </div>
          <div class="input-group-append pointer"
               style="position:relative"
               @click="removeItem(element)">
            <span class="input-group-text bg-transparent border-left-0">
              <i class="fa fa-trash redish"></i>
            </span>
          </div>
          <div class="input-group-append pointer"
               style="position:relative"
               @click="followUp(element)">
            <span class="input-group-text bg-transparent border-left-0">
              <i class="fas fa-arrow-right"></i>
            </span>
          </div>
        </div>
      </li>
    </template>
  </draggable>


  <!--  <ul class="list-group">-->
  <!--    -->
  <!--  </ul>-->
  <hr/>
  <button class="btn btn-success"
          @click="addNewCategoryitem"
          :disabled="selectedCategoryID == 0">
    <translation :t="'NEWCATEGORYITEM'"></translation>
  </button>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Upload from "@/components/widgets/Upload"
import draggable from 'vuedraggable'
import TreeModal from "../../widgets/TreeModal"
import CategoryArticle from "./CategoryArticle"
import Swal from "sweetalert2"

export default {
  name : "CategoryItems",
  props: ['items'],
  data() {
    return {
      showCategoryArticle: false,
      linkedArticles: [],
      selectedItem       : {
        id     : null,
        timeout: null
      }
    }
  },
  components: {CategoryArticle, TreeModal, Upload, draggable, Swal},
  computed  : {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'categoryModule', ['selectedCategoryID', 'followup'] )
  },
  methods   : {
    ...mapActions( 'categoryModule', ['getCategories', 'reorderCategories', 'setFollowup', 'addCategoryItem', 'attachCategoryTree', 'saveCategoryItem', 'detachCategoryTree', 'removeCategoryItem', 'getLinkedCategoryitemArticles'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    ...mapActions( 'uploadModule', ['setUploadID', 'setUploadType', 'setMultiUpload', 'showUploader', 'doUpload'] ),
    showTranslator( item ) {
      this.showHideTranslator( 'SHOW' )

      this.getTranslations( item.translation_id )
      this.setIdentifierID( item.translation_id )
      this.setMultiUpload( false )
      this.setToTranslate( item.translation ? item.translation.translation : '' )
    },
    toggleCategoryArticle( item ) {
      this.selectedItem = item
      this.showCategoryArticle = !this.showCategoryArticle
      this.getTheLinkedCategoryitemArticles()
    },
    getTheLinkedCategoryitemArticles() {
      if( ! this.selectedItem ) return
      this.getLinkedCategoryitemArticles(this.selectedItem.id)
          .then( result => {
            this.linkedArticles = result.data
          } )
    },
    saveCategoryTree( node ) {
      let payload = {}
      payload.categoryID = this.selectedItem.id
      payload.treeID = node.id

      this.attachCategoryTree( payload )
          .then( () => {
            this.getTheLinkedCategoryitemArticles()
          } )
    },
    addNewCategoryitem() {
      let self = this
      this.addCategoryItem( this.selectedCategoryID )
          .then( function () {
            self.getCategories( self.languageCode )
          } )
    },
    saveTheCategoryItem( categoryItem ) {
      let self = this
      if ( self.timeout ) {
        clearTimeout( self.timeout );
        self.timeout = null;
      }
      self.timeout = setTimeout( function () {
        let payload = {}
        payload.translationID = categoryItem.translation_id
        payload.categoryItemName = categoryItem.translation.translation
        payload.languageCode = self.languageCode

        self.saveCategoryItem( payload )
      }, 400 )
    },
    followUp( item ) {
      if ( item.categoryfollowup ) return this.setFollowup( item.categoryfollowup );

      this.setFollowup( {
        categoryitem_id: item.id,
        followuptype   : '',
        translation_id : null
      } );
    },
    showTheUploader( item ) {
      this.selectedItem = item
      this.setUploadID( item.id )
      this.setUploadType( 'CATEGORY' )

      this.showUploader( true )
    },
    setUploadFinished( link ) {
      this.selectedItem.imagepath = link.link
    },
    removeItem( item ) {
      let self = this
      Swal.fire( {
        title            : 'Are you sure you want to delete this category',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( !result.isConfirmed ) return

        self.removeCategoryItem( item.id )
            .then( function () {
              const index = self.items.indexOf( item );
              if ( index !== -1 ) self.items.splice( index, 1 );
            } )
      } )
    },
    detachArticle( articleID ) {
      let payload = {}
      payload.categoryID = this.selectedItem.id
      payload.treeID = articleID

      this.detachCategoryTree( payload )
          .then( () => {
            this.getCategories()
          } )
    },
    reorderTheCategories() {
      this.reorderCategories( this.items );
    }
  }
}
</script>

<style scoped>

</style>