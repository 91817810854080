/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setSourceNodeID( state, sourceNodeID ) {
        state.sourceNodeID = sourceNodeID
    },
    setArticles( state, payload ) {
        state.articles = payload
    },
    setSelectedTreenodeID( state, selectedTreenodeID ) {
        state.selectedTreenodeID = selectedTreenodeID
    },
    setArticleText( state, payload ) {
        state.articleText = payload
    },
    selectItemsWithShift(state, payload){
        state.shiftClickedItem = payload.itemID
    },
    selectItemsWithMeta( state, payload ) {
        if ( payload.itemID == 0 ) return state.selectedItems = []

        let index = state.selectedItems.findIndex( item => item == payload.itemID );

        if ( index != -1 && ! payload.bypassUnselect  ) {
            state.selectedItems.splice( index, 1 )
        } else  {
            state.lastSelectedItem = payload.itemID
            state.selectedItems.push( payload.itemID )
        }
    },
    setSelectedArticlenodeID(state, nodeID){
        state.selectedArticlenodeID = nodeID
    },
    clearSelectedItems(state){
        state.selectedItems = []
    },
    setRefreshSource( state, source ) {
        state.refreshSource = source
    }
}