<template>
  <span v-if="showRevisions">
  <div id="upload-backdrop" class="my-backdrop"></div>
  <div id="upload-popup" class="my-popup">
    <div class="my-popup-header">
      <h3>
        <translation :t="'REVISIONS'"></translation>
        <button class="close" @click="toggleRevisions">x</button>
      </h3>
    </div>
    <div class="my-popup-body">
      <div class="revision-scroller">
        <div class="col-12 mt-3" v-for="revision in revisions">
            <div class="card bg-light">
              <div class="card-header">
                <div>
                  <strong><translation :t="'REVISEDBY'"></translation>:</strong> {{ revision.user.fullname }}
                </div>
                <div>
                  <strong><translation :t="'REVISIONDATE'"></translation>:</strong> {{ revision.revisiondate }}
                </div>

              </div>
              <div class="card-body">
                <div class="fr-view" v-html="revision.translation"></div>
              </div>
              <div class="card-footer">
                <button class="btn btn-danger right-pull"
                        @click="replaceContent(revision.translation)">
                  <translation :t="'REPLACECONTENT'"></translation>
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  </span>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "../controls/Translation"
import Swal from "sweetalert2"

export default {
  name      : "Revisions",
  components: {Translation},
  emits     : ['replaceContent'],
  computed  : {
    ...mapGetters( 'editorModule', ['showRevisions', 'revisions'] )
  },
  methods   : {
    ...mapActions( 'editorModule', ['toggleRevisions'] ),
    replaceContent(content){
      let self = this

      self.toggleRevisions()
      Swal.fire( {
        title            : 'replace current editor content?',
        showDenyButton   : true,
        confirmButtonText: 'Yes',
        denyButtonText   : 'No',
        customClass      : {
          confirmButton: 'order-2',
          denyButton   : 'order-3',
        }
      } ).then( result => {
        if ( ! result.isConfirmed ){
          this.toggleRevisions()
          return
        }
        self.$emit('replaceContent', content)
      } )
    }
  }
}
</script>

<style scoped>
.revision-scroller{
  height:500px;
  overflow-y:auto;
}
.my-popup{
  /*z-index:925;*/
}
</style>