<template>
  <div class="my-backdrop"></div>
  <div class="my-popup">
    <div class="my-popup-header">
      <h3>
        <translation :t="'ARTICLES'"></translation>
        <button class="close" @click="toggleCategoryArticle">x</button>
      </h3>
    </div>
    <div class="my-popup-body">
      <div class="row">
        <div class="col-4">
          <ul class="linked-articles">
            <li v-for="article in articles">
              <span class="fa fa-trash redish" @click="detachArticle(article.id)"></span>
              <span>{{ article.translation }}</span>
            </li>
          </ul>
        </div>
        <div class="col-8">
          <ul style="overflow:auto;height:500px;">
            <tree-node
                v-for="node in nodes"
                @doClickAction="clickaction"
                :node="node"></tree-node>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import TreeNode from "@/components/widgets/partials/TreeNode"

export default {
  name : "CategoryArticle",
  props: ['articles'],
  emits: ['toggleCategoryArticle', 'doClickAction', 'detachArticle'],
  data() {
    return {
      nodes: [],
      timer:null,
      clicks: 0
    }
  },
  components: {TreeNode},
  computed  : {},
  created() {
    this.getTree( 'ARTICLESTREE' )
        .then( result => {
          this.nodes = result.data
        } )
  },
  methods: {
    ...mapActions( 'treeModule', ['getTree'] ),
    toggleCategoryArticle() {
      this.$emit( 'toggleCategoryArticle', null )
    },
    clickaction( node ) {
      this.$emit( 'doClickAction', node )
    },
    detachArticle( articleID ) {
      this.$emit( 'detachArticle', articleID )
      let aID = this.articles.findIndex( a => {
        return a.id == articleID
      } )
      this.articles.splice( aID, 1 )
    }
  }
}
</script>

<style scoped>
.my-popup {
  width: 700px;
}

.linked-articles {
  height:500px;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow:auto;
}

.linked-articles > li {
  font-size: 1.1em;
}

.linked-articles > li span:first-child {
  margin-right: 15px;
}
</style>