/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    formpages(state){
        return state.formpages
    },
    library(state){
        return state.library
    },
    formpageID(state){
        return state.formpageID
    },
    elementtypes(state){
        return state.elementtypes
    },
    formelements(state){
        return state.formelements
    },
    selectedFormpage(state){
        return state.selectedFormpage
    },
    selectedElement(state){
        return state.selectedElement
    },
    showInfoTextPanel(state){
        return state.showInfoTextPanel
    }

}
