<template>
  <div class="my-backdrop"></div>
  <div class="my-popup">
    <div class="my-popup-header">
      <h4>{{ categoryName }} <i class="close pointer"
                                @click="closePopup">x</i></h4>
    </div>
    <div class="my-popup-body"
         v-if="milestoneCategory">
      <input-translation
          class="mt-2"
          :identifierID="milestoneCategory.translation_id"
          :toTranslate="categoryName"
          @saveTranslation="saveCategoryName"></input-translation>
      <div class="row mt-2">
        <div class="col-6">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <translation :t="'SUFFICIENTLY'"></translation>
                  </span>
                </div>
                <input class="form-control"
                       style="text-align:right"
                       type="number"
                       step="1"
                       v-model="milestoneCategory.milestone_domain_category.sufficiently"
                       @keyup="saveTheMilestoneDomainCategory"
                       @change="saveTheMilestoneDomainCategory"
                       @click="saveTheMilestoneDomainCategory"
                       min="0">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>advice</label>
              <input-translation
                  :textarea="true"
                  :rows="3"
                  :nonEmitSave="true"
                  :saveOnEnter="true"
                  :toTranslate="milestoneCategory.milestone_domain_category.translation ? milestoneCategory.milestone_domain_category.translation.translation : ''"
                  :identifierID="milestoneCategory.milestone_domain_category.translation_id"></input-translation>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-8">
              <upload class=""
                      @uploadFinished="uploadFinished"
                      :simple="true"
                      :simpleText="'DRAGDROPIMAGE'"
                      :uploadData="uploadData"></upload>
            </div>
            <div class="col-4">
              <button class="btn btn-full btn-small btn-primary mt-4"
                      @click="pictureSelector">
                picture browser
              </button>
            </div>
          </div>
          <div class="row">
            <img :src="milestoneCategory.imageurl"
                 style="max-width:90%;max-height:80px;"
                 v-if="milestoneCategory"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <picture-selector
      v-if="showPictureSelector"
      @selectPicture="selectPicture">
  </picture-selector>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import InputTranslation from "../../controls/InputTranslation"
import Upload from '@/components/widgets/Upload.vue'
import MilestoneIndicators from "./MilestoneIndicators";
import PictureSelector from "../../widgets/pictureSelector";

export default {
  name : "MilestoneCategory",
  props: ['milestoneDomainID', 'milestoneCategoryID'],
  emits: ['closePopup'],
  data() {
    return {
      milestoneCategory  : false,
      categoryName       : '',
      timeout            : null,
      showPictureSelector: false,
      uploadData         : {
        milestoneID: this.milestoneCategoryID,
        treeEntity : 'MILESTONECATEGORY'
      }
    }
  },
  components: {PictureSelector, MilestoneIndicators, InputTranslation, Upload},
  computed  : {
    ...mapGetters( ['languages', 'languageCode',] )
  },
  created() {
    let payload = {}
    payload.milestoneDomainID = this.milestoneDomainID
    payload.milestoneCategoryID = this.milestoneCategoryID
    this.getMilestoneCategory( payload )
        .then( result => {
          this.milestoneCategory = result.data
          this.categoryName =
              this.milestoneCategory.translation ? this.milestoneCategory.translation.translation : ''
          this.setUploadID( 0 )
          this.setUploadType( 'MILESTONE' )
        } )

  },
  methods: {
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'modalModule', ['getImagesByChapter'] ),
    ...mapActions( 'milestoneModule', ['getMilestoneCategory', 'newMilestoneIndicator', 'saveMilestonecategory', 'saveMilestoneDomainCategory', 'saveCategoryPicture','removeMilestone'] ),
    ...mapActions( 'uploadModule', ['setUploadID', 'setUploadType', 'setMultiUpload', 'showUploader', 'doUpload'] ),
    saveCategoryName( categoryName ) {
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = this.milestoneCategory.translation_id
      this.categoryName = payload.translation = categoryName
      this.saveTranslationByIdentifier( payload )
    },
    saveTheMilestoneDomainCategory() {
      if ( this.timeout ) {
        clearTimeout( this.timeout );
        this.timeout = null;
      }
      this.timeout = setTimeout( _ => {
        let payload = {}
        payload.milestoneDomainCategoryid = this.milestoneCategory.milestone_domain_category.id
        payload.sufficiently = this.milestoneCategory.milestone_domain_category.sufficiently
        this.saveMilestoneDomainCategory( payload )
      }, 400 );
    },
    uploadFinished( link ) {
      this.milestoneCategory.imageurl = link.link
    },
    checkScore() {
      if ( this.milestoneCategory.poor_score > this.milestoneCategory.moderate_score )
        this.milestoneCategory.moderate_score = this.milestoneCategory.poor_score

      if ( this.milestoneCategory.moderate_score > this.milestoneCategory.sufficient_score )
        this.milestoneCategory.sufficient_score = this.milestoneCategory.moderate_score

      this.saveMilestonecategory( this.milestoneCategory )
    },
    setActivecategory() {
      if ( this.milestoneCategory.active ) return this.milestoneCategory.active = false

      this.$emit( 'setActivecategory', this.milestoneCategory.id )
    },
    addIndicator() {
      this.newMilestoneIndicator( this.milestoneCategory.id )
          .then( result => {
            this.milestoneCategory.milestoneindicators.push( result.data )
          } )
    },
    selectPicture( selectedPicture ) {
      this.showPictureSelector = false
      if( ! selectedPicture ) return
      this.milestoneCategory.imageurl = selectedPicture
      let payload = {}
      payload.milestonCategoryID = this.milestoneCategory.id
      payload.imageurl = selectedPicture
      this.saveCategoryPicture(payload)
    },
    pictureSelector() {
      this.getImagesByChapter( 'MILESTONES' )
      this.showPictureSelector = true
    },
    closePopup() {
      this.$emit( 'closePopup' )
    },
    removeMilestonecategory() {
      if ( this.milestoneCategory.milestoneindicators.length > 0 ) return
      let payload = {}
      payload.milestoneID = this.milestoneCategory.id
      payload.type = 'MILESTONECATEGORY'

      this.removeMilestone( payload )
          .then( _ => {
            if( result.data.success )
              this.$el.parentNode.removeChild( this.$el );

            ra.toasterMessage( 'Category has indicators, remove them first', 'error' )
          } )
    }
  }
}
</script>

<style scoped>


.right-icon {
  float: right;
  font-weight: 700;
  line-height: 1;
  opacity: .8;
  cursor: pointer;
}

.right-icon:hover {
  opacity: 1;
}
</style>