/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    forms(state){
        return state.forms;
    },
    formID(state){
        return state.formID
    },
    selectedForm(state){
        return state.selectedForm
    },
    showEditFormpage(state){
        return state.showEditFormpage
    }
}
