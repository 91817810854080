<template>
  <icon-selector
      @chosenicon="chosenicon"
      v-if="showIconselector">
  </icon-selector>
  <link-selector
      @chosenLink="chosenLink"
      :selectedLink="button.action.link"
      v-if="showLinkSelector">
  </link-selector>
  <button :class="'blix-button_' + buttonClass"
          @click.stop="showActionselector">
    <i
        class="material-icons"
       @click.stop="toggleIconselector">
      {{ button.buttonicon }}
    </i>
    <label>
      <input-translation
          style="background-color:#fff"
          @click.stop
          @keyup.stop
          v-if="editButton"
          :class="'blix-button_translation_' + buttonClass"
          :saveOnEnter="true"
          :identifierID="button.translation_id"
          :toTranslate="button.translation"
          @saveTranslation="saveButton">
      </input-translation>
      <label v-else @click.stop="toggleButtonName"
             class="pointer">
        {{ button.translation }}
      </label>
    </label>
    <div v-if="button.description_id" :class="'blix-description-holder_' + buttonClass">

      <input-translation
          style="background-color:#fff;width:350px"
          v-if="editDescription"
          :textarea="true"
          :rows="3"
          :saveOnEnter="true"
          :identifierID="button.description_id"
          :toTranslate="button.description"
          @saveTranslation="saveDescription">
      </input-translation>
      <p v-else
          :class="'blix-description_' + buttonClass"
          @click="editDescription = true">
        {{ button.description }}
      </p>

    </div>

    <i class="fa fa-trash redish"
       v-if="withDelete"
       @click="removeButton"
       :class="'blix-button-trash_' + buttonClass"></i>
    <span class="fa fa-link blix-button-link"
          @click="showLinkSelector = true"></span>
  </button>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import InputTranslation from "@/components/controls/InputTranslation"
import IconSelector from "@/components/widgets/IconSelector"
import LinkSelector from '@/components/widgets/LinkSelector'

export default {
  name : "ActionButtonDefault",
  emits: ['removeButton'],
  props: ['button', 'pageEntity', 'applicationpageID', 'buttonClass', 'withDelete'],
  data() {
    return {
      editButton      : false,
      IconSelector    : false,
      showIconselector: false,
      showLinkSelector: false,
      editDescription : false
    }
  },
  components: {InputTranslation, IconSelector, LinkSelector},
  computed  : {
    ...mapGetters( ['languageCode'] ),
  },
  watch     : {},
  methods   : {
    ...mapActions( 'translate', ['saveTranslationByIdentifier'] ),
    ...mapActions( 'applicationModule', ['saveApplicationpageButton'] ),
    showActionselector( button ) {
      return
//       this.toggleArticlesSelector( button.uuid )
//       this.toggleButtonActionselector(button.uuid)
//       this.selectedButton = button
    },
    toggleButtonName() {
      this.editButton = true
    },
    toggleIconselector() {
      this.showIconselector = true
    },
    toggleLinkSelector() {
      this.showLinkSelector = true
    },
    chosenicon( icon ) {
      this.showIconselector = false

      if ( !icon ) return;

      this.button.buttonicon = icon
      this.saveButtondetails()
    },
    chosenLink( link, pageType ) {
      this.showLinkSelector = false

      if ( !link ) return;

      if( pageType){
        this.button.action.link = link.id
        this.button.action.type = 'ROUTE'
        this.saveButtondetails()
      }else{
        this.button.action.link = link
        this.button.action.type = 'ACTION'
        this.saveButtondetails()
      }
    },
    saveButtondetails() {
      let payload = {}
      payload.applicationpageID = this.applicationpageID
      payload.button = this.button

      this.saveApplicationpageButton( payload )
    },
    saveButton( translation, translationID ) {
      this.editButton = false
      this.button.translation = translation

      this.saveButtonText( translation, translationID )
    },
    saveDescription( translation, translationID ){
      this.editDescription = false
      this.button.description = translation

      this.saveButtonText( translation, translationID )
    },
    saveButtonText( translation, translationID ){
      let payload = {}
      payload.languageCode = this.languageCode
      payload.identifierID = translationID
      payload.translation = translation

      this.saveTranslationByIdentifier( payload )
    },
    saveButtonLink() {

    },
    removeButton() {
      this.$emit( 'removeButton', this.button )
    }
  }
}
</script>

<style scoped>
.blix-button_DEFAULT {
  position: relative;
  width: 124px;
  height: 124px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 60px;
  border: none;
  border-radius: 5px;
}

.blix-button_DEFAULT i {
  font-size: 3em;
  color: #f16a02;
  position: relative;
  top: -5px;
}

.blix-button_DEFAULT label {
  text-transform: uppercase;
  color: #888;
  display: block;
  font-weight: normal;
}

.blix-button_LISTITEM {
  position: relative;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
}

.blix-button_LISTITEM i {
  font-size: 2em;
  color: #000;
  position: relative;
  top: 5px;
  margin-right: 20px;
}

.blix-button_LISTITEM label {
  color: #000;
  font-weight: bold;
  font-size: 1em;
}

.blix-button-trash_LISTITEM {
  font-size: 1.3em !important;
  color: #f64f60 !important;
  float: right;
}

.blix-button_LEFTSIDE_SQUARE {
  width: 130px;
  height: 100px;
  background-color: transparent;
  text-align: center;
  float: left;
  position: relative;
}

.blix-button_LEFTSIDE_SQUARE button {
  border: none;
}

.blix-leftside-panel-buttonholder button.blix-button_LEFTSIDE_SQUARE:nth-child(1n) {
  border-bottom: 1px solid #fc9649;
  border-right: 1px solid #fc9649;
  border-left: none;
  border-top: none;
}

.blix-leftside-panel-buttonholder button.blix-button_LEFTSIDE_SQUARE:nth-child(2n) {
  border-bottom: 1px solid #fc9649;
  border-right: none;
  border-left: none;
  border-top: none;
}

.blix-leftside-panel-buttonholder button.blix-button_LEFTSIDE_SQUARE:nth-last-child(-n+2),
.blix-leftside-panel-buttonholder button.blix-button_LEFTSIDE_SQUARE:last-child {
  border-bottom: none !important;
}

.blix-button_LEFTSIDE_SQUARE i {
  font-size: 2em;
  color: #fff;
  position: relative;
  display: block;
}

.blix-button_LEFTSIDE_SQUARE label {
  color: #fff;
  font-weight: normal;
  font-size: 1em;
  position: relative;
  top: 5px;
}

.blix-button-trash_LEFTSIDE_SQUARE {
  font-size: 1em !important;
  color: #fff !important;
  position: absolute !important;
  bottom: 5px !important;
  right: 5px;
}


.blix-button_LEFTSIDE_RETANGULAR {
  padding-top: 10px;
  width: 100%;
  background-color: transparent;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #fc9649;
  border-right: none;
  border-left: none;
  border-top: none;
  color: #fff;
  margin-bottom: 0;
}

.blix-button_LEFTSIDE_RETANGULAR label {
  font-weight: normal;
  font-size: 1em;
  margin-left: 10px;
}

.blix-button_LEFTSIDE_RETANGULAR i {
  font-size: 1.3em;
  color: #fff;
}

.blix-button-trash_LEFTSIDE_RETANGULAR {
  font-size: 1em !important;
  color: #fff !important;
  position: absolute !important;
  bottom: 15px !important;
  right: 5px;
}

.blix-button_DEFAULT .blix-button-link {
  position: absolute !important;
  bottom: 5px !important;
  right: 25px;
  font-size: 12px !important;
  color: #bbb;
}

.blix-button_LISTITEM .blix-button-link {
  position: absolute !important;
  top: 10px !important;
  right: 55px;
  font-size: 12px !important;
  color: #666;
}

.blix-button_LEFTSIDE_SQUARE .blix-button-link {
  position: absolute !important;
  bottom: 5px !important;
  right: 25px;
  font-size: 12px !important;
  color: #fff;
}


.blix-button_NAVIGATION_LIST {
  position: relative;
  width: 100%;
  height: 95px;
  margin-top: 15px;
  border: none;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  overflow: hidden;
}

.blix-button_NAVIGATION_LIST i {
  font-size: 4em;
  color: 555;
  position: absolute;
  top: 15px;
  left: -0px;
  width: 50px;
}

.blix-button_NAVIGATION_LIST label {
  color: #333;
  position: absolute;
  right: 0px !important;
  font-weight: bold;
  top: 5px;
  text-align: left;
  width: 89%;
}

.blix-description-holder_NAVIGATION_LIST {
  color: #777;
  position: absolute;
  width: 79%;
  right: 0;
  top: 30px;
}

.blix-description_NAVIGATION_LIST {
  text-align: left;
}

.blix-button_NAVIGATION_LIST .blix-button-link {
  position: absolute !important;
  bottom: 5px !important;
  right: 5px;
  font-size: 12px !important;
  /*color:#fff;*/
}

.blix-button_translation_NAVIGATION_LIST {
  position: relative;
  top: -15px;
  left: 15px;
  width: 160%;
}


</style>