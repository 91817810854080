<template>
  <!-- <translators></translators> -->
  <div v-if="showMilestoneOverview"
       class="milestone-overview">
    <div class="close-circle" @click="showMilestoneOverview = false">x</div>
    <br>
    <div v-html="overviewHtml"></div>
  </div>
  <milestone-category
      v-if="showMilestonecategory"
      @closePopup="closePopup"
      :milestoneDomainID="domain.id"
      :milestoneCategoryID="milestoneCategoryID">
  </milestone-category>
  <div class="row">
    <div class="col-6 mt-4">
      <div class="card">
        <div class="card-header justify-content-between align-items-center">
          <h4 class="card-title">
            <translation :t="'FORMS'"></translation>
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <select-edit
                  @showTranslator="showTranslator"
                  @setItem="setForm"
                  @saveItem="saveTheForm"
                  @newItem="newForm"
                  @removeItem="removeTheForm"
                  :deleteAble="formDeleteAble"
                  :items="compForms"></select-edit>
            </div>
          </div>
          <div class="row"
               v-if="formID">
            <ul class="col-12 countries">
              <li v-for="milestoneCountry in milestoneCountries">
                <translation :t="milestoneCountry.identifier"></translation>
              </li>
            </ul>
          </div>
          <div class="row"
               style="margin-top:15px;">
            <div class="col-12">
              <button class="btn btn-warning pull-right"
                      style="float:right;"
                      :disabled="! formID"
                      @click="addDomain">
                <translation :t="'NEWCOUNTRY'"></translation>
              </button>
              <button class="btn btn-warning pull-right"
                      style="float:right;"
                      :disabled="! formID"
                      @click="addDomain">
                <translation :t="'NEWDOMAIN'"></translation>
              </button>
            </div>
          </div>
        </div>
      </div>
      <milestonedomains
          v-if="milestoneForm"
          :domains="milestoneForm.milestonedomains">
      </milestonedomains>
    </div>
    <div class="col-6 mt-4">
      <div class="card-body">
        <button class="btn btn-warning btn-sm"
                :disabled="! activeMilestoneCategoryID ||  ! activeMilestoneDomainID"
                @click="addMilestoneIndicator">
          <translation :t="'ADDINDICATOR'"></translation>
        </button>
        <button class="btn btn-primary btn-sm"
                :disabled="! formID"
                @click="showTheOverview">
          <translation :t="'SHOWOVERVIEW'"></translation>
        </button>
      </div>


      <draggable v-model="localIndicators"
                 v-if="milestoneIndicators.length > 0"
                 ghost-class="ghost"
                 tag="transition-group"
                 @end="reorderIndicators"
                 :component-data="{name: 'flip-list', type: 'transition'}"
                 item-key="id">
        <template #item="{element}">
          <div class="form-page-block">
            <milestone-indicator
                :milestoneIndicator="element"
                :activeIndicators="activeIndicators"
                @removeIndicator="removeIndicator"
                @setActiveIndicators="setActiveIndicators">
            </milestone-indicator>
          </div>
        </template>
      </draggable>
      <!--      <milestone-indicator-->
      <!--          v-for="milestoneIndicator in milestoneIndicators"-->
      <!--          @setActiveIndicators="setActiveIndicators"-->
      <!--          :activeIndicators="activeIndicators"-->
      <!--          :milestoneIndicator="milestoneIndicator"></milestone-indicator>-->
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import SelectEdit from "@/components/controls/SelectEdit"
import Translators from "@/components/widgets/Translators.vue"
import Milestonedomains from '@/components/forms/milestones/Milestonedomains'
import MilestoneCategories from "@/components/forms/milestones/MilestoneCategories"
import MilestoneCategory from "../../components/forms/milestones/MilestoneCategory"
import MilestoneIndicator from "../../components/forms/milestones/MilestoneIndicator"
import draggable from 'vuedraggable'

export default {
  name : "Milestones",
  props: [],
  emits: [],
  data() {
    return {
      formID                    : null,
      editMode                  : false,
      formname                  : '',
      formType                  : 'MILESTONE',
      showMilestonecategory     : false,
      milestoneCategoryID       : null,
      activeIndicators          : [],
      countryID                 : 0,
      milestoneCountries        : [],
      selectedMilestoneCountryID: null,
      localIndicators           : [],
      overviewHtml              : '',
      showMilestoneOverview              : false
    }
  },
  components: {
    MilestoneIndicator,
    draggable,
    MilestoneCategory,
    SelectEdit,
    Translators,
    Milestonedomains,
    MilestoneCategories
  },
  computed  : {
    ...mapGetters( ['languages', 'languageCode'] ),
    ...mapGetters( 'formModule', ['forms', 'selectedForm'] ),
    ...mapState( 'milestoneModule', ['milestoneForm', 'milestoneIndicators', 'activeMilestoneCategoryID', 'activeMilestoneDomainID'] ),
    formDeleteAble() {
      return true
    },
    compForms() {
      let compForms = []
      this.forms.forEach( f => {
        f.name = f.formname
        compForms.push( f )
      } )
      return compForms
    },
  },
  watch     : {
    milestoneIndicators() {
      this.localIndicators = this.milestoneIndicators
    }
  },
  created() {
    this.getForms( this.formType );
  },
  methods: {
    ...mapActions( 'formModule', ['selectForm', 'getForms', 'resetForm', 'getForm', 'saveForm', 'removeForm'] ),
    ...mapActions( 'milestoneModule', [
      'showOverview',
      'getMilestoneForms',
      'getMilestoneForm',
      'newDomain',
      'newMilestonecategory',
      'newMilestoneIndicator',
      'getMilestoneIndicators',
      'setMilestoneIndicators',
      'saveMilestoneIndicatorOrder'] ),
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'getTranslations', 'setIdentifierID'] ),
    showTranslator() {
      if ( !this.selectedForm ) return

      this.showHideTranslator( 'SHOW' )

      this.getTranslations( this.selectedForm.form_id )
      this.setToTranslate( this.selectedForm.formname )
      this.setIdentifierID( this.selectedForm.form_id )
    },
    setForm( formID ) {
      this.selectForm( formID, 0 )
      this.formID = formID
      this.countryID = 0

      this.refreshForm()
    },
    setActiveIndicators( milestoneIndicatorID ) {
      let index = this.activeIndicators.findIndex( ai => ai === milestoneIndicatorID )
      if ( index === -1 ) {
        this.activeIndicators.push( milestoneIndicatorID )
      } else {
        this.activeIndicators.splice( index, 1 );
      }
    },
    refreshForm() {
      this.getMilestoneForms( this.formID )
          .then( result => {
            this.milestoneCountries = result.data
            let defaultMilestoneCountry =
                    this.milestoneCountries.find( mc => mc.country_id == 0 )
            this.selectedMilestoneCountryID = defaultMilestoneCountry.id

            this.refreshMilestoneForm()
          } )
    },
    refreshMilestoneForm() {
      this.getMilestoneForm( this.selectedMilestoneCountryID )
    },
    newForm() {
      this.selectForm( 0 )
    },
    addDomain() {
      this.newDomain( this.selectedMilestoneCountryID )
          .then( _ => {
            this.refreshForm()
          } )
    },
    addCategory() {
      this.newMilestonecategory( this.selectedMilestoneCountryID )
          .then( result => {
            this.milestoneCategoryID = result.data.id
            this.showMilestonecategory = true
          } )
    },
    addMilestoneIndicator() {
      let payload = {}
      payload.milestoneDomainID = this.activeMilestoneDomainID
      payload.milestoneCategoryID = this.activeMilestoneCategoryID

      this.newMilestoneIndicator( payload )
          .then( _ => {
            this.getMilestoneIndicators( payload )
                .then( result => {
                  this.setMilestoneIndicators( result.data )
                } )
          } )
    },
    saveTheForm( payload ) {
      payload.formname = payload.name
      payload.description = 'PLACEHOLDER'
      payload.languageCode = this.languageCode
      payload.formtype = this.formType
      payload.cycle = 0
      this.saveForm( payload )
          .then( result => {
            this.getForms( this.formType )
                .then( () => {
                  this.selectForm( result.data.id )
                  this.formID = result.data.id
                } )
          } )
    },
    reorderIndicators() {
      let indicators = []
      let order = 0
      this.localIndicators.forEach( mi => {
        order += 10
        indicators.push( {id: mi.id, order: order} )
      } )
      this.saveMilestoneIndicatorOrder( indicators )
    },
    showTheOverview() {
      this.showOverview( this.selectedMilestoneCountryID )
          .then( result => {
            console.log( result.data );
            this.overviewHtml = result.data.html
            this.showMilestoneOverview = true
          } )
    },
    removeIndicator( milestoneIndicatorID ) {
      let index = this.localIndicators.findIndex( li => li.id == milestoneIndicatorID )

      this.localIndicators.splice( index, 1 )
    },
    closePopup() {
      this.showMilestonecategory = false
      this.refreshForm()
    },
    removeTheForm( formID ) {
      if ( !formID ) return
      this.formID = null
      this.removeForm( formID, this.formType )
      this.getForms( this.formtype )
    }
  }
}
</script>

<style scoped>
.countries {
  list-style: none;
}

.countries li {
  float: left;
  padding: 4px 6px;
  background-color: #0abb87;
  color: #fff;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.milestone-overview {
  position: absolute;
  width: 80%;
  height: 80%;
  z-index: 999;
  background-color: #fff;
  padding: 15px;
  border: 3px solid #a2a2a2;
  border-radius: 25px;
  margin-top: 30px;
  overflow:auto;
}
.close-circle{
  cursor:pointer;
  position: relative;
  float:right;
  margin-bottom:10px;
  padding:15px;
  font-size:2em;
  top:-35px;
  z-index:999999999;
}
</style>



