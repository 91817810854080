<template>
  <div class="input-group mb-3  input-group-sm">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <translation :t="'EQUALANDGREATER'"></translation>
      </span>
    </div>
    <input type="number"
           min="0"
           class="form-control align-right"
           :class="{'is-invalid':invalidCondition}"
           v-model="flowelementcondition.greater_and_equal">
    <div class="input-group-append">
      <span class="input-group-text">
        <translation v-if="flowelementcondition.date_comparator_greater == 'YEAR'"
                     @click="flowelementcondition.date_comparator_greater = 'MONTH'"
                     :t="'YEAR'"></translation>
        <translation v-else
                     @click="flowelementcondition.date_comparator_greater = 'YEAR'"
                     :t="'MONTH'"></translation>
      </span>
    </div>
    <div class="input-group-append">
      <span class="input-group-text">
        <strong>
          <translation :t="'AND'"></translation>
        </strong>
      </span>
    </div>
    <div class="input-group-append">
      <span class="input-group-text">
        <translation :t="'SMALLERTHAN'"></translation>
      </span>
    </div>
    <input type="number"
           class="form-control"
           :min="flowelementcondition.greater_and_equal"
           :class="{'is-invalid':invalidCondition}"
           v-model="flowelementcondition.smaller_than">
    <div class="input-group-append">
      <span class="input-group-text">
        <translation v-if="flowelementcondition.date_comparator_smaller == 'YEAR'"
                     @click="flowelementcondition.date_comparator_smaller = 'MONTH'"
                     :t="'YEAR'"></translation>
        <translation v-else
                     @click="flowelementcondition.date_comparator_smaller = 'YEAR'"
                     :t="'MONTH'"></translation>
      </span>
    </div>
    <select class="form-control form-control-sm"
            v-model="flowelementcondition.formpage_id">
      <option value="0">
        <translation :t="'SELECTIT'" value="0"></translation>
      </option>
      <option v-for="formpage in formpages"
              :value="formpage.id">
        {{ formpage.formpagename }}
      </option>
    </select>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary redish pointer"
              @click="removeTheCondition">
        <strong>X</strong>
      </button>
    </div>
  </div>
  <div class="invalid-feedback" style="position:relative;top:-15px;"
       v-if="invalidCondition">
    <translation :t="'ERRORFORMFLOW'"></translation>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "Datecondition",
  props: ['flowelementcondition'],
  emits: ['refreshFlow', 'removeUnsavedCondition'],
  data() {
    return {
      timeout: null
    }
  },
  components: {},
  computed  : {
    ...mapGetters( 'formpageModule', ['formpages'] ),
    invalidCondition() {
      let months1 =
              this.flowelementcondition.date_comparator_greater == 'MONTH'
                  ? this.flowelementcondition.greater_and_equal
                  : this.flowelementcondition.greater_and_equal * 12
      let months2 =
              this.flowelementcondition.date_comparator_smaller == 'MONTH'
                  ? this.flowelementcondition.smaller_than
                  : this.flowelementcondition.smaller_than * 12

      if ( Number( months1 ) < 0 ) return true
      if ( Number( months2 ) <= Number( months1 ) || !months2 ) return true
      if ( this.flowelementcondition.formpage_id == 0 || !this.flowelementcondition.formpage_id ) return true

      return false;
    }
  },
  created() {
  },
  methods: {
    ...mapActions( 'formpageModule', ['saveCondition', 'removeCondition'] ),
    removeTheCondition() {
      let self = this

      if( self.flowelementcondition.id == 0)
        return self.$emit('removeUnsavedCondition', self.flowelementcondition)
    }
  }
}
</script>

<style scoped>

</style>