<template>
  <div class="row">
    <label class="col-1 control-label">
      <translation :t="'FORMS'"></translation>
    </label>
    <div class="col-5">
      <div class="input-group">
        <select class="form-control form-control-sm bg-white"
                v-model="formID"
                @change="addTheFormToApp">
          <option disabled value="">
            <translation :t="'SELECTIT'"></translation>
          </option>
          <option v-for="unselectedForm in unselectedForms" :value="unselectedForm.id">
            {{ unselectedForm.formname ? unselectedForm.formname.translation : 'NB' }}
          </option>
        </select>
        <div class="input-group-append">
          <button class="btn btn-success btn-sm"
                  type="button"
                  @click="addFormToApp">
            <translation :t="'ADDFORM2APP'"></translation>
          </button>
        </div>
      </div>
    </div>
    <ul class="col-6 selected-forms">
      <li v-for="selectedForm in selectedForms" class="item-tag">
        {{ selectedForm.formname ? selectedForm.formname.translation : 'NB' }}
        <button class="remove-tag" @click="removeTheFormFromApp(selectedForm)">x</button>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "SelectForm",
  props: [],
  emits: [],
  data() {
    return {
      formID :''
    }
  },
  components: {},
  computed  : {
    ...mapState( 'applicationModule', ['app', 'selectedForms', 'unselectedForms'] )
  },
  created() {
  },
  methods: {
    ...mapActions( 'applicationModule', ['addFormToApp', 'removeFormFromApp', 'getUnselectedForms'] ),
    addTheFormToApp() {
      let self = this
      if( ! self.formID ) return

      let payload = {}
      payload.applicationID = self.app.id
      payload.formID = self.formID

      self.addFormToApp( payload )
          .then( () => {
            self.getUnselectedForms(self.app.id)
          } )
    },
    removeTheFormFromApp(selectedForm) {
      let self = this
      let payload = {}
      payload.applicationID = self.app.id
      payload.formID = selectedForm.id

      self.removeFormFromApp( payload )
          .then( () => {
            self.getUnselectedForms(self.app.id)
          } )

    }
  }
}
</script>

<style scoped>
  .selected-forms{
    padding:0;
    list-style:none;
  }
  .selected-forms li{
    float:left;
    padding:5px 10px;
    background-color:#024959;
    color:#fff;
    border-radius:5px;
    margin-right:10px;
  }
  .selected-forms li button{
    border:0;
    padding:0;
    margin:0;
    color:#fff;
    position:relative;
    top:-6px;
    left:5px;
    font-size:1em;
  }
</style>