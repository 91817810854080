/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '@/plugins/axios'

export default {
    translations( context ) {
        let language = localStorage.getItem( 'language' )

        guest.get('/api/translations/' + language)
            .then(function(result){
                context.commit('setTranslations', result.data )
            })
    },
    getAllTranslations(context, languageCode){
        api.get('/api/allTranslations/' + languageCode)
            .then(function(result){
                context.commit('setAllTranslations', result.data)
            })
    },
    countries(context, language = false){
        if( ! language) language = localStorage.getItem( 'language' )
        api.get('/api/getcountries/' + language)
            .then(function(result){
                context.commit('setCountries', result.data)
            })
    },
    getTranslation(context, identifier ){
        const translated = context.state.translations.find(
            translation => translation.identifier == identifier )

        if ( !translated ) return 'NB'

        return translated.translation
    },
    getTranslationsByArray(context, translations){
        let languageCode = context.rootState.languageCode
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( translations ) )
        return api.post('/api/translation/getTranslationByArray/' + languageCode, formData)
    },
    saveTranslation(context, form){
        api.post('/api/savetranslation', form)
    },
    saveTranslationByIdentifier(context, payload){
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )

        api.post('/api/translation/saveTranslationByIdentifier', formData)
    }
}