<template>
  <!-- label for -->
  <div class="row">
    <div class="col-12">
      <label class="">
        <translation :t="'LABELFORCHECKBOX'"></translation>
      </label>
      <input-translation
          :identifierID="metaData.label.translated"
          :toTranslate="metaData.label.value"
          @saveTranslation="savelabel"></input-translation>
    </div>
  </div>
  <!-- end label for -->
  <!-- category choice -->
  <div class="row" v-if="metaData.itemtype.value == 'CATEGORY'">
    <div class="col-12">
      <hr/>
      <label>
        <translation :t="'CHOOSECATEGORY'"></translation>
      </label>
      <select class="form-control form-control-sm"
              @change="savecategoryID"
              v-model="categoryID">
        <option value="null">
          <translation :t="'SELECTITEM'"></translation>
        </option>
        <option v-for="category in categories" :value="category.id">
          {{ category.translation ? category.translation.translation : 'NB' }}
        </option>
      </select>
    </div>
  </div>
  <!-- end category choice -->

  <!-- itemtype choice -->
  <div class="row">
    <div class="col-12">
      <hr/>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'SINGLE'"></i>
        <i class="far fa-square"
           @click="setItemtype('SINGLE')"
           v-if="metaData.itemtype.value != 'SINGLE'"></i>
        <label>
          <translation :t="'SINGLECHOICE'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemtype.value == 'CATEGORY'"></i>
        <i class="far fa-square"
           @click="setItemtype('CATEGORY')"
           v-if="metaData.itemtype.value != 'CATEGORY'"></i>
        <label>
          <translation :t="'CATEGORY'"></translation>
        </label>
      </div>

      <div class="item-choice" style="margin-left:15px;">
        <i class="fa fa-check"
           v-if="! metaData.itemStyle || metaData.itemStyle.value != 'COLUMN'"></i>
        <i class="far fa-square"
           @click="setItemstyle('ROW')"
           v-else></i>
        <label>
          <translation :t="'ROW'"></translation>
        </label>
      </div>
      <div class="item-choice">
        <i class="fa fa-check"
           v-if="metaData.itemStyle && metaData.itemStyle.value == 'COLUMN'"></i>
        <i class="far fa-square"
           @click="setItemstyle('COLUMN')"
           v-else></i>
        <label>
          <translation :t="'COLUMN'"></translation>
        </label>
      </div>
    </div>
  </div>
  <!-- end itemtype choice -->
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translation from "@/components/controls/Translation"
import InputTranslation from "@/components/controls/InputTranslation"

export default {
  name : "Checkbox",
  emits: ['saveMetaData', 'showTranslator'],
  props: ['metaData', 'formelementID'],
  data() {
    return {
      categoryID: null
    }
  },
  components: {
    InputTranslation,
    Translation
  },
  computed  : {
    ...mapGetters( 'categoryModule', ['categories'] )
  },
  created() {
    this.categoryID = this.metaData.categoryID.value
  },
  methods: {
    toggleShowTranslations( translator ) {
      this.$emit( 'showTranslator', translator )
    },
    setItemtype( type ) {
      this.metaData.itemtype.value = type

      this.$emit( 'saveMetaData', this.metaData )
    },
    savelabel( translation ) {
      this.metaData.label.value = translation
      this.$emit( 'saveMetaData', this.metaData )
    },
    savecategoryID() {
      this.metaData.categoryID.value = this.categoryID
      this.saveTheMetaData()
    },
    setItemstyle(itemStyle){
      this.metaData.itemStyle.value = itemStyle

      this.$emit( 'saveMetaData', this.metaData )
    },
    saveTheMetaData( direction ) {
      if ( direction == 'MINUS' ) this.metaData.items.value--
      if ( direction == 'PLUS' ) this.metaData.items.value++

      this.$emit( 'saveMetaData', this.metaData )
    }
  }
}
</script>

<style scoped>

</style>