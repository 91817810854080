/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import milestoneMutations from './milestoneMutations.js';
import milestoneActions from './milestoneActions.js';
import milestoneGetters from './milestoneGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            activeMilestoneDomainID  : null,
            activeMilestoneCategoryID: null,
            milestoneForm            : false,
            milestoneIndicators      : []
        }
    },
    mutations: milestoneMutations,
    actions  : milestoneActions,
    getters  : milestoneGetters,

};


