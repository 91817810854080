<template>
  <div v-if="editMode">
    <input v-model="itemname"
           @keyup.enter="saveItem"
           class="form-control form-control-sm"/>
  </div>
  <div class="input-group" v-else>
    <select class="form-control"
            v-model="itemID"
            @change="setItem">
      <option v-for="item in items" :value="item.id">
        {{ item.name }}
      </option>
    </select>
    <div class="input-group-append">
      <span class="input-group-text parent-popover
                              bg-transparent border-left-0 pointer"
            @click="showTranslator">
        <tooltip :tip="'SHOWTRANSLATION'"></tooltip>
        <i class="far fa-flag"
           :class="itemID ? '' : 'greyish'"></i>
      </span>
      <span class="input-group-text bg-transparent
                                border-left-0 pointer parent-popover"
            @click="editItemname">
        <tooltip :tip="'EDITFORMPAGE'"></tooltip>
        <i class="fa fa-edit"
           :class="itemID ? '' : 'greyish'"></i>
      </span>
      <span class="input-group-text bg-transparent parent-popover
                              border-left-0 pointer"
            @click="newItem">
        <tooltip :tip="'NEWFORM'"></tooltip>
        <i class="fa fa-plus color2"></i>
      </span>
      <span class="input-group-text bg-transparent parent-popover
                                border-left-0 pointer"
            @click="removeItem">
        <tooltip :tip="'FORMNOTEMPTYORCHOOSE'"
                 v-if="! itemID"></tooltip>
        <i class="fa fa-trash redish"
           :class="{'greyish': ! itemID || ! deleteAble }"></i>
      </span>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  name : "SelectEdit",
  props: ['items', 'deleteAble'],
  emits: ['saveItem', 'showTranslator', 'setItem', 'newItem', 'removeItem'],
  data() {
    return {
      itemID  : null,
      editMode: false,
      itemname: '',
    }
  },
  components: {},
  computed  : {},
  created() {
  },
  methods: {
    newItem() {
      this.editMode = true
      this.itemID = 0
      this.$emit('newItem')
    },
    showTranslator(){
      this.$emit('showTranslator')
    },
    setItem() {
      this.$emit('setItem', this.itemID)
    },
    editItemname() {
      console.log( 'inside edit' );
      if ( !this.itemID ) return
      this.selectedItem = this.items.find( i => i.id == this.itemID)
      this.editMode = true
      this.itemname = this.selectedItem.name
    },
    saveItem() {
      this.editMode = false
      if ( this.itemname.length == 0 ) return

      let payload = {}
      payload.id = this.itemID
      payload.name = this.itemname

      this.$emit('saveItem', payload)
    },
    removeItem(){
      if( ! this.itemID || ! this.deleteAble ) return
      this.$emit('removeItem', this.itemID)
    }
  }
}
</script>

<style scoped>

</style>