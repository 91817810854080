/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'

export default {

    getTags( context, languageCode ) {
        api.get( '/api/management/getTags/' + languageCode )
            .then( result => {
                context.commit( 'setTags', result.data )
            } )
    },
    searchContentTags(context, payload){
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/searchContentTags', formData )
    },
    getItemTags( context, payload ) {
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/getItemTags', formData )
    },
    getTagsByType(context, tagType){
        let languageCode = context.rootState.languageCode
        return api.get( '/api/management/getTagsByType/' + tagType + '/' + languageCode )

    },
    addTag(context, payload){
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/addTag', formData )
    },
    addTaggs(context, payload){
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        console.log( formData );
        return api.post('/api/management/addTags', formData)
    },
    createTag( context, payload ) {
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/createTag', formData )
    },
    removeTag(context, payload){
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/management/removeTag', formData )
    }


}