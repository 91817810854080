/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setLoginState (state, payload){
        state.loggedIn = payload.loggedIn;
    },
    loginFailed(state, status){
        state.loginFailed = status
    },
    logout(state){
        state.loggedIn = false
    }
}