/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {guest, api} from '../../../plugins/axios'
import router from "@/router";
// import router from '../../../router/index'

export default {
    setLoginState( context ) {
        let payload = {};
        payload.loggedIn = true;

        context.commit( 'setLoginState', payload )
    },
    passwordForgotten( context, form ) {
        guest.get('/sanctum/csrf-cookie')
            .then(respons=> {
                guest.post( '/api/resetpassword', form )
                    .then( function ( result ) {
                        if ( result.data.success ) {
                            context.dispatch( 'createLoginState', result.data )
                            sessionStorage.setItem( 'show_change_password', '1' )
                            location.reload()
                        } else {
                            router.push( '/login' );
                        }
                    } ).catch( ( error ) => {
                    console.log( 'ERROR', error );
                    //             router.push('login');
                } )
            })
    },
    logOut( context ) {
        sessionStorage.removeItem( 'loggedin' )
        sessionStorage.removeItem( 'expires' )

        context.commit( 'logout' )

        router.push( '/login' );
    },
    createLoginState( context, data ) {
        sessionStorage.setItem( 'loggedin', '1' )
        sessionStorage.setItem( 'expires', data.expires )

        data.loggedIn = true
    }

}