/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    sourceNodeID( state ) {
        return state.sourceNodeID
    },
    articles( state ) {
        return state.articles
    },
    selectedTreenodeID( state ) {
        return state.selectedTreenodeID
    },
    refreshSource( state ) {
        return state.refreshSource
    },
    selectedItems( state ) {
        return state.selectedItems
    },
    lastSelectedItem( state ) {
        return state.lastSelectedItem
    },
    shiftClickedItem( state ) {
        return state.shiftClickedItem
    }
}
