<template>
  <app-header :background="true"></app-header>
  <div class="blix-article">
    <div v-if="childpages" class="blix-navigation-page"
         style="padding:15px; margin-top:50px;background-color:#fff;height:588px;">
      <div v-html="article"></div>
      <draggable
          v-model="childpages"
          ghost-class="ghost"
          tag="transition-group"
          @end="reOrder"
          :component-data="{name: 'flip-list', type: 'transition'}"
          item-key="id">
        <template #item="{element}">
          <div class="blix-navigation-articles" @click="setArticlepage(element)">
            {{ element.translation }}
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {mapState, mapGetters, mapActions} from 'vuex'
import AppHeader from '@/components/phoneapp/templates/blix/partials/Appheader.vue'
import ActionButtonDefault from '@/components/phoneapp/templates/blix/controls/ActionButtonDefault.vue'

export default {
  name : "childpages",
  props: ['childpages', 'applicationpageID', 'article'],
  emits: ['setArticlepage'],
  data() {
    return {
      drag: false
    }
  },
  components: {AppHeader, ActionButtonDefault, draggable},
  computed  : {},
  watch     : {
    childpages() {
      let reorder = []
      let order = 10
      this.childpages.forEach( cp => {
        reorder.push( {'id': cp.id, order: order} )
        order += 10
      } )
      let payload = {}
      payload.applicationpageID = this.applicationpageID
      payload.reorder = reorder
      this.reorderArticleNavigation( payload )

      this.setNavigationTranslations()
    }
  },
  created() {
    this.setNavigationTranslations()
  },
  methods: {
    ...mapActions( 'translate', ['getTranslationsByArray'] ),
    ...mapActions( 'applicationModule', ['reorderArticleNavigation'] ),
    setArticlepage( childpage ) {
      this.$emit( 'setArticlepage', childpage )
    },
    setNavigationTranslations() {
      let translationIDS = []
      this.childpages.forEach( cp => {
        translationIDS.push( {
          translation_id: cp.translation_id
        } )
      } )
      this.getTranslationsByArray( translationIDS )
          .then( result => {
            result.data.forEach( t => {
              let childpage = this.childpages.find( cp => cp.translation_id == t.translation_id )
              childpage.translation = t.translation
            } )
          } )
    },
    reOrder() {
      this.drag = false
      this.childPages
    }
  }
}
</script>

<style scoped>
/*.blix-navigation-articles {*/
/*  list-style: none;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  margin-top: 60px;*/
/*}*/

.blix-navigation-articles {
  font-size: 16px;
  border: 3px solid #f16a02;
  color: #f16a02;
  padding: 10px 15px;
  border-radius: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}
</style>