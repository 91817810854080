/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {

    setApplications( state, payload ) {
        state.applications = payload
    },
    setApp( state, payload ) {
        state.app = payload
    },
    setSelectedApplicationID( state, payload ) {
        state.selectedApplicationID = payload
    },
    setSelectedForms(state, payload){
        state.selectedForms = payload
    },
    setUnselectedForms( state, payload ) {
        state.unselectedForms = payload
    }
}