/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {
    setForms( state, payload ) {
        state.forms = payload
    },
    selectForm( state, formID ) {
        state.formID = formID
        state.selectedForm = state.forms.find( form => {
            return form.id == formID
        } )
    },
    resetForm( state ) {
        state.selectedForm = {
            id                : null,
            form_id           : null,
            formdescription_id: null,
            formname          : '',
            description       : '',
            cycle             : 1
        }
    },
    updateFormname( state, payload ){
        state.selectedForm.formname = payload
    },
    toggleShowEditFormpage(state){
        state.showEditFormpage = !state.showEditFormpage
    }
}