<template>
  <div class="side-panel side-panel-translator"
       :class="showTranslator ? 'open-panel' : ''">
    <h3 class="side-button side-translate-button"
        @click="toggleShow">
      <translation :t="'TRANSLATIONS'"></translation>
    </h3>
    <div class="card bg-primary-light" style="width:500px;">
      <div class="card-body">
        <table class="display table table-condensed table-striped table-bordered">
          <tbody>
          <tr v-for="language in languages">
            <td>
              {{ language.translation }}
            </td>
            <td>
              <translator v-if="languageCode != language.languagecode"
                          :ownLanguageCode="language.languagecode">
              </translator>
              <span v-else>
                {{ toTranslate }}
              </span>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2">
              <button class="btn btn-small btn-success"
                      @click="setOpentranslations">
                <translation :t="'OPENTRANSLATIONS'"></translation>
              </button>
              <button class="btn btn-small btn-danger"
                      @click="reTranslate">
                <translation :t="'ALLTRANSLATIONS'"></translation>
              </button>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Translator from './Translator.vue'
import Translation from "@/components/controls/Translation";

export default {
  name      : "Translations",
  components: {Translation, Translator},
  emits     : ['closeTranslator'],
  data() {
    return {
      busy : false
    }
  },
  computed: {
    ...mapGetters( ['languages', 'languageCode'] ),
    ...mapGetters( 'translator', [
      'showTranslator', 'toTranslate', 'identifierID', 'translations'] )
  },
  methods : {
    ...mapActions( 'translator', [
      'showHideTranslator', 'getTranslations', 'translateIt', 'translateAll'] ),
    toggleShow() {
      this.openTranslator = !this.showHideTranslator()
    },
    setOpentranslations() {
      this.bulkTranslation('MISSING')
    },
    reTranslate(){

      this.bulkTranslation('ALL')
    },
    bulkTranslation(type){
      let self = this
      if( self.busy ) return;
      self.busy = true
      let translationData = JSON.stringify( {
        fromLanguage: this.languageCode,
        toTranslate : this.toTranslate,
        identifierID: this.identifierID,
        type: type
      } )
      let formData = new FormData();
      formData.append( 'translationData', translationData )
      self.translateAll( formData )
          .then( function () {
            self.getTranslations( self.identifierID )
            self.busy = false
            ra.toasterMessage('ready', 'success')
          } )
    }

  }
}
</script>

<style scoped>

</style>