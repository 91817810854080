<template>
  <div
      class="modal fade"
      id="change-password">
    <div
        class="modal-dialog"
        role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
              class="modal-title"
              id="exampleModalLabel">
            <translation :t="'CHANGEPASSWORD'"></translation>
          </h5>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label :class="{'invalid' : invalidFirst }">
                <translation :t="'PASSWORD'"></translation>
              </label>
              <input
                  type="password"
                  :class="{'invalid' : invalidFirst }"
                  v-model.trim="passwordFirst"
                  class="form-control">
              <div
                  class="invalid-feedback"
                  v-if="invalidFirst">
                <translation :t="'PASSWORDFORMATERROR'"></translation>
              </div>
            </div>
            <div class="form-group">
              <label :class="{'invalid' : invalidSecond }">
                <translation :t="'PASSWORDAGAIN'"></translation>
              </label>
              <input
                  type="password"
                  :disabled=" invalidFirst || invalidFirst.length > 0"
                  :class="{'invalid' : invalidSecond }"
                  v-model.trim="passwordSecond"
                  class="form-control">
              <div
                  class="invalid-feedback"
                  v-if="invalidSecond">
                <translation :t="'PASSWORDSNOTIDENTICAL'"></translation>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal">Close
          </button>
          <button
              type="button"
              :disabled="! allValid"
              @click="submitPassword"
              class="btn btn-primary">Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name    : "Changepassword",
  data() {
    return {
      passwordFirst : '',
      passwordSecond: ''
    }
  },
  computed: {
    invalidFirst() {
      if ( this.passwordFirst.length == 0 ) return false

      // min 8 characters (max 50) one uppercase one lowercase one number
      var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/
      if ( this.passwordFirst.match( passw ) ) return false

      return true
    },
    invalidSecond() {
      if ( this.passwordFirst.length < 8 ) return false;
      if ( this.passwordSecond.length < 8 ) return false;

      if ( this.passwordFirst != this.passwordSecond ) return true;

      return false;
    },
    allValid() {
      if ( this.passwordFirst.length < 8 ) return false;
      if ( this.passwordSecond.length < 8 ) return false;

      if ( !this.invalidFirst && !this.invalidSecond ) return true

      return false;
    }
  },
  methods : {
    ...mapActions( 'translate', ['getTranslation'] ),
    ...mapActions( 'user', ['setPassword'] ),
    submitPassword() {
      const self = this
      const form = new FormData()
      form.append( 'password', self.passwordFirst )
      console.log( form );
      this.setPassword(form).then( function () {
        $('#change-password').modal('hide');
        sessionStorage.removeItem('show_change_password')

        self.getTranslation( 'SUCCESSFULLSETPASSWORD', 'success' )
            .then( function ( translation ) {
              ra.toasterMessage( translation, 'success' )
            } )
      } )
    }
  }
}
</script>

<style scoped>

</style>