<template>
  <ul>
    <li v-if="node.showchildren">
      <div class="clearfix"></div>
      <div class="tree-node-clicker">
        <i class="far fa-plus-square pointer"
           v-if="node.allchildren.length >= 1 && node.allchildren[0].showchildren == 0"
           @click.stop="showChildren"></i>
        <i class="far fa-minus-square pointer"
           v-else-if="node.allchildren.length >= 1 && node.allchildren[0].showchildren == 1"
           @click.stop="hideChildren"></i>
        <i class="far fa-square"
           v-else
           @click.stop="doNothing"></i>
      </div>
      <div @click.stop="clicker(node)" class="tree-node">
        <span class="node-id">
          #{{ node.id }}
        </span>
        {{ node.translation ? node.translation.translation : 'nb' }}
      </div>
      <tree-node
          v-if="node.allchildren.length >= 1"
          v-for="child in node.allchildren"
          @doClickAction="clickAction"
          :node="child"></tree-node>
    </li>
  </ul>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import TreeNode from "./TreeNode";

export default {
  name : "TreeNode",
  props: ['node'],
  emits: ['doClickAction'],
  data() {
    return {
      clicks:0,
      timer: null
    }
  },
  components: {TreeNode},
  computed  : {},
  methods   : {
    clicker( node ){
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout( () => {
          this.node.allchildren.forEach( child => child.showchildren = 1 )
          this.clicks = 0;
        }, 300);
      } else {
        clearTimeout(this.timer)
        this.clicks = 0;
        this.$emit( 'doClickAction', node )
      }
    },
    clickAction( node ) {
      this.$emit( 'doClickAction', node )
    },
    showChildren() {
      this.node.allchildren.forEach( child => child.showchildren = 1 )
    },
    hideChildren() {
      this.node.allchildren.forEach( child => child.showchildren = 0 )
    },
    doNothing() {

    }
  }
}
</script>

<style scoped>
ul {
  margin: 0px 0;
  padding: 0px 0 0px 5px;
  list-style: none;
  cursor: pointer;
}
li{
  padding:4px 3px;
}
.tree-node-clicker{
  float:left;
  margin-right:15px;
  margin-top:10px;
}
.tree-node {
  width:90%;
  border-bottom:1px solid #b2b2b2;
  padding-bottom:6px;
  padding-top:10px;
}

.node-id {
  padding: 3px 10px;
  background-color: #6700a9;
  color: #fff;
  border-radius: 50%;
}
</style>