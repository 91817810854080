import {createStore} from 'vuex'

import authModule from './modules/auth/authState.js'
import translationModule from './modules/translations/translationState.js'
import userModule from './modules/users/userState.js'
import organizationModule from './modules/organizations/organizationState.js'
import formModule from './modules/forms/forms/formState.js'
import formpageModule from './modules/forms/formpages/formpageState.js'
import translatorModule from './modules/widgets/translator/translatorState.js'
import editorModule from './modules/widgets/editor/editorState.js'
import categoryModule from './modules/management/categoryState.js'
import uploadModule from './modules/widgets/upload/uploadState.js'
import treeModule from './modules/cms/trees/treeState.js'
import tagModule from './modules/management/tags/tagState.js'
import templateModule from './modules/phoneapp/templates/templateState.js'
import applicationModule from './modules/phoneapp/applications/applicationState.js'
import modalModule from './modules/widgets/genericModals/modalState'
import milestoneModule from './modules/forms/milestones/milestoneState'
import {guest, api} from '@/plugins/axios'


const store = createStore( {
    modules  : {
        auth             : authModule,
        translate        : translationModule,
        user             : userModule,
        organization     : organizationModule,
        formModule       : formModule,
        formpageModule   : formpageModule,
        translator       : translatorModule,
        editorModule     : editorModule,
        categoryModule   : categoryModule,
        uploadModule     : uploadModule,
        treeModule       : treeModule,
        tagModule        : tagModule,
        templateModule   : templateModule,
        applicationModule: applicationModule,
        modalModule      : modalModule,
        pages            : [],
        milestoneModule  : milestoneModule
    },
    state    : {
        userRights         : [],
        languages          : [],
        authorizedLanguages: [],
        languageCode       : 'EN',
        regions            : [],
        entities           : [],
        alertData          : {
            title  : '',
            message: '',
            type   : 'ALERT'
        }
    },
    mutations: {
        setLanguages( state, payload ) {
            state.languages = payload
        },
        userRights( state, payload ) {
            state.userRights = payload
        },
        setLanguageCode( state, payload ) {
            state.languageCode = payload
        },
        setTranslatedLanguages( state, payload ) {
            state.translatedLanguages = payload
        },
        setAuthorizedLanguages( state, payload ) {
            state.authorizedLanguages = payload
        },
        setPages( state, payload ) {
            state.pages = payload
        },
        setRegions( state, payload ) {
            state.regions = payload
        },
        setEntities( state, payload ) {
            state.entities = payload
        },
        alertMe( state, payload ) {
            state.alertData.title = payload.title
            state.alertData.message = payload.message
            state.alertData.type = payload.type
        }
    },
    actions  : {
        setLanguageCode( context ) {
            let languageCode = localStorage.getItem( 'language' )
            if ( !languageCode ) {
                languageCode = 'EN';
                localStorage.setItem( 'language', languageCode )
            }
            context.commit( 'setLanguageCode', languageCode )
        },
        changeLanguageCode( context, languageCode ) {
            return localStorage.setItem( 'language', languageCode )
        },
        getPages( context ) {
            return api.get( '/api/getpages' )
                .then( result => {
                    context.commit( 'setPages', result.data );
                    return result
                } );
        },
        getLanguages( context ) {
            guest.get( '/api/getLanguagesInOwnLanguage' )
                .then( function ( response ) {
                    context.commit( 'setLanguages', response.data )
                } )
        },
        getAuthorizedLanguages( context ) {
            let languageCode = context.rootState.languageCode
            api.get( '/api/getAuthorizedLanguages/' + languageCode )
                .then( response => {
                    context.commit( 'setAuthorizedLanguages', response.data )
                } )
        },
        getRegions( context ) {
            let languageCode = context.rootState.languageCode
            guest.get( '/api/getRegions/' + languageCode )
                .then( ( result ) => {
                    context.commit( 'setRegions', result.data )
                } )
        },
        getTranslatedLanguages( context ) {
            let languageCode = context.rootState.languageCode
            guest.get( '/api/getTranslatedLanguages/' + languageCode )
                .then( function ( response ) {
                    context.commit( 'setTranslatedLanguages', response.data )
                } )
        },
        getEntities( context ) {
            return api.get( '/api/getEntities' )
                .then( function ( result ) {
                    context.commit( 'setEntities', result.data )
                    return
                } )
        },
        alertMe( context, payload ) {
            $( '#alert-me' ).modal( 'show' )
            context.commit( 'alertMe', payload )
        }
    },
    getters  : {
        languageCode( state ) {
            return state.languageCode
        },
        languages( state ) {
            return state.languages
        },
        translatedLanguages( state ) {
            return state.translatedLanguages
        },
        entities( state ) {
            return state.entities
        },
        alertData( state ) {
            return state.alertData
        }
    }
} )

export default store;

