/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

export default {


    setModalpictures(state, payload){
        state.modalPictures = payload
    },
    toggleRegionPicture(state){
        state.showRegionPicture = ! state.showRegionPicture
    },
    setSelectedPicture( state, icon ) {
        state.selectedPicture = icon
    },
    setPageTypeID(state,pageTypeID){
	    state.pageTypeID = pageTypeID
    },
    toggleButtonActionselector( state, activeActionButton ) {
        state.showButtonActionselector = !state.showButtonActionselector
        state.activeActionButton = activeActionButton
        if ( state.showButtonActionselector ) state.selectedButtonAction = '';
    },

    toggleArticlesSelector(state){  //toggleArticlesSelector
        state.showTreeModal = ! state.showTreeModal
//         state.selectedModalpage = selectedModalpage
//         if ( state.showTreeModal ) state.selectedModalpage = '';
    }
}