<template>
  <translators></translators>
  <div class="card bg-primary-light" style="width:1000px;min-height:500px;">
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <label>
            <translation :t="'CATEGORY'"></translation>
          </label>
          <div class="input-group" v-if=" ! editNewMode">
            <select class="form-control form-control-sm bg-white"
                    v-model="categoryID"
                    @change="setTheSelectedCategoryID">
              <option value="">
                select...
              </option>
              <option v-for="category in categories" :value="category.id">
                {{ category.translation ? category.translation.translation : 'NB' }}
              </option>
            </select>
            <div class="input-group-append bg-white"
                 style="position:relative">
                  <span class="input-group-text bg-transparent border-left-0"
                        @click="showTranslator">
                    <i class="far fa-flag"></i>
                  </span>
              <span class="input-group-text bg-transparent border-left-0"
                    @click="setUpdateMode">
                    <i class="fa fa-edit"></i>
                  </span>
              <span class="input-group-text bg-transparent border-left-0">
                    <i class="fa fa-plus color2"
                       @click="addCategory"></i>
                  </span>
              <span class="input-group-text bg-transparent border-left-0"
                    v-if="categoryItems.length == 0 && selectedCategoryID">
                  <i class="fa fa-trash redish"
                     @click="removeTheCategory"></i>
              </span>
              <span class="input-group-text bg-transparent border-left-0"
                    v-if="categoryItems.length > 0 || ! selectedCategoryID">
                  <i class="fa fa-trash greyish"></i>
                </span>
            </div>
          </div>
          <input class="form-control-sm form-control"
                 v-model="categoryname"
                 @keyup.enter="saveTheCategory"
                 v-else>
          <category-items :items="categoryItems"></category-items>
        </div>
        <div class="col-6">
          <category-followup v-if="followup"></category-followup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Translators from "@/components/widgets/Translators.vue"
import {mapActions, mapGetters} from 'vuex'
import Translation from "@/components/controls/Translation"
import CategoryItems from "@/components/management/categories/CategoryItems"
import CategoryFollowup from '@/components/management/categories/CategoryFollowup.vue'

export default {
  name      : "Categories",
  components: {CategoryItems, Translation, Translators, CategoryFollowup},
  data() {
    return {
      editNewMode : false,
      categoryname: '',
      categoryID  : null
    }
  },
  computed: {
    ...mapGetters( ['languageCode'] ),
    ...mapGetters( 'categoryModule', ['categories', 'selectedCategoryID', 'followup'] ),
    categoryItems() {
      let self = this
      let category = this.categories.find( category => {
        return category.id == self.selectedCategoryID
      } )

      if ( category ) return category.categoryitems

      return []
    }
  },
  methods : {
    ...mapActions( 'translator', ['showHideTranslator', 'setToTranslate', 'setIdentifierID', 'getTranslations'] ),
    ...mapActions( 'categoryModule', ['saveCategory', 'getCategories', 'setSelectedCategoryID', 'setFollowup', 'removeCategory'] ),
    showTranslator() {
      let self = this
      if ( !self.selectedCategoryID ) return
      let category = self.categories.find( category => {
        if ( self.selectedCategoryID == category.id ) return category
      } )
      if ( !category || !category.translation ) return;
      this.showHideTranslator( 'SHOW' )

      this.getTranslations( category.translation_id )
      this.setToTranslate( category.translation.translation )
      this.setIdentifierID( category.translation_id )
    },
    addCategory() {
      this.editNewMode = true
      this.categoryname = ''
      this.setSelectedCategoryID(0)
      this.setFollowup(null)
    },
    setTheSelectedCategoryID() {
      this.setSelectedCategoryID( this.categoryID )
    },
    setUpdateMode() {
      let self = this
      if ( !self.selectedCategoryID ) return
      let category = this.categories.find( category => {
        return category.id == self.selectedCategoryID
      } )
      this.categoryname = category.translation ? category.translation.translation : ''
      this.editNewMode = true
    },
    saveTheCategory() {
      let self = this
      let payload = {}
      payload.categoryID = this.selectedCategoryID
      payload.categoryname = this.categoryname
      payload.languageCode = this.languageCode

      if ( this.categoryname.length == 0 ) return this.editNewMode = false;

      this.saveCategory( payload )
          .then( function ( result ) {
            self.getCategories( self.languageCode )
                .then( function () {
                  self.editNewMode = false
                  if ( !result.data ) return

                  self.setSelectedCategoryID( result.data.id)
                  self.categoryID = self.selectedCategoryID
                  self.categoryname = ''
                } )
          } )
    },
    removeTheCategory() {
      let self = this
      self.removeCategory( this.selectedCategoryID )
          .then( () => {
            self.getCategories(self.languageCode)
          } )
    }
  }
}
</script>

<style scoped>

</style>