/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import templateMutations from './templateMutations.js';
import templateActions from './templateActions.js';
import templateGetters from './templateGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            templates         : [],
            selectedTemplateID: 0
        }
    },
    mutations: templateMutations,
    actions  : templateActions,
    getters  : templateGetters,

};


