<template>
  <span v-if="showTreeModal">
    <div class="my-backdrop"></div>
      <div class="my-popup">
      <div class="my-popup-header">
        <h3>
          <translation :t="'ARTICLES'"></translation>
          <button class="close" @click="toggleArticlesSelector()">x</button>
        </h3>
      </div>
      <div class="my-popup-body">
        <ul>
          <tree-node
              v-for="node in nodes"
              @doClickAction="clickAction"
              :node="node"></tree-node>
        </ul>
      </div>
    </div>
  </span>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import TreeNode from "./partials/TreeNode"

export default {
  name : "TreeModal",
  props: [],
  data() {
    return {
      nodes: []
    }
  },
  emits     : ['clickAction'],
  components: {TreeNode},
  computed  : {
    ...mapState( 'modalModule', ['selectedModalpage', 'showTreeModal'] )
  },
  created() {
      let self = this
      self.getTree( 'ARTICLESTREE' )
          .then( result => {
            self.nodes = result.data
          } )
  },
  methods: {
    ...mapActions( 'treeModule', ['getTree'] ),
    ...mapActions( 'modalModule', ['toggleArticlesSelector'] ),
    clickAction( node ) {
      this.$emit( 'clickAction', node )
      this.toggleArticlesSelector();
    }
  }
}
</script>

<style scoped>

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>