/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import applicationMutations from './applicationMutations.js';
import applicationActions from './applicationActions.js';
import applicationGetters from './applicationGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            applications         : [],
            app                  : {},
            selectedApplicationID: null,
            applicationPages     : [],
            selectedForms        : [],
            unselectedForms      : []
        }
    },
    mutations: applicationMutations,
    actions  : applicationActions,
    getters  : applicationGetters,

};


