/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:28
 */

import authMutations from './authMutations.js';
import authActions from './authActions.js';
import authGetters from './authGetters.js';

export default {
    namespaced: true,
    state() {
        return {
            loggedIn    : false,
            loginFailed : false
        };
    },
    mutations : authMutations,
    actions   : authActions,
    getters   : authGetters
};


