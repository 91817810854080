<template>
  <span>
    {{ trans }}
  </span>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  props   : ['t'],
  data() {
    return {
      trans: 'NB'
    }
  },
  created() {
    this.setTranslation()
  },
  computed: {
    ...mapGetters( 'translate', ['translations'] )
  },
  watch   : {
    translations() {
      this.setTranslation()
    },
    t(){
      this.setTranslation()
    }
  },
  methods : {
    setTranslation() {
      let self = this
      self.trans =
          ra.findTranslation( self.translations, self.t)
    }
  }
}
</script>

<style scoped>

</style>