/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 31/10/2020
 * Time: 15:29
 */

import {api} from '@/plugins/axios'
import router from "@/router";

export default {
    setSelectedTreenodeID( context, selectedTreenodeID ) {
        context.commit( 'setSelectedTreenodeID', selectedTreenodeID )
    },
    setSourceNodeID( context, sourceNodeID ) {
        context.commit( 'setSourceNodeID', sourceNodeID )
    },
    switchNodes( context, payload ) {
        return api.get( '/api/cms/switchNodes/' + payload.sourceID + '/' + payload.targetID )
    },
    getTree( context, treeType ) {
        let languageCode = context.rootState.languageCode
        return api.get( '/api/cms/getTree/' + treeType + '/' + languageCode )
    },
    getTreeItemsByTags( context, payload ) {
        let formData = new FormData
        formData.append( 'formData', JSON.stringify( payload ) )
        return api.post( '/api/images/getTreeItemsByTags', formData )
    },
    selectItemsWithShift( context, payload ) {
        context.commit( 'selectItemsWithShift', payload )
    },
    selectItemsWithMeta( context, payload ) {
        context.commit( 'selectItemsWithMeta', payload )
    },
    firstSelectedItem( context, payload ) {
        context.commit( 'clearSelectedItems' )

        context.commit( 'selectItemsWithMeta', payload )
    },
    clearSelection( context ) {
        context.commit( 'clearSelectedItems' )
    },
    setSecondkey( context, payload ) {
        context.commit( 'setSecondkey', payload )
    },
    setSelectedArticlenodeID(context, nodeID){
        console.log( nodeID );
        context.commit('setSelectedArticlenodeID', nodeID)
    },
    clickDropItems( context, nodeID ) {
        let payload = {}
        payload.items = context.state.selectedItems
        payload.nodeID = nodeID
        payload.pageName = router.currentRoute.value.name
        let formData = new FormData()
        formData.append( 'formData', JSON.stringify( payload ) )

        return api.post( '/api/cms/clickDropItems', formData )
            .then( () => {
                context.dispatch( 'clearSelection' )
                context.dispatch( 'setRefreshSource', router.currentRoute.value.name )
            } )
    },
    setRefreshSource( context, source ) {
        context.commit( 'setRefreshSource', source )
    },
    getNodeContent( context, payload ) {
        console.log( navigator.onLine );
        if( ! navigator.onLine ) context.dispatch('auth/logOut', {}, {root:true})
        return api.get( '/api/cms/getNodeContent/' + payload.treenodeID + '/' + payload.languageCode )
    },
    addTreenode( context, formData ) {
        return api.post( '/api/cms/addTreenode', formData )
    },
    saveTreenodeName( context, formData ) {
        return api.post( '/api/cms/saveTreenodeName', formData )
    },
    removeTreenode( context, treenodeID ) {
        return api.get( '/api/cms/removeTreenode/' + treenodeID )
    },
    removeItem( context, itemID ) {
        return api.get( '/api/cms/removeItem/' + itemID + '/' + router.currentRoute.value.name )
    },
    removeItems( context, items ) {
        return api.get( '/api/cms/removeItems/' + items + '/' + router.currentRoute.value.name )
    }
}
